import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DLText from "../DLText/DLText";
import {getFontFamily, isEmpty} from "../DLComponentHelper";
import appTheme from "../../../assets/appTheme";
import DLLoader from "../DLLoader/DLLoader";

const DLSearchDropdownTestView = (props) => {
    const {
        keyword,
        placeholder,
        onChangeValue,
        data = [],
        isShowInlineLoader,
        dropdownHeight,
        loader,
        helperMessage,
        disableHelperMessageHeight,
        error,
        onClickItem,
        renderCustomItemUI,
        noMatchMessage,
        autoFocus,
    } = props;

    const renderLoader = () => {
            return (
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{height: dropdownHeight}}
                >
                    <DLLoader isVisible={true} type="inline"/>
                </div>
            );
    };

    const renderNoMatchMessage = () => {
        return (
            <div className="w-100">
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{height: dropdownHeight}}
                >
                    <DLText id={"no-match-message"} text={noMatchMessage} marginBottom={"none"}/>
                </div>
            </div>
        );
    }

    const renderUI = () => {
        const loading = isShowInlineLoader || loader;
        return (
            <div className={'w-100'}>
                <Autocomplete
                    open={keyword.length > 0}
                    options={loading ? [] : data}
                    filterOptions={(options) => options}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) =>
                        option.value === value?.value
                    }
                    noOptionsText={renderNoMatchMessage()}
                    onInputChange={(event, newInputValue) => {
                        const trimmedValue = newInputValue.trim();
                        if (trimmedValue || newInputValue === "") {
                            onChangeValue(trimmedValue);
                        }
                    }}
                    onChange={(event, newValue) => {
                        onClickItem(newValue?.value);
                    }}
                    popupIcon={<SearchIcon/>}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.value}>
                                {renderCustomItemUI ? (
                                    renderCustomItemUI(option.value)
                                ) : (
                                    <div>{option.label}</div>
                                )}
                            </li>
                        );
                    }}
                    PaperComponent={({children}) => (
                        <div
                            style={{
                                overflowY: "auto",
                                fontSize: "12px",
                                fontFamily: getFontFamily(),
                                marginTop: "3px",
                                marginBottom: "3px",
                                padding: '0px'
                            }}
                            className={"dlSearchDropdown-root bg-white"}
                        >
                            {loading ? renderLoader() : children}
                        </div>
                    )}
                    sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: '25px',
                            borderColor: error ? appTheme.appColor.danger : '#d3d3d3',
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                            color: error ? appTheme.appColor.danger : appTheme.appColor.black,
                        },
                        '& .MuiAutocomplete-popupIndicatorOpen': {
                            transform: 'rotate(0deg)',
                        },

                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            autoFocus={autoFocus}
                            placeholder={placeholder}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    color: error ? appTheme.appColor.danger : appTheme.appColor.black,
                                    '& fieldset': {
                                        borderColor: error ? `${appTheme.appColor.danger} !important` : '#d3d3d3',
                                    },
                                },
                                '& .MuiInputBase-root': {
                                    height: '36px',
                                    fontSize: '14px',
                                    fontFamily: getFontFamily(),
                                    backgroundColor: '#fff',
                                    borderRadius: '25px !important',
                                },
                                '& .MuiInputBase-input::placeholder': {
                                    fontFamily: getFontFamily(),
                                    fontSize: '14px',
                                },
                            }}
                            fullWidth
                        />
                    }
                />
            </div>
        );
    };

    const renderHelperMessage = () => {
        return (
            <div style={{
                paddingLeft: '14px',
                paddingTop: '2.5px',
                minHeight: disableHelperMessageHeight ? '0' : '19px'
            }}>
                {error && !isEmpty(helperMessage) && (
                    <DLText
                        id={"helper-message"}
                        text={helperMessage}
                        fontColor={"danger"}
                        fontSize={"xs"}
                        marginBottom={"none"}
                    />
                )}
            </div>
        );
    };

    return (
        <div>
            {renderUI()}
            {renderHelperMessage()}
        </div>
    );
};

export default DLSearchDropdownTestView;
