import React from "react";
import {isEmpty} from "../../../../../../utils/validations";

import {
    swpSelectedSchemeCellConfig,
    swpSelectedSchemeMobileCellConfig,
    swpSelectedSchemeHeaderData,
    swpSelectedSchemeHeaderDataForWeekly
} from "../../../../FTModal";

import DLCheckbox from "../../../../../../core/components/DLCheckbox/DLCheckbox";
import DLTable from "../../../../../../core/components/DLTable/DLTable";
import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLDropDown from "../../../../../../core/components/DLDropdown/DLDropDown";
import DLInputTextField from "../../../../../../core/components/DLInputTextField/DLInputTextField";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLDatePicker from "../../../../../../core/components/DLDatePicker/DLDatePicker";
import moment from "moment/moment";
import appTheme from "../../../../../../assets/appTheme";
import ExistingPortfolio from "../existingPortfolio/ExistingPortfolio";
import {numberWithCommas} from "../../../../../../utils/helper";

const PurchaseSwpTransactionView = (props) => {
    const {
        isMobileView,
        isShowLoader,
        handleDelete,
        selectedScheme,
        UIState,
        handleChangeDate,
        handleMaximumPeriodCheckBox,
        changeSWPDate,
        addSchemeToList,
        setUiState,
        changeTransferAmount,
        responseErrors,
        isWeeklyFrequency,
        isXSView
    } = props;

    const renderExistingPortfolio = () => {
        return (
            <ExistingPortfolio
                {...props}
                setSelectedSchemeData={addSchemeToList}
                setUiState={setUiState}
                subTextLabel={'Select the funds you would like to redeem'}
            />
        );
    };

    const renderDate = (selectedTransaction, index) => {
        return (
            <div className={(isMobileView && !isXSView) ? 'h-100 d-flex flex-column' : "h-100 py-2 d-flex flex-column "}
                 style={{verticalAlign: 'Top'}}>
                {(isMobileView && !isXSView) &&
                    <DLText id={''}
                         text={'SWP Start Date & End Date'}
                         fontColor={"grayDark"}
                         fontSize={"xs"}
                         marginBottom={"sm"}
                />}
                <div className="d-flex align-items-start justify-content-start">
                    <div className="d-flex align-items-center">
                        <DLDatePicker id={'start-date'}
                                      value={selectedTransaction?.startDate}
                                      minimumDate={moment()}
                                      isClearable={true}
                                      isRequired={true}
                                      onChangeValue={(value) => {
                                          handleChangeDate('startDate', value, index);
                                      }}
                                      minWidth={'175px'}
                                      disableHelperMessageHeight={true}
                        />
                        <div className="px-1">
                            <DLText id={''}
                                    text={'&'}
                            />
                        </div>
                    </div>

                    <div>
                        <DLDatePicker id={'start-date'}
                                      value={selectedTransaction?.endDate}
                                      minimumDate={moment().add(6, 'months').format('YYYY-MM-DD')}
                                      maximumDate={moment().add(30, 'years').format('YYYY-MM-DD')}
                                      isClearable={true}
                                      isRequired={true}
                                      onChangeValue={(value) => {
                                          handleChangeDate('endDate', value, index);
                                      }}
                                      minWidth={'175px'}
                                      disableHelperMessageHeight={true}
                                      isDisabled={selectedTransaction?.isForMaxPeriod}
                        />
                        <div className="d-flex align-items-center pt-1">
                            <DLCheckbox
                                id={'mark-as-reviewed-dayCheck'}
                                fontSize={"sm"}
                                disableHelperMessageHeight={true}
                                onChangeValue={() => {
                                    handleMaximumPeriodCheckBox(index);
                                }}
                                labelPlacement={'end'}
                                marginBottom={'none'}
                                checkboxcolor={'black'}
                                labelColor={appTheme.appColor.black}
                                isChecked={selectedTransaction?.isForMaxPeriod}
                            />
                            <div>
                                <DLText id={''}
                                        text={'Perpetual'}
                                        marginBottom={'none'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderSWPDatesDropdown = (selectedTransaction, index) => {
        return (
            <div className={(isMobileView && !isXSView) ? "h-100" : "h-100 py-2"}
                 style={{verticalAlign: 'Top'}}>
                <DLDropDown
                    id={"status-filter"}
                    label={isMobileView ? 'SWP Date' : ''}
                    fontSize={"xs"}
                    labelPlacement={'top'}
                    value={isEmpty(selectedTransaction?.selectedSwpDate) ? '' : selectedTransaction?.selectedSwpDate}
                    options={selectedTransaction?.swpAllowedDates}
                    onSelect={(e) => {
                        changeSWPDate(e, index);
                    }}
                    // error={!selectedTransaction?.isDateSelected}
                    isDisabled={isEmpty(selectedTransaction?.swpAllowedDates)}
                    marginBottom={"none"}
                    disableHelperMessageHeight={true}
                    minWidth={isMobileView ? '100%' : '90px'}

                />
            </div>
        );
    };

    const getFormattedTableData = () => {
        let tableData = [];
        selectedScheme?.map((selectedTransaction, index) => {
            let transactionRecords = [];
            let transactionDetails = {};
            if (!isMobileView) {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderProductNameUI(selectedTransaction)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderFolioNumberUI(selectedTransaction?.folioNo)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderDate(selectedTransaction, index)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderSWPDatesDropdown(selectedTransaction, index)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderRedeemAmount(selectedTransaction, index)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderAction(selectedTransaction?.id)
                });

            } else {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderSelectedSchemeTableMobileView(selectedTransaction, index)
                });
            }
            transactionDetails.id = selectedTransaction?.clientRequestId;
            transactionDetails.data = transactionRecords;
            tableData.push(transactionDetails);
        });
        return tableData;
    };

    const renderErrorMessages = (errors) => {
        const {isDoneClicked, filterErrorMessages} = props;
        if (isEmpty(errors)) {
            return '';
        }
        errors = isDoneClicked ? errors : filterErrorMessages(errors);


        return (
            errors?.map((error, index) => {
                return <div className="pb-2 d-flex align-items-top" key={"error-" + index}>
                    <i className="fa-solid fa-circle-info error-icon pe-1"
                       style={{paddingTop: '2px'}}
                    />
                    <DLText id={''}
                            text={error}
                            fontSize={"xs"}
                            fontColor={"danger"}
                            marginBottom={'none'}
                    />
                </div>;
            })
        );
    };

    const renderProductNameUI = (selectedTransaction) => {
        let productName = selectedTransaction?.sourceScheme;
        let errors = selectedTransaction?.errors;

        const errorUI = renderErrorMessages(errors);
        return (
            <div>
                <div className="pt-2 mt-2">
                    <DLText id={''}
                            text={productName}
                            fontSize={"xs"}
                            marginBottom={'none'}
                    />
                </div>
                {!isEmpty(errorUI) && <div className="pt-2">
                    {errorUI}
                </div>}
            </div>
        );
    };

    const renderFolioNumberUI = (folioNo) => {
        return (
            <div className="pt-2 mt-2">
                <DLText id={'folio-id'}
                        text={folioNo}
                        marginBottom={'none'}
                />
            </div>
        );
    };

    const renderRedeemAmount = (selectedTransaction, index) => {
        return (
            <div className="pt-2"
                 id={'transferAmount-field-' + index}>
                {selectedTransaction?.type === 'AMOUNT' && selectedTransaction?.type !== 'ALL' ? (
                    <div className={''} style={{}}>
                        <DLInputTextField
                            id={'transfer-amount'}
                            onChangeValue={(value) => {
                                changeTransferAmount(value, index);
                            }}
                            inputType={"decimal"}
                            maxDecimalPlaces={2}
                            isCommaSeparated={true}
                            disableHelperMessageHeight={false}
                            value={selectedTransaction?.redeemAmount}
                            // error={selectedTransaction.redeemAmountError}
                            marginBottom={"none"}
                            rows={1}
                            fontSize={'xs'}
                            isClearable={false}
                        />
                    </div>
                ) : (
                    <div className={'pt-2'} style={{paddingLeft: '14px'}}>
                        <DLText
                            id={'units'}
                            text={isEmpty(selectedTransaction?.redeemAmount) ? 0 : selectedTransaction?.redeemAmount}
                            marginBottom={'none'}
                        />
                    </div>
                )}
            </div>
        );
    };

    const renderAction = (id) => {
        return (
            <div className={'pt-3 cursor'} onClick={() => handleDelete(id)}>
                <i className="fa-regular fa-lg fa-trash-can"/>
            </div>
        );
    };

    const renderSelectedSchemeTableMobileView = (selectedScheme, index) => {

        const renderMobileSourceScheme = () => {
            let productName = selectedScheme.sourceScheme;

            return (
                <div className="d-flex justify-content-between">
                    <div className="w-80 px-0 mx-0">
                        <DLText id={'scheme-title'}
                                text={productName}
                                fontWeight={'normal'}
                                fontSize={"xs"}
                        />
                        {renderMobileFolioNumberUI()}
                    </div>
                    <div className="h-100 px-2">
                        <i className="fa-regular fa-xl fa-trash-can default-icon cursor"
                           onClick={() => {
                               handleDelete(selectedScheme?.id);
                           }}
                        />
                    </div>
                </div>
            );
        };

        const renderMobileFolioNumberUI = () => {
            return (
                <div className="pb-2">
                    <DLText
                        id={"folioNo"}
                        text={"Folio no. "}
                        fontColor={"grayDark"}
                        fontSize={"xs"}
                        marginBottom={"none"}
                        isInline={true}
                    />
                    <DLText
                        id={'folio-no'}
                        text={selectedScheme?.folioNo}
                        fontSize={"xs"}
                        marginBottom={"none"}
                        isInline={true}
                    />
                </div>
            );
        };

        const errorUI = () => {
            let errors = selectedScheme?.errors;
            return (
                <div>
                    {isEmpty(errors) ? '' :
                        errors.map((error, index) => {
                            return <div className='py-2 d-flex align-items-center' key={"error-" + index}>
                                <i className="fa-solid fa-circle-info error-icon pe-1"/>
                                <DLText id={''}
                                        text={error}
                                        fontSize={"xs"}
                                        fontColor={"danger"}
                                        marginBottom={'none'}
                                />
                            </div>
                        })}
                </div>
            )
        };

        const renderMobileAmount = () => {
            return (
                <div className={(isMobileView && !isXSView) ? "h-100" : "h-100 py-2"}
                    id={'transferAmount-field-' + index} style={{flex: 1}}>
                    <DLInputTextField
                        id="transfer-amount"
                        onChangeValue={(value) => changeTransferAmount(value, index)}
                        disableHelperMessageHeight={true}
                        value={isEmpty(selectedScheme?.redeemAmount) ? '' : numberWithCommas(selectedScheme?.redeemAmount)}
                        isClearable={false}
                        marginBottom={"none"}
                        fontSize={"xs"}
                        inputType={"decimal"}
                        label={'Amount'}
                        labelFontSize={'xs'}
                    />
                </div>
            );
        };

        return (
            <div className={'mb-4'}>
                <div className={isXSView ? '' : 'p-3'}>
                    {renderMobileSourceScheme()}
                    <div className={isXSView ? '' : 'd-flex align-items-start'}>
                        {renderDate(selectedScheme, index)}
                        <div className={isXSView ? "d-flex w-100 " : "d-flex w-100 px-3"}>
                            <div style={{flex:1}}>
                                {renderSWPDatesDropdown(selectedScheme, index)}
                            </div>
                            <div style={{flex:1}} className='ps-3'>
                                {renderMobileAmount()}
                            </div>
                        </div>
                    </div>
                    {errorUI()}
                </div>
            </div>
        );
    };

    const renderFrequencyDetails = () => {
        const {selectedFrequency} = props;
        return (
            <div className="d-flex pb-3">
                <div className="pe-4">
                    <DLText
                        id={'frequency-title'}
                        type={'normal'}
                        text={`Frequency: `}
                        fontSize={'md'}
                        fontWeight={'normal'}
                        fontColor={'black'}
                        marginBottom={"none"}
                        isInline={true}
                    />
                    <DLText
                        id={'frequency-value'}
                        type={'normal'}
                        text={selectedFrequency}
                        fontSize={'md'}
                        fontWeight={'bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                        isInline={true}
                    />
                </div>
            </div>
        );
    };

    const renderSelectedPortfolio = () => {
        return (
            <div className={"bg-white border-rounded px-3 pt-2 pb-3"}>
                {renderFrequencyDetails()}
                {isEmpty(selectedScheme) ?
                    <div className={'d-flex justify-content-center align-items-center border-rounded border'}
                         style={{
                             minHeight: 'calc(100dvh - 650px)',
                             maxHeight: 'calc(100dvh - 650px)'
                         }}>
                        <DLText
                            id={'empty-ui'}
                            text={'Add a fund from the existing portfolio for withdrawal.'}
                        />
                    </div> :
                    <div className={''} style={{
                        minHeight: 'max-content',
                        maxHeight: 'calc(100dvh - 630px)'
                    }}>
                        {renderSelectedSchemeTable()}
                    </div>
                }
            </div>
        );
    };

    const renderSelectedSchemeTable = () => {
        const tableData = getFormattedTableData();
        if (isMobileView) {
            return (
                <div>
                    <DLTable
                        id={'selected-list'}
                        headerData={[]}
                        tableData={tableData}
                        tableMaxHeight={'auto'}
                        tableMinHeight={'auto'}
                        isRowClickable={false}
                        isShowPagination={false}
                        isShowTotalCount={true}
                        cellConfig={swpSelectedSchemeMobileCellConfig}
                    />
                </div>
            );
        }
        return (
            <div>
                <DLTable
                    id={'selected-list'}
                    headerData={isWeeklyFrequency ? swpSelectedSchemeHeaderDataForWeekly : swpSelectedSchemeHeaderData}
                    tableData={tableData}
                    isDynamicHeight={true}
                    tableMaxHeight={'calc(100dvh - 604px)'}
                    tableMinHeight={'calc(100dvh - 604px)'}
                    isRowClickable={false}
                    isShowPagination={false}
                    isShowTotalCount={true}
                    cellConfig={swpSelectedSchemeCellConfig}
                    isShowCellSeprator={false}
                />
            </div>
        );
    };

    const renderBottomActionButtons = () => {
        const {submitTransactionDetails, openDiscardModal} = props;
        return (
            <div
                className={(isMobileView ? 'bg-white fixed-bottom' : '')}
                style={{
                    zIndex: 1000,
                    boxShadow: isMobileView ? "0px -2px 5px rgba(0, 0, 0, 0.1)" : "none",
                }}>
                <div
                    style={{zIndex: 100}}
                    className={isMobileView ? 'd-flex py-2 justify-content-center align-items-center' : 'sticky-bottom-buttons '}>
                    <div>
                        <DLButton
                            id={'save-and-close-button'}
                            type={"danger"}
                            styleType={"outlined"}
                            sideMargin={'none'}
                            onClick={() => {
                                openDiscardModal();
                            }}
                            label={"Discard"}
                        />
                    </div>
                    <div className={isMobileView ? "ps-3" : ""}>
                        <DLButton id={'next-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  isDisabled={!isEmpty(responseErrors)}
                                  onClick={() => {
                                      submitTransactionDetails();
                                  }}
                                  label={"Done"}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderDiscardModal = () => {
        const {closeAddDiscardModal, isOpenDiscardModal, redirectScreen} = props;
        return (
            <DLModal
                history={props.history}
                maxWidth={"xs"}
                onClose={closeAddDiscardModal}
                open={isOpenDiscardModal}
                id={'discard-modal'}>
                <DLModalTitle
                    id={'discard-title'}
                    onClose={() => closeAddDiscardModal()}
                    title={'Confirmation'}/>
                <DLModalContainer
                    id={'discard-container'}>
                    <div className="mb-2 pb-1 text-center">
                        <DLText id={''}
                                text={"Are you sure you want to discard this transaction?"}
                                fontSize={"sm"}
                                fontWeight={"normal"}
                                marginBottom={"none"}
                        />
                        <DLText id={''}
                                text={"This action cannot be undone."}
                                fontSize={"sm"}
                                fontColor={"danger"}
                                marginBottom={"none"}
                        />
                    </div>
                </DLModalContainer>
                <DLModalActions id={'discard-action'}>
                    <div className="mb-4" style={{paddingTop: '7px'}}>
                        <DLButton
                            id={'discard-cancelBtn'}
                            history={props.history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => closeAddDiscardModal()}/>
                        <DLButton
                            id={'discard-btn'}
                            history={props.history}
                            styleType={"contained"}
                            type={"danger"}
                            label={'Discard'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => {
                                redirectScreen();
                            }}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        );
    };


    const renderHeader = () => {
        if (isMobileView) {
            return (
                <div
                    className={'d-flex align-items-center align-content-center w-100 justify-content-between border-bottom px-3 py-3'}>
                    <DLText id={"purchase-transaction-field"}
                            text={"SWP Transaction: Edit"}
                            fontSize={"md"}
                            fontWeight={'semi-bold'}
                            marginBottom={"none"}
                    />
                </div>
            )
        }

        return (
            <div className='d-flex justify-content-between w-100'>
                <div className='page-header px-0 py-3  m-0'>
                    SWP Transaction: Edit
                </div>
            </div>
        )
    };

    const renderUi = () => {
        return (
            <div>
                <div className="bg-light h-100">
                    <div className="bg-white mb-2 border-rounded border">
                        {renderExistingPortfolio()}
                    </div>
                    <div className={'bg-white border-rounded border mb-5'}>
                        {renderSelectedPortfolio()}
                    </div>
                </div>
                {renderBottomActionButtons()}
            </div>
        );
    };

    const renderMobileViewUi = () => {
        return (
            <div className='mb-5'>
                <div className="bg-light h-100">
                    <div className="bg-white mb-2">
                        {renderExistingPortfolio()}
                    </div>
                    <div className={'bg-white border'}>
                        {renderSelectedPortfolio()}
                    </div>
                </div>
                {renderBottomActionButtons()}
            </div>
        );
    };

    const renderErrorUI = () => {
        const {responseErrors, warningMessage} = props;

        if (isEmpty(responseErrors) && isEmpty(warningMessage)) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={isMobileView ? 'mt-1' : 'mb-3'}>
                {!isEmpty(responseErrors) && <div className={isMobileView ? 'px-3 py-3 error-container' : 'pb-1 error-container'}>
                    <div className='error-vertical-line'/>
                    <div className={'error-panel'}>
                        {!isEmpty(responseErrors) ? responseErrors : warningMessage}
                    </div>
                </div>}
                {!isEmpty(warningMessage) && isEmpty(responseErrors) && <div className={isMobileView ? 'px-3 py-3 error-container' : 'pb-1 error-container'}>
                    <div className={'warning-vertical-line'}/>
                    <div className={'warning-panel'}>
                        {warningMessage}
                    </div>
                </div>}
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>
        );
    };

    if (isEmpty(UIState)) {
        return renderLoader();
    }

    return (
        <div className={isMobileView ? '' : 'bg-light px-4'}
             style={{minHeight: '100%'}}>
            {renderHeader()}
            {renderErrorUI()}
            <div
                className={isMobileView ? '' : 'd-flex flex-column bg-light'}>
                {isMobileView ? renderMobileViewUi() : renderUi()}
            </div>
            {renderDiscardModal()}
            {renderLoader()}
        </div>
    );
};

export default PurchaseSwpTransactionView;
