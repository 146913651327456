import TxnTableSIPView from "./TxnTableSIPView";
import React from "react";
import {connect} from "react-redux";

const TxnTableSIP = (props) => {
    return (
        <TxnTableSIPView
            {...props}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(TxnTableSIP);
