import React from 'react';
import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLDropDown from "../../../core/components/DLDropdown/DLDropDown";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import emptyLeads from '../../../assets/img/empty-leads.png';
import emptyFilter from '../../../assets/img/appIcons/emptyFilter.png';
import emptyFilterFilled from '../../../assets/img/appIcons/emptyFilterFilled.png';
import filterDot from '../../../assets/img/appIcons/filterDot.png';

import {headingData, headingMobileData, cellConfig} from "../../LeadsModal";
import {APP_CONST} from "../../../AppComponents/AppConstants";

import {isEmpty} from "../../../core/components/DLComponentHelper";
import '../../../assets/css/table.css'
import '../../../assets/css/listing.css'
import DLTable from "../../../core/components/DLTable/DLTable";
import {handleKeyPress} from "../../LeadsHelper";
import FilterBadgeView from "../filterBadge/FilterBadgeView";


const LeadListView = (props) => {
    const {
        isXSView, listingCount, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, leads, leadsFilter,
        isShowLoader, getPaginationNextButtonStatus, skipCount, onChangeFilter, UIState,
        isMobileView
    } = props;

    const {keyword, status} = leadsFilter;

    const statusFilterOptions = [
        {label: 'All', value: 'ALL'},
        {label: 'Contacted', value: 'CONTACTED'},
        {label: 'Lost', value: 'LOST'},
        {label: 'Not Contacted', value: 'NOT_CONTACTED'},
        {label: 'On Hold', value: 'ON_HOLD'},
        {label: 'Onboarding Completed', value: 'ONBOARDING_COMPLETED'},
        {label: 'Onboarding Initiated', value: 'ONBOARDING_INITIATED'}
    ];

    const renderHeaderCreateButton = () => {
        const {isMobileView, isXSView} = props;

        if (isXSView) {
            return (
                <div className={'mx-3 my-2'}>
                    <DLButton
                        id={'create-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        padding={'none'}
                        onClick={() => props.history.push("/createLeads/new")}
                        startIcon={<i className="fa-solid fa-plus"/>}/>
                </div>
            );
        }
        return (
            <div className={'d-flex justify-content-center pe-2 my-2' + (isMobileView ? ' page-container' : ' mx-4')}>
                <DLButton
                    id={'create-button'}
                    buttonSize={'sm'}
                    reduceWidth={isMobileView}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => props.history.push("/createLeads/new")}
                    label={"Create Lead"}/>
            </div>
        )

    };

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between pb-1 pt-3'>
                <div className='page-header pt-0'>
                    Leads
                </div>
                <div>
                    {renderHeaderCreateButton()}
                </div>
            </div>
        )
    };

    function isFiltersApplied(filters) {

        for (let key in filters) {
            if (filters.hasOwnProperty(key)) {
                if (Array.isArray(filters[key])) {
                    if (key === 'status' && filters[key][0] === 'NOT_CONTACTED') {
                        return false
                    }
                    if (filters[key].length > 0) {
                        return true;
                    }
                } else if (filters[key] !== '') {
                    return true;
                }
            }
        }
        return false;
    }

    const renderFilterIcon = () => {
        const {isShowFilterMobile, leadsFilter} = props;
        const filterIcon = isShowFilterMobile ? emptyFilterFilled : emptyFilter;

        if (isFiltersApplied(leadsFilter)) {
            return (
                <div className='icon-container'>
                    <img src={filterIcon} alt={''} height={14} width={14}/>
                    <img className='dot' alt={''} src={filterDot} height={8} width={8}/>
                </div>
            );
        }

        return <img src={filterIcon} alt={''} height={14} width={14}/>;
    };

    const renderFilterMobile = () => {
        const {isShowFilterMobile, handleToggleMobileFilter, resetLeadListing, applyFilterBadge} = props;
        const badgeData = applyFilterBadge();

        return <div>
            <div className='d-flex align-items-center justify-content-between gap-3 page-container '>
                <div className='search-input' style={{flex: 8}}>
                    <DLInputTextField id={'leads-searchBox'}
                                      marginBottom={"none"}
                                      rows={1}
                                      styleType={"search"}
                                      onChangeValue={(e) => {
                                          onChangeFilter('keyword', e)
                                      }}
                                      value={keyword}
                                      onKeyPress={handleKeyPress}
                                      placeholder={'Search by Name'}
                                      isSearchable={true}
                                      size="small"
                                      inputHeight={'sm'}
                                      inputType={"text"}
                                      disableHelperMessageHeight={true}

                    />
                </div>
                <div className="d-flex align-items-center">
                    <DLButton
                        id={'filter-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        onClick={() => handleToggleMobileFilter(!isShowFilterMobile)}
                        startIcon={renderFilterIcon()}/>

                    {isFiltersApplied(leadsFilter) &&
                    <div>
                        <i className="fa-solid fa-xmark cursor ms-2 "
                           onClick={() => resetLeadListing()}
                           style={{fontSize: 18}}/>
                    </div>
                    }
                </div>
            </div>
            {isShowFilterMobile &&
            <div className='bg-light mt-2' style={{paddingTop: 10, paddingBottom: 10}}>
                <div className='page-container'>
                    <DLDropDown
                        id={"status-filter"}
                        label={'Status'}
                        labelFontColor={'black'}
                        labelPlacement={'top'}
                        value={status[0]}
                        options={statusFilterOptions}
                        onSelect={(e) => {
                            onChangeFilter('status', e)
                        }}
                        disableHelperMessageHeight={true}
                        minWidth={'100%'}
                        // minWidth={'92dvw'}
                    />
                    <div>
                        {badgeData.data !== 'UNASSIGNED' && <div className='d-flex align-items-end mb-1 pb-1'>
                            <FilterBadgeView
                                badgeData={badgeData}
                                onClose={() => {
                                    onChangeFilter('fromtoDate', '')
                                }}
                            />
                        </div>}
                    </div>
                </div>
            </div>
            }
        </div>
    };

    const renderFilter = () => {
        const {applyFilterBadge} = props;
        const badgeData = applyFilterBadge();

        return (
            <div>
                <div className='d-flex'>
                    <div className='search-input' style={{flex: 1.8}}>
                        <DLInputTextField
                            id={'lead-list-search-filter'}
                            label={'Search'}
                            labelFontSize={'sm'}
                            labelFontColor={'grayDark'}
                            marginBottom={"none"}
                            rows={1}
                            styleType={"search"}
                            onChangeValue={(e) => {
                                onChangeFilter('keyword', e)
                            }}
                            value={isEmpty(keyword) ? '' : keyword}
                            placeholder={'Search by Name'}
                            isSearchable={true}
                            size="small"
                            inputHeight={'sm'}
                            inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                            inputType={"text"}
                            disableHelperMessageHeight={true}
                        />
                    </div>
                    <div className='select-filter ms-3' style={{flex: 1}}>
                        <DLDropDown
                            id={"status-filter"}
                            label={'Status'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={status[0]}
                            minWidth={"100%"}
                            options={statusFilterOptions}
                            onSelect={(e) => {
                                onChangeFilter('status', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                    {badgeData.data !== 'UNASSIGNED' && <div className='d-flex align-items-end mb-1 pb-1 ms-3'>
                        <FilterBadgeView
                            badgeData={badgeData}
                            onClose={() => {
                                onChangeFilter('fromtoDate', '')
                            }}
                        />
                    </div>}
                </div>
            </div>
        );
    };

    const emptyList = () => {
        return <div className='d-flex justify-content-center align-items-center gray-lg-text'
                    style={{minHeight: 'calc(100dvh - 132px)', overflow: 'hidden', fontSize: '16px'}}>
            <div className='text-center'>
                <img src={emptyLeads} alt={''}/>
                <DLText id={'create-first-lead'}
                        text={'Please create your first lead.'}
                        marginBottom={'md'}
                />
                <DLButton
                    id={'create-button'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => props.history.push("/createLeads/new")}
                    label={'Create Lead'}/>
            </div>
        </div>
    };

    const renderMobileTable = () => {
        const {getFormattedTableData, redirectToDetailsScreen, isShowFilterMobile} = props;
        let tableData = getFormattedTableData(leads);

        if (isEmpty(leads)) {
            return (
                <div className='d-flex align-items-center justify-content-center empty-records'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 370px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className="w-100 pagination-container">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 438px)' : 'calc(100dvh - 321px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 438px)' : 'calc(100dvh - 321px)'}
                    isRowClickable={true}
                    onRowClick={(leadId) => {
                        redirectToDetailsScreen(leadId)
                    }}
                    cellConfig={cellConfig}
                    headerData={headingMobileData}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const renderCount = () => {
        const {isXSView, handleRefreshButton} = props;

        if (isXSView) {
            return (
                <div className='page-container mt-3 mb-3'>
                    {!isEmpty(listingCount) &&
                    <div className='d-flex align-items-center'>
                    <DLText id={''}
                            isInline={true}
                            fontColor={'grayDark'}
                            fontSize={'sm'}
                            marginBottom={'none'}
                            fontWeight={'semi-bold'}
                            text={'Showing ' + (listingCount) + ' records'}/>
                        <div className={'d-flex align-items-center mx-2'}
                             onClick={() => {
                                 handleRefreshButton()
                             }}>
                            <i className="fa-solid fa-rotate-right cursor"/>
                        </div>
                        </div>
                    }
                </div>)
        }
        return (
            <div className={isMobileView? 'py-3' : 'pb-3'} style={{height: '37px'}}>
                {!isEmpty(listingCount) &&
                <div className={'d-flex align-items-center '}>
                    <DLText id={''}
                            isInline={true}
                            fontColor={'grayDark'}
                            fontSize={'sm'}
                            marginBottom={'none'}
                            fontWeight={'semi-bold'}
                            text={'Showing ' + (listingCount) + ' records'}/>
                    <div className={'d-flex align-items-center mx-2'}
                         onClick={() => {
                             handleRefreshButton()
                         }}>
                        <i className="fa-solid fa-rotate-right cursor"/>
                    </div>
                </div>}
            </div>
        )
    };

    const renderDesktopTable = () => {
        const {getFormattedTableData, redirectToDetailsScreen, isShowFilterMobile} = props;
        let tableData = getFormattedTableData(leads);

        if (isEmpty(leads)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center panel'
                     style={{maxHeight: 'calc(100dvh - 277px)', minHeight: 'calc(100dvh - 277px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className="w-100">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 340px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 340px)'}
                    isRowClickable={true}
                    onRowClick={(leadId) => {
                        redirectToDetailsScreen(leadId)
                    }}
                    cellConfig={cellConfig}
                    headerData={headingData}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const renderLeadsTable = () => {
        return (
            <div className={'w-100 mt-3' + ((isMobileView && !isXSView) ? '  page-container' : '')}>
                {renderCount()}
                {isXSView ?
                    renderMobileTable()
                    :
                    <div>
                        {renderDesktopTable()}
                    </div>
                }
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    if (UIState === APP_CONST.CONTENT_UNAVAILABLE) {
        return (
            <div className='page-container'>
                {emptyList()}
            </div>
        );
    }

    if (isMobileView) {
        return (
            <div>
                <div className={'w-100'} style={{
                    background: 'rgba(0, 0, 0, 0.02)'
                }}>
                    {renderHeader()}
                    {renderFilterMobile()}
                    {renderLeadsTable()}
                    {renderLoader()}
                </div>
            </div>
        );
    }

    return (
        <div className={'h-100 d-flex justify-content-center bg-light'}>
            <div className={'w-100'}>
                {renderHeader()}
                <div className='page-container pt-1'>
                    {renderFilter()}
                </div>
                <div className='page-container'
                     style={{minHeight: 'calc(100dvh - 205px)'}}>
                    {renderLeadsTable()}
                </div>
                {renderLoader()}
            </div>
        </div>
    );
};

export default LeadListView;