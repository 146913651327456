import React, {useEffect} from 'react';
import DLText from '../../../../../core/components/DLText/DLText';
import DLInputTextField from '../../../../../core/components/DLInputTextField/DLInputTextField';
import DLDatePicker from '../../../../../core/components/DLDatePicker/DLDatePicker';
import "./nomineeList/NomineeList.css"
import DLButton from "../../../../../core/components/DLButton/DLButton";
import {isEmpty} from "../../../../../utils/validations";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import {debounce} from "../../../../../core/components/DLComponentHelper";
import DLDocUploader from "../../../../../core/components/DLDocUploader/DLDocUploader";
import {handleFileError} from "../../../../../utils/FileHelper";
import DLSearchAutoComplete from "../../../../../core/components/DLSearchAutoComplete/DLSearchAutoComplete";
import NumberInputBasic from "./NumberInputTextField";
import {searchOnWordBoundary} from "../../../../../utils/helper";

const MajorNomineeCreateOrUpdateView = (props) => {

    const {personalInformation, nomineeAddress, errors, handleChange, profile, dropdownValues, isLoaderVisible,
        scrollToFieldId, isMobileView, accountType, getFirstApplicantData} = props;
    const {dateOfBirth, name, PANNumber, relation, percentageAllocation} = personalInformation;
    const {city, stateCode, addressLine1, addressLine2, addressLine3, zipCode} = nomineeAddress;

    useEffect(() => {
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce()
            }
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [scrollToFieldId]);

    const handleScrollChangeWithDebounce = debounce(() => {

        handleChange('scrollToFieldId', '')
    }, 1000);

    const renderPersonalInformationMobile = () => {

        return (
            <div>
                <div className='section-title pt-0'
                     id={'Personal-information'}
                     style={{lineHeight: '14px'}}>
                    Personal Information
                </div>
                <div>
                    <div>
                        <div id='personalInformation-name-id'>
                            <div className='non-editable-inputs-label'>
                                Name
                            </div>
                            <div className='non-editable-input-border mb-3'>
                                <div className='non-editable-inputs'>
                                    {name}
                                </div>
                            </div>
                            {/*<DLInputTextField*/}
                            {/*    id={''}*/}
                            {/*    label={'Name'}*/}
                            {/*    placeholder={"Please enter full name"}*/}
                            {/*    value={name}*/}
                            {/*    inputType={'text'}*/}
                            {/*    maxCharLength={62}*/}
                            {/*    isClearable={false}*/}
                            {/*    isRequired={true}*/}
                            {/*    disableHelperMessageHeight={true}*/}
                            {/*    marginBottom={'sm'}*/}
                            {/*    hideCharLength={true}*/}
                            {/*    onChangeValue={(value) => {*/}
                            {/*        handleChange('name', value, "personalInformation")*/}
                            {/*    }}*/}
                            {/*    error={errors.personalInformation.name !== ''}*/}
                            {/*    helperMessage={errors.personalInformation.name}*/}
                            {/*/>*/}
                        </div>
                        <div id='personalInformation-PANNumber-id'>
                            <div className='non-editable-inputs-label'>
                                PAN Number
                            </div>
                            <div className='non-editable-input-border mb-3'>
                                <div className='non-editable-inputs'>
                                    {PANNumber.toUpperCase()}
                                </div>
                            </div>
                            {/*<DLInputTextField*/}
                            {/*    id={''}*/}
                            {/*    label={'PAN Number'}*/}
                            {/*    value={PANNumber.toUpperCase()}*/}
                            {/*    inputType={'text'}*/}
                            {/*    maxCharLength={101}*/}
                            {/*    isRequired={true}*/}
                            {/*    isClearable={false}*/}
                            {/*    disableHelperMessageHeight={true}*/}
                            {/*    marginBottom={'sm'}*/}
                            {/*    hideCharLength={true}*/}
                            {/*    onChangeValue={(value) => {*/}
                            {/*        handleChange('PANNumber', value, "personalInformation")*/}
                            {/*    }}*/}
                            {/*    error={errors.personalInformation.PANNumber !== ''}*/}
                            {/*    helperMessage={errors.personalInformation.PANNumber}*/}
                            {/*/>*/}
                        </div>
                    </div>
                    <div>
                        <div id='personalInformation-dateOfBirth-id' className="mb-3">
                            <div className='non-editable-inputs-label'>
                                Date of Birth
                            </div>
                            <div className='non-editable-input-border mb-3'>
                                <div className='non-editable-inputs'>
                                    {dateOfBirth}
                                </div>
                            </div>
                            {/*<DLDatePicker*/}
                            {/*    id={'InboxView-FromDate'}*/}
                            {/*    label={'Date of Birth'}*/}
                            {/*    value={dateOfBirth}*/}
                            {/*    maximumDate={new Date()}*/}
                            {/*    isClearable={false}*/}
                            {/*    isRequired={true}*/}
                            {/*    disableHelperMessageHeight={true}*/}
                            {/*    marginBottom={'sm'}*/}
                            {/*    minWidth={'100%'}*/}
                            {/*    minimumDate={'1900-12-30'}*/}
                            {/*    onChangeValue={(value) => {*/}
                            {/*        handleChange('dateOfBirth', value, "personalInformation")*/}
                            {/*    }}*/}
                            {/*    error={errors.personalInformation.dateOfBirth !== ''}*/}
                            {/*    helperMessage={errors.personalInformation.dateOfBirth}*/}

                            {/*/>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderNominationInformationMobile = () => {
        return (
            <div>
                <div className='section-title'
                     id={'Nomination-information'}>
                    Nomination Information
                </div>
                <div>
                    <div>
                        <div id='personalInformation-relation-id'>
                            <div className='non-editable-inputs-label'>
                                Relation
                            </div>
                            <div className='non-editable-input-border mb-3'>
                                <div className='non-editable-inputs'>
                                    {relation?.value}
                                </div>
                            </div>
                            {/*<DLSearchAutoComplete*/}
                            {/*    id={'relation-dropDown'}*/}
                            {/*    label={'Relation'}*/}
                            {/*    value={relation}*/}
                            {/*    loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.relations)}*/}
                            {/*    onSelect={(value) =>  handleChange('relation', value, "personalInformation")}*/}
                            {/*    isClearable={false}*/}
                            {/*    isSearchable={true}*/}
                            {/*    error={errors.personalInformation.relation !== ''}*/}
                            {/*    helperMessage={errors.personalInformation.relation}*/}
                            {/*    itemNotFoundText={"No options found'"}*/}
                            {/*    disableHelperMessageHeight={true}*/}
                            {/*    marginBottom={'sm'}*/}
                            {/*    isMulti={false}*/}
                            {/*    defaultOptions={dropdownValues?.relations}*/}
                            {/*    noOptionsMessage={"No options found'"}*/}
                            {/*    isRequired={true}*/}
                            {/*/>*/}
                        </div>
                        {/*<div id='personalInformation-percentageAllocation-id' className='col-6'>*/}
                        {/*    <DLInputTextField*/}
                        {/*        id={''}*/}
                        {/*        label={'Percentage Allocation'}*/}
                        {/*        value={percentageAllocation}*/}
                        {/*        inputType={'number'}*/}
                        {/*        maxCharLength={3}*/}
                        {/*        isClearable={true}*/}
                        {/*        disableHelperMessageHeight={true}*/}
                        {/*        marginBottom={'sm'}*/}
                        {/*        isRequired={true}*/}
                        {/*        hideCharLength={true}*/}
                        {/*        onChangeValue={(value) => {*/}
                        {/*            handleChange('percentageAllocation', value, "personalInformation")*/}
                        {/*        }}*/}
                        {/*        error={errors.personalInformation.percentageAllocation !== ''}*/}
                        {/*        helperMessage={errors.personalInformation.percentageAllocation}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div id='personalInformation-percentageAllocation-id' className='mb-3'>
                            <div className='non-editable-inputs-label'>
                                Percentage Allocation
                            </div>
                            <div className='non-editable-input-border mb-3'>
                                <div className='non-editable-inputs'>
                                    {percentageAllocation}
                                </div>
                            </div>

                            {/*<div style={{marginBottom : '2px'}}>*/}
                            {/*    <DLText id={''}*/}
                            {/*            text={'Percentage Allocation'}*/}
                            {/*            fontSize={'xs'}*/}
                            {/*            marginBottom={"none"}*/}
                            {/*            fontColor={errors.personalInformation.percentageAllocation !== '' ? 'danger' : "grayDark"}*/}
                            {/*    />*/}
                            {/*</div>*/}

                            {/*<NumberInputBasic*/}
                            {/*    onChange={(value)=>{handleChange('percentageAllocation', value, "personalInformation")}}*/}
                            {/*    value={percentageAllocation}*/}
                            {/*/>*/}

                            {/*{(errors.personalInformation.percentageAllocation !== '') &&*/}
                            {/*<DLText id={"percentage-allocation"}*/}
                            {/*        text={errors.personalInformation.percentageAllocation}*/}
                            {/*        fontSize={'xs'}*/}
                            {/*        fontColor={"danger"}*/}
                            {/*/>}*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderAddressMobile = () => {
        return (
            <div className=''>
                <div className='d-flex justify-content-between'>
                    <div className='section-title'
                         id={'Address'}>
                        Address
                    </div>
                </div>

                <div>
                    <div id='nomineeAddress-addressLine1-id'>
                        <div className='non-editable-inputs-label'>
                            Address Line 1
                        </div>
                        <div className='non-editable-input-border mb-3 overflow-hidden'>
                            <div className='non-editable-inputs'>
                                {addressLine1}
                            </div>
                        </div>
                        {/*<DLInputTextField*/}
                        {/*    id={''}*/}
                        {/*    label={'Address Line 1'}*/}
                        {/*    value={addressLine1}*/}
                        {/*    inputType={'text'}*/}
                        {/*    maxCharLength={50}*/}
                        {/*    isMulti={true}*/}
                        {/*    isClearable={false}*/}
                        {/*    isRequired={true}*/}
                        {/*    disableHelperMessageHeight={true}*/}
                        {/*    marginBottom={'sm'}*/}
                        {/*    hideCharLength={true}*/}
                        {/*    onChangeValue={(value) => {*/}
                        {/*        handleChange('addressLine1', value, 'nomineeAddress')*/}
                        {/*    }}*/}
                        {/*    onBlur={() => {*/}
                        {/*    }}*/}
                        {/*    error={errors.nomineeAddress.addressLine1 !== ''}*/}
                        {/*    helperMessage={errors.nomineeAddress.addressLine1}*/}
                        {/*/>*/}
                    </div>
                    <div id='nomineeAddress-addressLine2-id'>
                        <div className='non-editable-inputs-label'>
                            Address Line 2 (Optional)
                        </div>
                        <div className='non-editable-input-border mb-3 overflow-hidden'>
                            <div className='non-editable-inputs'>
                                {addressLine2}
                            </div>
                        </div>
                        {/*<DLInputTextField*/}
                        {/*    id={''}*/}
                        {/*    label={'Address Line 2 (Optional)'}*/}
                        {/*    value={addressLine2}*/}
                        {/*    inputType={'text'}*/}
                        {/*    maxCharLength={50}*/}
                        {/*    isMulti={true}*/}
                        {/*    isClearable={false}*/}
                        {/*    isRequired={true}*/}
                        {/*    disableHelperMessageHeight={true}*/}
                        {/*    marginBottom={'sm'}*/}
                        {/*    hideCharLength={true}*/}
                        {/*    onChangeValue={(value) => {*/}
                        {/*        handleChange('addressLine2', value, 'nomineeAddress')*/}
                        {/*    }}*/}
                        {/*    onBlur={() => {*/}
                        {/*    }}*/}
                        {/*    error={errors.nomineeAddress.addressLine2 !== ''}*/}
                        {/*    helperMessage={errors.nomineeAddress.addressLine2}*/}
                        {/*/>*/}
                    </div>
                    <div id='nomineeAddress-addressLine3-id'>
                        <div className='non-editable-inputs-label'>
                            Address Line 3 (Optional)
                        </div>
                        <div className='non-editable-input-border mb-3 overflow-hidden'>
                            <div className='non-editable-inputs'>
                                {addressLine3}
                            </div>
                        </div>
                        {/*<DLInputTextField*/}
                        {/*    id={''}*/}
                        {/*    label={'Address Line 3 (Optional)'}*/}
                        {/*    value={addressLine3}*/}
                        {/*    inputType={'text'}*/}
                        {/*    maxCharLength={50}*/}
                        {/*    isClearable={false}*/}
                        {/*    isRequired={true}*/}
                        {/*    disableHelperMessageHeight={true}*/}
                        {/*    marginBottom={'sm'}*/}
                        {/*    hideCharLength={true}*/}
                        {/*    onChangeValue={(value) => {*/}
                        {/*        handleChange('addressLine3', value, 'nomineeAddress')*/}
                        {/*    }}*/}
                        {/*    onBlur={() => {*/}
                        {/*    }}*/}
                        {/*    error={errors.nomineeAddress.addressLine3 !== ''}*/}
                        {/*    helperMessage={errors.nomineeAddress.addressLine3}*/}
                        {/*/>*/}
                    </div>
                    <div>
                        <div>
                            <div id='nomineeAddress-city-id'>
                                <div className='non-editable-inputs-label'>
                                    City
                                </div>
                                <div className='non-editable-input-border mb-3'>
                                    <div className='non-editable-inputs'>
                                        {city}
                                    </div>
                                </div>
                                {/*<DLInputTextField*/}
                                {/*    id={''}*/}
                                {/*    label={'City'}*/}
                                {/*    value={city}*/}
                                {/*    inputType={'text'}*/}
                                {/*    maxCharLength={30}*/}
                                {/*    isClearable={false}*/}
                                {/*    isRequired={true}*/}
                                {/*    disableHelperMessageHeight={true}*/}
                                {/*    marginBottom={'sm'}*/}
                                {/*    hideCharLength={true}*/}
                                {/*    onChangeValue={(value) => {*/}
                                {/*        handleChange('city', value, 'nomineeAddress')*/}
                                {/*    }}*/}
                                {/*    onBlur={() => {*/}
                                {/*    }}*/}
                                {/*    error={errors.nomineeAddress.city !== ''}*/}
                                {/*    helperMessage={errors.nomineeAddress.city}*/}
                                {/*/>*/}
                            </div>
                            <div id='nomineeAddress-zipCode-id'>
                                <div className='non-editable-inputs-label'>
                                    Pincode
                                </div>
                                <div className='non-editable-input-border mb-3'>
                                    <div className='non-editable-inputs'>
                                        {zipCode}
                                    </div>
                                </div>
                                {/*<DLInputTextField*/}
                                {/*    id={''}*/}
                                {/*    label={'Pincode'}*/}
                                {/*    value={zipCode}*/}
                                {/*    inputType={"number"}*/}
                                {/*    maxCharLength={6}*/}
                                {/*    isClearable={false}*/}
                                {/*    isRequired={true}*/}
                                {/*    disableHelperMessageHeight={true}*/}
                                {/*    marginBottom={'sm'}*/}
                                {/*    hideCharLength={true}*/}
                                {/*    onChangeValue={(value) => {*/}
                                {/*        handleChange('zipCode', value, 'nomineeAddress')*/}
                                {/*    }}*/}
                                {/*    onBlur={() => {*/}
                                {/*    }}*/}
                                {/*    error={errors.nomineeAddress.zipCode !== ''}*/}
                                {/*    helperMessage={errors.nomineeAddress.zipCode}*/}
                                {/*/>*/}

                            </div>
                        </div>

                        <div>
                            <div id='nomineeAddress-stateCode-id'>
                                <div className='w-100'>
                                    <div className='non-editable-inputs-label'>
                                        State
                                    </div>
                                    <div className='non-editable-input-border mb-3'>
                                        <div className='non-editable-inputs'>
                                            {stateCode?.value}
                                        </div>
                                    </div>
                                    {/*<DLSearchAutoComplete*/}
                                    {/*    id={'stateCode-dropDown'}*/}
                                    {/*    label={'State'}*/}
                                    {/*    value={stateCode}*/}
                                    {/*    onSelect={(value) => handleChange('stateCode', value, 'nomineeAddress')}*/}
                                    {/*    isClearable={false}*/}
                                    {/*    isSearchable={true}*/}
                                    {/*    dropDownPlacement={"top"}*/}
                                    {/*    error={errors.nomineeAddress.stateCode !== ''}*/}
                                    {/*    helperMessage={errors.nomineeAddress.stateCode}*/}
                                    {/*    itemNotFoundText={"No options found'"}*/}
                                    {/*    disableHelperMessageHeight={true}*/}
                                    {/*    marginBottom={'sm'}*/}
                                    {/*    isMulti={false}*/}
                                    {/*    loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.states)}*/}
                                    {/*    defaultOptions={dropdownValues?.states}*/}
                                    {/*    noOptionsMessage={"No options found'"}*/}
                                    {/*    isRequired={true}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderPersonalInformation = () => {

        return (
            <div>
                <div className='section-title pt-0'
                     id={'Personal-information'}
                     style={{lineHeight: '14px'}}>
                    Personal Information
                </div>
                <div className='panel'>
                    <div className='row'>
                        <div id='personalInformation-name-id' className="col-6">
                            <div className='non-editable-inputs-label'>
                                Name
                            </div>
                            <div className='non-editable-input-border mb-3'>
                                <div className='non-editable-inputs'>
                                    {name}
                                </div>
                            </div>
                            {/*<DLInputTextField*/}
                            {/*    id={''}*/}
                            {/*    label={'Name'}*/}
                            {/*    placeholder={"Please enter full name"}*/}
                            {/*    value={name}*/}
                            {/*    inputType={'text'}*/}
                            {/*    maxCharLength={62}*/}
                            {/*    isClearable={false}*/}
                            {/*    isRequired={true}*/}
                            {/*    disableHelperMessageHeight={true}*/}
                            {/*    marginBottom={'sm'}*/}
                            {/*    hideCharLength={true}*/}
                            {/*    onChangeValue={(value) => {*/}
                            {/*        handleChange('name', value, "personalInformation")*/}
                            {/*    }}*/}
                            {/*    error={errors.personalInformation.name !== ''}*/}
                            {/*    helperMessage={errors.personalInformation.name}*/}
                            {/*/>*/}
                        </div>
                        <div id='personalInformation-PANNumber-id' className="col-6">
                            <div className='non-editable-inputs-label'>
                                PAN Number
                            </div>
                            <div className='non-editable-input-border mb-3'>
                                <div className='non-editable-inputs'>
                                    {PANNumber.toUpperCase()}
                                </div>
                            </div>
                            {/*<DLInputTextField*/}
                            {/*    id={''}*/}
                            {/*    label={'PAN Number'}*/}
                            {/*    value={PANNumber.toUpperCase()}*/}
                            {/*    inputType={'text'}*/}
                            {/*    maxCharLength={101}*/}
                            {/*    isRequired={true}*/}
                            {/*    isClearable={false}*/}
                            {/*    disableHelperMessageHeight={true}*/}
                            {/*    marginBottom={'sm'}*/}
                            {/*    hideCharLength={true}*/}
                            {/*    onChangeValue={(value) => {*/}
                            {/*        handleChange('PANNumber', value, "personalInformation")*/}
                            {/*    }}*/}
                            {/*    error={errors.personalInformation.PANNumber !== ''}*/}
                            {/*    helperMessage={errors.personalInformation.PANNumber}*/}
                            {/*/>*/}
                        </div>
                    </div>
                    <div className='row'>
                        <div id='personalInformation-dateOfBirth-id' className="col-6 mb-3">
                            <div className='non-editable-inputs-label'>
                                Date of Birth
                            </div>
                            <div className='non-editable-input-border mb-3'>
                                <div className='non-editable-inputs'>
                                    {dateOfBirth}
                                </div>
                            </div>
                            {/*<DLDatePicker*/}
                            {/*    id={'InboxView-FromDate'}*/}
                            {/*    label={'Date of Birth'}*/}
                            {/*    value={dateOfBirth}*/}
                            {/*    maximumDate={new Date()}*/}
                            {/*    isClearable={false}*/}
                            {/*    isRequired={true}*/}
                            {/*    disableHelperMessageHeight={true}*/}
                            {/*    marginBottom={'sm'}*/}
                            {/*    minWidth={'100%'}*/}
                            {/*    minimumDate={'1900-12-30'}*/}
                            {/*    onChangeValue={(value) => {*/}
                            {/*        handleChange('dateOfBirth', value, "personalInformation")*/}
                            {/*    }}*/}
                            {/*    error={errors.personalInformation.dateOfBirth !== ''}*/}
                            {/*    helperMessage={errors.personalInformation.dateOfBirth}*/}
                            {/*/>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderNominationInformation = () => {
        return (
            <div className='mt-2'>
                <div className='section-title'
                     id={'Nomination-information'}>
                    Nomination Information
                </div>
                <div className={"panel"}>
                    <div className='row'>
                        <div id='personalInformation-relation-id' className='col-6'>
                            <div className='non-editable-inputs-label'>
                                Relation
                            </div>
                            <div className='non-editable-input-border mb-3'>
                                <div className='non-editable-inputs'>
                                    {relation?.value}
                                </div>
                            </div>
                            {/*<DLSearchAutoComplete*/}
                            {/*    id={'relation-dropDown'}*/}
                            {/*    label={'Relation'}*/}
                            {/*    value={relation}*/}
                            {/*    loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.relations)}*/}
                            {/*    onSelect={(value) =>  handleChange('relation', value, "personalInformation")}*/}
                            {/*    isClearable={false}*/}
                            {/*    isSearchable={true}*/}
                            {/*    error={errors.personalInformation.relation !== ''}*/}
                            {/*    helperMessage={errors.personalInformation.relation}*/}
                            {/*    itemNotFoundText={"No options found'"}*/}
                            {/*    disableHelperMessageHeight={true}*/}
                            {/*    marginBottom={'sm'}*/}
                            {/*    isMulti={false}*/}
                            {/*    defaultOptions={dropdownValues?.relations}*/}
                            {/*    noOptionsMessage={"No options found'"}*/}
                            {/*    isRequired={true}*/}
                            {/*/>*/}
                        </div>
                        {/*<div id='personalInformation-percentageAllocation-id' className='col-6'>*/}
                        {/*    <DLInputTextField*/}
                        {/*        id={''}*/}
                        {/*        label={'Percentage Allocation'}*/}
                        {/*        value={percentageAllocation}*/}
                        {/*        inputType={'number'}*/}
                        {/*        maxCharLength={3}*/}
                        {/*        isClearable={true}*/}
                        {/*        disableHelperMessageHeight={true}*/}
                        {/*        marginBottom={'sm'}*/}
                        {/*        isRequired={true}*/}
                        {/*        hideCharLength={true}*/}
                        {/*        onChangeValue={(value) => {*/}
                        {/*            handleChange('percentageAllocation', value, "personalInformation")*/}
                        {/*        }}*/}
                        {/*        error={errors.personalInformation.percentageAllocation !== ''}*/}
                        {/*        helperMessage={errors.personalInformation.percentageAllocation}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div id='personalInformation-percentageAllocation-id' className='col-6 mb-3'>
                            <div className='non-editable-inputs-label'>
                                Percentage Allocation
                            </div>
                            <div className='non-editable-input-border mb-3'>
                                <div className='non-editable-inputs'>
                                    {percentageAllocation}
                                </div>
                            </div>
                            {/*<div style={{marginBottom : '2px'}}>*/}
                            {/*    <DLText id={''}*/}
                            {/*            text={'Percentage Allocation'}*/}
                            {/*            fontSize={'xs'}*/}
                            {/*            marginBottom={"none"}*/}
                            {/*            fontColor={errors.personalInformation.percentageAllocation !== '' ? 'danger' : "grayDark"}*/}
                            {/*    />*/}
                            {/*</div>*/}

                            {/*<NumberInputBasic*/}
                            {/*    onChange={(value)=>{handleChange('percentageAllocation', value, "personalInformation")}}*/}
                            {/*    value={percentageAllocation}*/}
                            {/*/>*/}

                            {/*{(errors.personalInformation.percentageAllocation !== '') &&*/}
                            {/*<DLText id={"percentage-allocation"}*/}
                            {/*        text={errors.personalInformation.percentageAllocation}*/}
                            {/*        fontSize={'xs'}*/}
                            {/*        fontColor={"danger"}*/}
                            {/*/>}*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderAddress = () => {
        return (
            <div className=''>
                <div className='d-flex justify-content-between'>
                    <div className='section-title'
                         id={'Address'}>
                        Address
                    </div>
                </div>
                <div className="panel">
                    <div id='nomineeAddress-addressLine1-id'>
                        <div className='non-editable-inputs-label'>
                            Address Line 1
                        </div>
                        <div className='non-editable-input-border mb-3'>
                            <div className='non-editable-inputs'>
                                {addressLine1}
                            </div>
                        </div>
                        {/*<DLInputTextField*/}
                        {/*    id={''}*/}
                        {/*    label={'Address Line 1'}*/}
                        {/*    value={addressLine1}*/}
                        {/*    inputType={'text'}*/}
                        {/*    maxCharLength={50}*/}
                        {/*    isMulti={true}*/}
                        {/*    isClearable={false}*/}
                        {/*    isRequired={true}*/}
                        {/*    disableHelperMessageHeight={true}*/}
                        {/*    marginBottom={'sm'}*/}
                        {/*    hideCharLength={true}*/}
                        {/*    onChangeValue={(value) => {*/}
                        {/*        handleChange('addressLine1', value, 'nomineeAddress')*/}
                        {/*    }}*/}
                        {/*    onBlur={() => {*/}
                        {/*    }}*/}
                        {/*    error={errors.nomineeAddress.addressLine1 !== ''}*/}
                        {/*    helperMessage={errors.nomineeAddress.addressLine1}*/}
                        {/*/>*/}
                    </div>
                    <div id='nomineeAddress-addressLine2-id'>
                        <div className='non-editable-inputs-label'>
                            Address Line 2 (Optional)
                        </div>
                        <div className='non-editable-input-border mb-3'>
                            <div className='non-editable-inputs'>
                                {addressLine2}
                            </div>
                        </div>
                        {/*<DLInputTextField*/}
                        {/*    id={''}*/}
                        {/*    label={'Address Line 2 (Optional)'}*/}
                        {/*    value={addressLine2}*/}
                        {/*    inputType={'text'}*/}
                        {/*    maxCharLength={50}*/}
                        {/*    isMulti={true}*/}
                        {/*    isClearable={false}*/}
                        {/*    isRequired={true}*/}
                        {/*    disableHelperMessageHeight={true}*/}
                        {/*    marginBottom={'sm'}*/}
                        {/*    hideCharLength={true}*/}
                        {/*    onChangeValue={(value) => {*/}
                        {/*        handleChange('addressLine2', value, 'nomineeAddress')*/}
                        {/*    }}*/}
                        {/*    onBlur={() => {*/}
                        {/*    }}*/}
                        {/*    error={errors.nomineeAddress.addressLine2 !== ''}*/}
                        {/*    helperMessage={errors.nomineeAddress.addressLine2}*/}
                        {/*/>*/}
                    </div>
                    <div id='nomineeAddress-addressLine3-id'>
                        <div className='non-editable-inputs-label'>
                            Address Line 3 (Optional)
                        </div>
                        <div className='non-editable-input-border mb-3'>
                            <div className='non-editable-inputs'>
                                {addressLine3}
                            </div>
                        </div>
                        {/*<DLInputTextField*/}
                        {/*    id={''}*/}
                        {/*    label={'Address Line 3 (Optional)'}*/}
                        {/*    value={addressLine3}*/}
                        {/*    inputType={'text'}*/}
                        {/*    maxCharLength={50}*/}
                        {/*    isClearable={false}*/}
                        {/*    isRequired={true}*/}
                        {/*    disableHelperMessageHeight={true}*/}
                        {/*    marginBottom={'sm'}*/}
                        {/*    hideCharLength={true}*/}
                        {/*    onChangeValue={(value) => {*/}
                        {/*        handleChange('addressLine3', value, 'nomineeAddress')*/}
                        {/*    }}*/}
                        {/*    onBlur={() => {*/}
                        {/*    }}*/}
                        {/*    error={errors.nomineeAddress.addressLine3 !== ''}*/}
                        {/*    helperMessage={errors.nomineeAddress.addressLine3}*/}
                        {/*/>*/}
                    </div>
                    <div>
                        <div className="row">
                            <div id='nomineeAddress-city-id' className="col-6">
                                <div className='non-editable-inputs-label'>
                                    City
                                </div>
                                <div className='non-editable-input-border mb-3'>
                                    <div className='non-editable-inputs'>
                                        {city}
                                    </div>
                                </div>
                                {/*<DLInputTextField*/}
                                {/*    id={''}*/}
                                {/*    label={'City'}*/}
                                {/*    value={city}*/}
                                {/*    inputType={'text'}*/}
                                {/*    maxCharLength={30}*/}
                                {/*    isClearable={false}*/}
                                {/*    isRequired={true}*/}
                                {/*    disableHelperMessageHeight={true}*/}
                                {/*    marginBottom={'sm'}*/}
                                {/*    hideCharLength={true}*/}
                                {/*    onChangeValue={(value) => {*/}
                                {/*        handleChange('city', value, 'nomineeAddress')*/}
                                {/*    }}*/}
                                {/*    onBlur={() => {*/}
                                {/*    }}*/}
                                {/*    error={errors.nomineeAddress.city !== ''}*/}
                                {/*    helperMessage={errors.nomineeAddress.city}*/}
                                {/*/>*/}
                            </div>
                            <div id='nomineeAddress-zipCode-id' className="col-6">
                                <div className='non-editable-inputs-label'>
                                    Pincode
                                </div>
                                <div className='non-editable-input-border mb-3'>
                                    <div className='non-editable-inputs'>
                                        {zipCode}
                                    </div>
                                </div>
                                {/*<DLInputTextField*/}
                                {/*    id={''}*/}
                                {/*    label={'Pincode'}*/}
                                {/*    value={zipCode}*/}
                                {/*    inputType={"number"}*/}
                                {/*    maxCharLength={6}*/}
                                {/*    isClearable={false}*/}
                                {/*    isRequired={true}*/}
                                {/*    disableHelperMessageHeight={true}*/}
                                {/*    marginBottom={'sm'}*/}
                                {/*    hideCharLength={true}*/}
                                {/*    onChangeValue={(value) => {*/}
                                {/*        handleChange('zipCode', value, 'nomineeAddress')*/}
                                {/*    }}*/}
                                {/*    onBlur={() => {*/}
                                {/*    }}*/}
                                {/*    error={errors.nomineeAddress.zipCode !== ''}*/}
                                {/*    helperMessage={errors.nomineeAddress.zipCode}*/}
                                {/*/>*/}

                            </div>
                        </div>

                        <div className={"row"}>
                            <div id='nomineeAddress-stateCode-id' className="col-6">
                                <div className='w-100'>
                                    <div className='non-editable-inputs-label'>
                                        State
                                    </div>
                                    <div className='non-editable-input-border mb-3'>
                                        <div className='non-editable-inputs'>
                                            {stateCode?.value}
                                        </div>
                                    </div>
                                    {/*<DLSearchAutoComplete*/}
                                    {/*    id={'stateCode-dropDown'}*/}
                                    {/*    label={'State'}*/}
                                    {/*    value={stateCode}*/}
                                    {/*    onSelect={(value) => handleChange('stateCode', value, 'nomineeAddress')}*/}
                                    {/*    isClearable={false}*/}
                                    {/*    isSearchable={true}*/}
                                    {/*    dropDownPlacement={"top"}*/}
                                    {/*    error={errors.nomineeAddress.stateCode !== ''}*/}
                                    {/*    helperMessage={errors.nomineeAddress.stateCode}*/}
                                    {/*    itemNotFoundText={"No options found'"}*/}
                                    {/*    disableHelperMessageHeight={true}*/}
                                    {/*    marginBottom={'sm'}*/}
                                    {/*    isMulti={false}*/}
                                    {/*    loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.states)}*/}
                                    {/*    defaultOptions={dropdownValues?.states}*/}
                                    {/*    noOptionsMessage={"No options found'"}*/}
                                    {/*    isRequired={true}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderDocumentUploader = () => {
        const {associateDocument, document} = props;
        if(isEmpty(document.filePath)){
            return (
                <div className='w-100 d-flex justify-content-center pb-2'>
                    <div className='border border-rounded d-flex align-items-center justify-content-center bg-white w-100'
                         style={{
                             height: 180,
                             fontSize: 14
                         }}>
                        <div>
                            Photo not available
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <DLDocUploader
                id={'resource-doc-uploader'}
                history={props.history}
                key={'doc-uploader'}
                label={''}
                boxLabel={'Upload PAN Card'}
                onChangeValue={(type,value) => {
                    associateDocument(type,value);
                }}
                isShowLabel={false}
                isDisableDelete={true}
                disableHelperMessageHeight={false}
                helperMessage={errors.document?.PAN_CARD}
                isRequired={true}
                filePath={document.filePath}
                uploadedFileName={document.fileName}
                documentId={document?.id}
                handleError={(error) => handleFileError(error, 62914560, 1)}
            />
        )
    };

    const renderBlocks = () => {

        if(isMobileView){
            return (
                <div>
                    {renderPersonalInformationMobile()}
                    <div className='mobile-border-bottom'/>
                    {renderNominationInformationMobile()}
                    <div className='mobile-border-bottom'/>
                    {renderAddressMobile()}
                </div>
            )
        }
        return (
            <div className={''}>
                {renderPersonalInformation()}
                {renderNominationInformation()}
                {renderAddress()}
            </div>
        )
    };

    const renderNote = () => {
        return (
            <div className={'mb-3'}>
                <DLText id={''}
                        text={'Note: PAN Card is mandatory for nominee who is older than 18 years.'}
                        fontSize={'sm'}
                        fontColor={'grayDark'}
                        fontWeight={'normal'}
                        marginBottom={'none'}
                />
            </div>
        )
    };

    const renderButtons = () => {
        const {onCancelClick, onSaveClick} = props;
        return (
            <div className='sticky-bottom-buttons'>
                <div style={{paddingTop: '7px'}}>
                    <DLButton
                        id={'create-lead-cancelBtn'}
                        history={history}
                        label={'Cancel'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => onCancelClick()}/>
                    {/*<DLButton*/}
                    {/*    id={'create-lead-saveBtn'}*/}
                    {/*    history={history}*/}
                    {/*    label={'Save'}*/}
                    {/*    buttonSize={"sm"}*/}
                    {/*    fontSize={"md"}*/}
                    {/*    sideMargin={"sm"}*/}
                    {/*    onClick={() => onSaveClick()}/>*/}
                </div>
            </div>
        );
    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors} = props;

        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className='my-3'>
                {responseErrors.slice(0, 3).map((errorMessage, index) => (
                    <div key={index} className='py-2 align-items-center d-flex message-block'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {errorMessage}
                        </div>
                    </div>
                ))}
                {totalErrors > 3 && (
                    <div className='py-2 align-items-center d-flex'>
                        {`...${totalErrors - 3} More`}
                    </div>
                )}
            </div>
        );
    };

    const renderLoader = () =>{
        return(
            <DLLoader isVisible={isLoaderVisible} type={'screen'}/>
        )
    }

    const renderUi = () => {
        if(isMobileView){
            return (
                <div>
                    {renderNote()}
                    <div>
                        <div>
                            {renderDocumentUploader()}
                        </div>
                        <div>
                            {renderBlocks()}
                        </div>
                    </div>
                    {/*{renderButtons()}*/}
                </div>
            );
        }
        return (
            <div>
                {renderNote()}
                <div className='row p-0 m-0'>
                    <div className='col-lg-4 ps-0'>
                        {renderDocumentUploader()}
                    </div>
                    <div className='col-lg-8'>
                        {renderBlocks()}
                    </div>
                </div>
                {/*{renderButtons()}*/}
            </div>
        );
    };

    return (
        <div style={{overflowY: 'auto', marginBottom : '10px'}}>
            {renderErrorUI()}
            {renderUi()}
            {renderLoader()}
        </div>
    );
};

MajorNomineeCreateOrUpdateView.propTypes = {};

export default MajorNomineeCreateOrUpdateView;