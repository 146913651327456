import React from "react";
import {isEmpty} from "../../../../../../utils/validations";
import {convertUTCToLocalTime, numberWithCommas} from "../../../../../../utils/helper";

import {
    switchSelectedSchemeCellConfig,
    switchSelectedSchemeCellConfigXSView,
    switchSelectedSchemeHeaderData
} from "../../../../FTModal";

import DLCheckbox from "../../../../../../core/components/DLCheckbox/DLCheckbox";
import DLTable from "../../../../../../core/components/DLTable/DLTable";
import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLDropDown from "../../../../../../core/components/DLDropdown/DLDropDown";
import DLInputTextField from "../../../../../../core/components/DLInputTextField/DLInputTextField";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLSearchDropdown from "../../../../../../core/components/DLSearchDropdown/DLSearchDropdown";
import {txnRequestTypes} from "../../../../../../clientRequest/ClientRequestConstants";
import ExistingPortfolio from "../existingPortfolio/ExistingPortfolio";

const SwitchTransactionView = (props) => {
    const {
        isMobileView,
        isMDView,
        isSMView,
        checkIsChecked,
        isCheckboxDisabled,
        isShowLoader,
        handleDelete,
        handleChange,
        handleAddToListButton,
        selectedScheme,
        handleCheckBox,
        UIState,
        products,
        searchKeyword,
        searchFundError, searchProduct, isShowInlineLoader, productModalError,
        targetSchemeName,
        changeTargetScheme,
        setUiState
    } = props;

    const getFormattedTableData = () => {
        let tableData = [];

        selectedScheme?.map((selectedTransaction, index) => {

            let transactionRecords = [];
            let transactionDetails = {};
            if (!isMobileView) {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderProductNameUI(selectedTransaction)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderFolioNumberUI(selectedTransaction?.folioNo)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderTargetSchemeUI(selectedTransaction)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderAmount(selectedTransaction, index)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderAction(selectedTransaction?.id)
                });
            } else {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderSelectedSchemeTableMobileView(selectedTransaction, index)
                })
            }
            transactionDetails.id = selectedTransaction?.clientRequestId;
            transactionDetails.data = transactionRecords;
            tableData.push(transactionDetails)
        });
        return tableData;
    };

    const renderCheckbox = (selectedPortfolio) => {

        return (
            <DLCheckbox
                id={'mark-as-reviewed-dayCheck'}
                fontSize={"sm"}
                disableHelperMessageHeight={true}
                onChangeValue={() => {
                    handleCheckBox(selectedPortfolio?.id)
                }}
                marginBottom={'none'}
                checkboxcolor={'black'}
                isChecked={checkIsChecked(selectedPortfolio?.id)}
                isDisabled={isCheckboxDisabled()}
            />
        )
    };

    const renderProductNameUI = (selectedTransaction) => {
        let productName = selectedTransaction?.scheme;
        let errors = selectedTransaction?.errors;

        const errorUI = isEmpty(errors) ? '' :
            errors.map((error, index) => {
                return <div className='pb-2 d-flex align-items-center' key={"error-" + index}>
                    <i className="fa-solid fa-circle-info error-icon pe-1"/>
                    <DLText id={''}
                            text={error?.errorMessage ? error?.errorMessage : error}
                            fontSize={"xs"}
                            fontColor={"danger"}
                            marginBottom={'none'}
                    />
                </div>
            });
        return (
            <div>
                <div className='pt-2 mt-2'>
                    <DLText id={''}
                            text={productName}
                            fontSize={"xs"}
                            marginBottom={'none'}
                    />
                </div>
                {!isEmpty(errorUI) && <div className='pt-2'>
                    {errorUI}
                </div>}
            </div>
        )
    };

    const renderFolioNumberUI = (folioNo) => {
        return (
            <div className='pt-2 mt-2'>
                <DLText id={'folio-id'}
                        text={folioNo}
                        fontSize={'xs'}
                        marginBottom={'none'}
                />
            </div>
        )
    };

    const getFormattedData = (data) => {
        return data?.map(item => ({
            value: item.id,
            label: item.description
        }));
    };

    const renderTargetSchemeUI = (selectedTransaction) => {
        if (targetSchemeName === '') {
            return (
                <div className='mt-2'>
                    <DLSearchDropdown
                        placeholder={"Search..."}
                        isShowInlineLoader={isShowInlineLoader}
                        data={getFormattedData(products)}
                        onChangeData={(e) => searchProduct(e, selectedTransaction?.productDetails?.AMCCode)}
                        value={searchKeyword}
                        onClickItem={(id) => changeTargetScheme(id)}
                        helperMessage={'Please select product'}
                        error={searchFundError !== ''}
                        disableHelperMessageHeight={true}
                        dropdownHeight={"calc(100vh - " + (productModalError ? "545px" : "490px") + ")"}
                    />
                </div>
            )
        } else {
            return (
                <div className="d-flex align-items-center justify-content-between mt-2">
                    <DLText
                        id={'units'}
                        text={targetSchemeName}
                        fontSize={'xs'}
                        marginBottom={'none'}
                    />
                    <DLText id={''}
                            text={'Change Scheme'}
                            isClickable={true}
                            fontSize={'xs'}
                            onClick={() => {
                                changeTargetScheme('')
                            }}
                    />
                </div>
            )
        }

    }

    const renderAmount = (selectedTransaction, index) => {
        return (
            <div className='pt-2'
                 id={'transferAmount-field-' + index}>
                <div className={''} style={{}}>
                    <DLInputTextField
                        id={'transfer-amount-input'}
                        onChangeValue={(value) => {
                            handleChange(value, selectedTransaction?.id, 'redeemAmount')
                        }}
                        disableHelperMessageHeight={false}
                        value={isEmpty(selectedTransaction?.redeemAmount) ? '' : numberWithCommas(selectedTransaction?.redeemAmount)}
                        marginBottom={"none"}
                        rows={1}
                        fontSize={'xs'}
                        isClearable={false}
                        inputType={"decimal"}
                    />
                </div>
            </div>
        );
    };

    const renderAction = (id) => {
        return (
            <div className={'pt-3 cursor'} onClick={() => handleDelete(id)}>
                <i className="fa-regular fa-lg fa-trash-can"/>
            </div>
        )
    };

    const renderSelectedSchemeTableMobileView = (selectedScheme, index) => {

        const renderMobileSourceScheme = () => {
            let productName = selectedScheme.scheme;

            return (
                <div className='d-flex justify-content-between'>
                    <div className='w-80 px-0 mx-0'>
                        <DLText
                            id={"source-scheme"}
                            text={"Source Scheme"}
                            fontColor={"grayDark"}
                            fontSize={"xs"}
                            marginBottom={"none"}
                            isInline={true}
                        />
                        <DLText id={'scheme-title'}
                                text={productName}
                                fontWeight={'normal'}
                                fontSize={"xs"}
                        />
                    </div>
                    <div className='h-100 px-2'>
                        <i className='fa-regular fa-xl fa-trash-can default-icon cursor'
                           onClick={() => {
                               handleDelete(selectedScheme?.id)
                           }}
                        />
                    </div>
                </div>
            );
        };

        const renderMobileFolioNumberUI = () => {
            return (
                <div className='py-2'>
                    <DLText
                        id={"folioNo"}
                        text={"Folio no. "}
                        fontColor={"grayDark"}
                        fontSize={"xs"}
                        marginBottom={"none"}
                        isInline={true}
                    />
                    <DLText
                        id={'folio-no'}
                        text={selectedScheme?.folioNo}
                        fontSize={"xs"}
                        marginBottom={"none"}
                        isInline={true}
                    />
                </div>
            )
        };

        const errorUI = () => {
            let errors = selectedScheme?.errors;
            return (
                <div>
                    {isEmpty(errors) ? '' :
                        errors.map((error, index) => {
                            return <div className='py-2 d-flex align-items-center' key={"error-" + index}>
                                <i className="fa-solid fa-circle-info error-icon pe-1"/>
                                <DLText id={''}
                                        text={error?.errorMessage}
                                        fontSize={"xs"}
                                        fontColor={"danger"}
                                        marginBottom={'none'}
                                />
                            </div>
                        })}
                </div>
            )
        };

        const renderMobileTargetScheme = () => {
            if (targetSchemeName === '') {
                return (
                    <div className='py-2'>
                        <DLSearchDropdown
                            placeholder={"Search..."}
                            isShowInlineLoader={isShowInlineLoader}
                            data={getFormattedData(products)}
                            onChangeData={(e) => searchProduct(e)}
                            value={searchKeyword}
                            onClickItem={(id) => changeTargetScheme(id)}
                            helperMessage={'Please select product'}
                            error={searchFundError !== ''}
                            disableHelperMessageHeight={true}
                            dropdownHeight={"calc(100vh - " + (productModalError ? "545px" : "490px") + ")"}
                        />
                    </div>
                )
            } else {
                return (
                    <div className="d-flex align-items-center my-2">
                        <div>
                            <DLText
                                id={'units'}
                                text={targetSchemeName}
                                fontSize={'xs'}
                                marginBottom={'none'}
                            />
                        </div>
                        <div className='ps-2'>
                            <DLText id={''}
                                    text={'Change Scheme'}
                                    isClickable={true}
                                    fontSize={'xs'}
                                    onClick={() => {
                                        changeTargetScheme('')
                                    }}
                            />
                        </div>
                    </div>
                )
            }

        }

        const renderMobileAmount = () => {
            return (
                <div className="" id={'transferAmount-field-' + index} style={{flex: 1}}>
                    <DLInputTextField
                        id="transfer-amount"
                        onChangeValue={(value) => handleChange(value, selectedScheme?.id, 'redeemAmount')}
                        disableHelperMessageHeight={true}
                        value={isEmpty(selectedScheme?.redeemAmount) ? '' : numberWithCommas(selectedScheme?.redeemAmount)}
                        isClearable={false}
                        marginBottom={"none"}
                        fontSize={"xs"}
                        inputType={"decimal"}
                        label={'Amount'}
                        labelFontSize={'xs'}
                    />
                </div>
            )
        }

        return (
            <div>
                {renderMobileSourceScheme()}
                {renderMobileFolioNumberUI()}
                {renderMobileTargetScheme()}
                {renderMobileAmount()}
                {errorUI()}
            </div>
        );
    };

    const renderSelectedPortfolio = () => {

        return (
            <div className={"bg-white border-rounded px-3 pt-2 " + (isEmpty(selectedScheme) ? "pb-3" : "pb-4")}>
                <div className='d-flex mb-3'>
                    <div>
                        <DLText
                            id={'switch-header'}
                            type={'normal'}
                            text={'Switch '}
                            fontSize={'lg'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                            isInline={true}
                        />
                    </div>
                </div>
                {isEmpty(selectedScheme) ?
                    <div className={'d-flex justify-content-center align-items-center border-rounded border'}
                         style={{
                             minHeight: 'calc(100dvh - 535px)',
                             maxHeight: 'calc(100dvh - 535px)'
                         }}>
                        <DLText
                            id={'empty-ui'}
                            text={'Add a fund from the existing portfolio for switch.'}
                        />
                    </div> :
                    <div className={''} style={{
                        minHeight: 'calc(100dvh - 535px)',
                        maxHeight: 'calc(100dvh - 535px)'
                    }}>
                        {renderSelectedSchemeTable()}
                        <div className={'d-flex pt-2'}
                             style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'start'}}
                        >
                        </div>
                    </div>
                }
            </div>
        )
    };

    const renderSelectedSchemeTable = () => {
        const tableData = getFormattedTableData();
        if (isMobileView) {
            return (
                <div className={'px-2'}>
                    <DLTable
                        id={'selected-list'}
                        headerData={[]}
                        tableData={tableData}
                        tableMaxHeight={'auto'}
                        tableMinHeight={'auto'}
                        isRowClickable={false}
                        isShowPagination={false}
                        isShowTotalCount={true}
                        cellConfig={switchSelectedSchemeCellConfigXSView}
                    />
                </div>
            )
        }
        return (
            <div>
                <DLTable
                    id={'selected-list'}
                    headerData={switchSelectedSchemeHeaderData}
                    tableData={tableData}
                    tableMaxHeight={'calc(100dvh - 540px)'}
                    tableMinHeight={'calc(100dvh - 540px)'}
                    isRowClickable={false}
                    isShowPagination={false}
                    isShowTotalCount={true}
                    cellConfig={switchSelectedSchemeCellConfig}
                    isShowCellSeprator={false}
                />
            </div>
        )
    };

    const renderBottomActionButtons = () => {
        const {submitTransactionDetails, openDiscardModal} = props;
        return (
            <div
                className={(isMobileView ? 'bg-white fixed-bottom' : '')}
                style={{
                    zIndex: 100,
                    boxShadow: isMobileView ? "0px -2px 5px rgba(0, 0, 0, 0.1)" : "none",
                }}
            >
                <div
                    className={isMobileView ? 'd-flex py-2 justify-content-center align-items-center' : 'sticky-bottom-buttons '}>
                    <div>
                        <DLButton
                            id={'save-and-close-button'}
                            type={"danger"}
                            styleType={"outlined"}
                            sideMargin={'none'}
                            onClick={() => {
                                openDiscardModal()
                            }}
                            label={"Discard"}
                        />
                    </div>
                    <div className={isMobileView ? "ps-3" : ""}>
                        <DLButton id={'next-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  onClick={() => {
                                      submitTransactionDetails()
                                  }}
                                  label={"Done"}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderDiscardModal = () => {
        const {closeAddDiscardModal, isOpenDiscardModal, redirectScreen} = props;
        return (
            <DLModal
                history={props.history}
                maxWidth={"xs"}
                onClose={closeAddDiscardModal}
                open={isOpenDiscardModal}
                id={'discard-modal'}>
                <DLModalTitle
                    id={'discard-title'}
                    onClose={() => closeAddDiscardModal()}
                    title={'Confirmation'}/>
                <DLModalContainer
                    id={'discard-container'}>
                    <div className='mb-2 pb-1 text-center'>
                        <DLText id={''}
                                text={"Are you sure you want to discard this transaction?"}
                                fontSize={"sm"}
                                fontWeight={"normal"}
                                marginBottom={"none"}
                        />
                        <DLText id={''}
                                text={"This action cannot be undone."}
                                fontSize={"sm"}
                                fontColor={"danger"}
                                marginBottom={"none"}
                        />
                    </div>
                </DLModalContainer>
                <DLModalActions id={'discard-action'}>
                    <div className='mb-4' style={{paddingTop: '7px'}}>
                        <DLButton
                            id={'discard-cancelBtn'}
                            history={props.history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => closeAddDiscardModal()}/>
                        <DLButton
                            id={'discard-btn'}
                            history={props.history}
                            styleType={"contained"}
                            type={"danger"}
                            label={'Discard'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => {
                                redirectScreen('DISCARD')
                            }}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        );
    };

    const renderHeader = () => {
        if (isMobileView) {
            return (
                <div className='w-100 bg-white'>
                    <div className='page-header d-flex justify-content-between py-3 ps-3 pe-2'>
                        <div className="d-flex align-items-top">
                            <i className='fa-solid fa-chevron-left cursor fa-xs pt-3 mt-0'
                               style={{width: 10}}
                               onClick={() => {
                                   props.history.goBack()
                               }}
                            />
                            <div>
                                <div className="ps-4">
                                    <div className='page-header-mobile d-flex align-items-top p-0' style={{lineHeight: '30px'}}>
                                        Switch Transaction: Edit
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className='d-flex justify-content-between w-100'>
                <div className='page-header px-0 py-3  m-0'>
                    Switch Transaction: Edit
                </div>
            </div>
        )
    };

    const renderUi = () => {
        return (
            <div>
                <div className="bg-light h-100">
                    <div className="bg-white mb-3 border-rounded border">
                        {renderExistingPortfolio()}
                    </div>
                    <div className={'bg-white border-rounded border'}>
                        {renderSelectedPortfolio()}
                    </div>
                </div>
                {renderBottomActionButtons()}
            </div>
        );
    };


    const renderExistingPortfolio = () => {
        return (
            <ExistingPortfolio
                {...props}
                subTextLabel={"Select the funds you would like to switch"}
                setSelectedSchemeData={(existingPortfolio) => handleAddToListButton(existingPortfolio)}
            />
        )
    }


    const renderMobileViewSelectedPortfolio = () => {
        return (
            <div className={"bg-white"}>
                <div className='border-bottom px-3 py-2'>
                    <DLText
                        id={'switch'}
                        type={'normal'}
                        text={'Switch '}
                        fontSize={'xs'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                    />
                </div>
                {isEmpty(selectedScheme) ?
                    <div className={'d-flex justify-content-center align-items-center px-4'}
                         style={{minHeight: "calc(100dvh - 490px)"}}>
                        <DLText
                            id={'empty-ui'}
                            text={'Add a fund from the existing portfolio for switch.'}
                            customWrapperStyle={{
                                textAlign: 'center'
                            }}
                        />
                    </div> :
                    <div className={''} style={
                        !isMobileView
                            ? {
                                minHeight: 'calc(100dvh - 490px)',
                                maxHeight: 'calc(100dvh - 490px)',
                            }
                            : {
                                minHeight: 'calc(100dvh - 450px)',
                                maxHeight: 'calc(100dvh - 450px)',
                            }
                    }>
                        {renderSelectedSchemeTable()}
                    </div>
                }
            </div>
        )
    };

    const renderMobileViewUi = () => {
        return (
            <div>
                <div>
                    <div className="bg-white mb-3">
                        {renderExistingPortfolio()}
                    </div>
                    <div className={'bg-white'}>
                        {renderMobileViewSelectedPortfolio()}
                    </div>
                </div>
                {renderBottomActionButtons()}
            </div>
        );
    };

    const renderErrorUI = () => {
        const {responseErrors} = props;

        if (isEmpty(responseErrors)) {
            return '';
        }

        return (
            <div id='page-level-errors'>
                {!isEmpty(responseErrors) && <div className='error-container'>
                    <div className='error-vertical-line'/>
                    <div className={'error-panel'}>
                        {responseErrors}
                    </div>
                </div>}
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>
        );
    };

    if (isEmpty(UIState)) {
        return renderLoader()
    }

    return (
        <div className={isMobileView ? 'bg-light' : 'bg-light px-4 h-100'}>
            {renderHeader()}
            <div className={isMobileView ? 'px-3 py-3 black-border-bottom black-border-top' : ''}>
                {renderErrorUI()}
            </div>
            <div
                className={isMobileView ? '' : 'd-flex flex-column bg-light'}>
                {isMobileView ? renderMobileViewUi() : renderUi()}
            </div>
            {renderDiscardModal()}
            {renderLoader()}
        </div>
    );
};

export default SwitchTransactionView;
