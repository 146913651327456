import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {isEmpty} from "../../../../../../utils/validations";
import {
    addPurchaseScheme,
    getTransactionDetails,
    selectProduct,
    serachPurchaseProducts,
    submitFundRequest
} from "../../../../FTAction";
import {getSipFrequency} from "../../../../../../clientRequest/ClientRequestsActions";
import {applyPortfolio} from "../../../../../../portfolio/portfolioAction";
import {APP_CONST} from "../../../../../../AppComponents/AppConstants";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import store from "../../../../../../store";
import {showToastMessage} from "../../../../../../AppComponents/AppActions";
import PurchaseSIPTransactionView from "./PurchaseSIPTransactionView";
import moment from "moment/moment";
import {convertUTCToLocalTime} from "../../../../../../utils/helper";

const PurchaseSIPTransaction = (props) => {

    const [purchaseTransactionState, setPurchaseTransactionState] = useState({
        funds: [],
        investmentAmount: "",
        totalAllocationAmount: "",
        totalAllocationPercentage: "",
        endDate: "",
        frequency: "",
        isForMaxPeriod: false,
        sipAmount: "",
        startDate: "",
        isMaxPeriodSelected: false,
        warningMessage: '',
        totalSelectedAmount: '',
        responseErrors: '',
        isOpenAddSchemeModal: false,
        isOpenDiscardModal: false,
        isShowLoader: false,
        UIState: '',
        isShowInlineLoader: false,
        searchFundError: '',
        searchKeyword: '',
        addedFund: '',
        productModalError: '',
        selectedProductId: '',
        isReinvested: false,
        addedProductAmount: '',
        addedFolioNumber: '',
        productMinValue: '',
        productMaxValue: '',
        productMultipleValue: '',
        addedProductAmountError: '',
        addedProductDateError: '',
        selectedScheme: [],
        errors: {
            startDateError: '',
            endDateError: '',
        },
        selectedProductDropDown: [],
        selectedProductDate: '',
        SIPDatesDropdown: [],
        txnRequestType: '',
        isDoneClicked: false,
        isOpenSelectFolioModal: false
    });

    useEffect(() => {
        let mode = props?.location?.state?.mode;

        if (mode === 'EDIT') {
            getTransactionData(mode)
        }
    }, []);

    useEffect(() => {
        if (purchaseTransactionState?.isOpenAddSchemeModal) {
            let limits = getProductMinAndMaxValue(purchaseTransactionState?.addedFolioNumber, purchaseTransactionState?.selectedScheme?.productLimits)

            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                ...limits,
            }))
        }
    }, [purchaseTransactionState?.addedFolioNumber]);

    const getSelectedPortfolioData = (portfolioId) => {
        let transactionRequestId = getTransactionRequestId();
        let payload = {
            portfolioId: isEmpty(portfolioId) ? null : portfolioId,
            transactionRequestId: parseInt(transactionRequestId)
        };

        getTransactionDetails(transactionRequestId).then(transactionsRes => {
            if (transactionsRes?.success) {
                applyPortfolio(payload, true)
                    .then(res => {
                        if (res?.success) {
                            setTransactionData(res?.data, transactionsRes, false, false);
                        } else {
                            setPurchaseTransactionState((prevState) => ({
                                ...prevState,
                                responseErrors: res?.__error,
                                UIState: APP_CONST.CONTENT_UNAVAILABLE,
                            }))
                        }
                    })
            } else {
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE
                }))
            }
        })

    };

    const getTransactionData = () => {
        const txnRequestId = getTransactionRequestId();
        let isAddSchemeModalOpen = props?.location?.state?.isAddSchemeModalOpen;
        getTransactionDetails(txnRequestId).then(transactionsRes => {
            if (transactionsRes?.success) {
                let requestData = transactionsRes.data;
                if (isEmpty(requestData?.funds)) {
                    setTransactionData(requestData, transactionsRes, isAddSchemeModalOpen, !isAddSchemeModalOpen);
                } else {
                    setTransactionData(requestData, transactionsRes, false, false);
                }
            }
        })
    };

    const openSelectFolioModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenSelectFolioModal: true
        }))
    };

    const closeSelectFolioModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenSelectFolioModal: false
        }))
    };

    const getTransactionRequestId = () => {
        return props.match?.params?.transactionId
    };

    const getClientRequestId = () => {
        return props.match?.params?.clientRequest
    };

    const setDropdown = (numbers) => {
        if (isEmpty(numbers)) {
            return
        }
        return numbers.map(num => ({
            label: num.toString(),
            value: num.toString()
        }));
    };

    const getAmountErrors = (amount, selectedFund) => {

        let allLimits = selectedFund?.details?.limits;

        if (isEmpty(allLimits)) {
            return false;
        }

        let selectedLimit = allLimits.find(product => product.TRXNType === selectedFund?.details?.txnType);
        let selectedMultiplier = (selectedLimit?.multiples < 100) ? 100 : selectedLimit?.multiples;

        if (!isEmpty(selectedLimit)) {
            if (amount > selectedLimit?.maxAmount || amount < selectedLimit?.minAmount || !isMultiple(amount, selectedMultiplier)) {
                return true;
            }

        }
        return false;
    };

    const setTransactionAndFrequencyData = (resFrequency, selectedPortfolio, headerData, isAddSchemeModalOpen, isOpenSelectFolioModal) => {

        let allFunds = [];
        let response = selectedPortfolio?.funds;

        if (response?.length === 0 || isEmpty(response)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                funds: [],
                investmentAmount: selectedPortfolio?.investmentAmount,
                totalAllocationAmount: selectedPortfolio?.totalAllocationAmount,
                totalAllocationPercentage: selectedPortfolio?.totalAllocationPercentage,
                isOpenAddSchemeModal: isAddSchemeModalOpen,
                isOpenSelectFolioModal: isOpenSelectFolioModal,
                UIState: APP_CONST.CONTENT_UNAVAILABLE,
                frequency: isEmpty(resFrequency) ? '' : getFrequency(headerData?.data?.txnDetails?.frequency, resFrequency?.data?.frequencies),
            }));
            return;
        }

        response?.map((selectedFund) => {
            let allocationAmount = getAllocation(selectedFund?.amount, selectedPortfolio?.investmentAmount);
            allFunds.push({
                productId: selectedFund?.productId,
                productName: selectedFund?.productName,
                amount: selectedFund?.amount,
                allocationAmount: selectedFund?.allocation,
                allocation: isEmpty(allocationAmount) ? '-' : (allocationAmount + '%'),
                errors: getErrors(selectedFund?.amount, selectedFund, selectedFund?.folioNumber),
                // errors: selectedFund?.errors,
                details: selectedFund?.details,
                folioNumber: selectedFund?.folioNumber,
                selectedDate: selectedFund?.frequencyDetails?.dayOfMonth,
                isDateSelected: !isEmpty(selectedFund?.sipDate),
                isSipAmountError: getAmountErrors(selectedFund?.amount, selectedFund),
                sipDate: setDropdown(selectedFund?.allowedDates),
                endDate: isEmpty(selectedFund?.endDate) ? '' : selectedFund?.endDate,
                startDate: isEmpty(selectedFund?.startDate) ? '' : selectedFund?.startDate,
                isMaxPeriodSelected: selectedFund?.isForMaxPeriod || false,
                reinvestFlag: setReinvest(selectedFund),
                UIState: APP_CONST.CONTENT_AVAILABLE
            })

        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: allFunds,
            totalAllocationAmount: selectedPortfolio?.totalAllocationAmount,
            totalAllocationPercentage: selectedPortfolio?.totalAllocationPercentage,
            investmentAmount: headerData?.data?.txnDetails?.sipAmount,
            txnRequestType: headerData?.data?.txnRequestType,
            isOpenAddSchemeModal: isAddSchemeModalOpen,
            isOpenSelectFolioModal: isOpenSelectFolioModal,
            UIState: APP_CONST.CONTENT_AVAILABLE,
            frequency: isEmpty(resFrequency) ? '' : getFrequency(headerData?.data?.txnDetails?.frequency, resFrequency?.data?.frequencies),

        }))
    };
    const setTransactionData = (selectedPortfolio, headerData, isAddSchemeModalOpen, isOpenSelectFolioModal) => {
        if (isEmpty(selectedPortfolio)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                isOpenAddSchemeModal: isAddSchemeModalOpen
            }));
        }


        getSipFrequency().then(resFrequency => {
            if (resFrequency?.success) {
                setTransactionAndFrequencyData(resFrequency, selectedPortfolio, headerData, isAddSchemeModalOpen, isOpenSelectFolioModal)
            } else {
                setTransactionAndFrequencyData('', selectedPortfolio, headerData, isAddSchemeModalOpen, isOpenSelectFolioModal)
            }
        })


    };
    const getFrequency = (type, allFrequency) => {

        const status = allFrequency.find(status => status.code === type);
        return status?.description;
    };

    const isMultiple = (num, multiple) => {

        if (multiple === 0) return false;

        let selectedMultiple = multiple;

        if (selectedMultiple < 100) {
            //as per disscusion with both sir to solve 0.01 multiplier issue
            selectedMultiple = 100
        }

        // Count decimal places in multiple to determine the scaling factor
        const decimalPlaces = selectedMultiple?.toString().split('.')[1]?.length || 0;
        const scaleFactor = Math.pow(10, decimalPlaces);

        // Scale num and selectedMultiple to integers
        const scaledNum = Math.round(num * scaleFactor);
        const scaledMultiple = Math.round(selectedMultiple * scaleFactor);
        // Check if scaled num is a selectedMultiple of scaled selectedMultiple
        return scaledNum % scaledMultiple === 0;
    };

    const getProductMinAndMaxValue = (addedFolioNumber, productLimits) => {

        if (isEmpty(productLimits)) {
            return null;
        }
        let purchaseTransactionObj = getPurchaseTxnLimitObj(addedFolioNumber, productLimits)
        if (!purchaseTransactionObj || purchaseTransactionObj.maxAmount <= 0) {
            return null;
        }

        let limitsObj = {
            productMinValue: isEmpty(purchaseTransactionObj.minAmount) ? '' : purchaseTransactionObj.minAmount,
            productMaxValue: isEmpty(purchaseTransactionObj.maxAmount) ? '' : purchaseTransactionObj.maxAmount,
            productMultipleValue: isEmpty(purchaseTransactionObj.multiples) ? '' : (purchaseTransactionObj.multiples < 100) ? 100 : purchaseTransactionObj.multiples,
        };
        return limitsObj;
    };

    const getPurchaseTxnLimitObj = (folioNumber, limitsArray) => {
        if (isEmpty(limitsArray)) {
            return;
        }
        let txn = [];

        if (isEmpty(folioNumber)) {
            txn = limitsArray.find((item) => {
                return item?.TRXNType === "FP";
            })
        } else {
            txn = limitsArray.find((item) => {
                return item?.TRXNType === "AP";
            })
        }
        return txn;
    };

    const replaceExistingMessage = (array, newValues) => {
        if (isEmpty(array)) {
            return [];
        }
        let updatedArray = [...array];
        for (let i = 0; i < updatedArray.length; i++) {
            if (updatedArray[i].includes("Minimum amount required is")) {
                updatedArray[i] = newValues?.minimumAmountMessage;
            } else if (updatedArray[i].includes("Maximum amount allowed is")) {
                updatedArray[i] = newValues?.maximumAmountMessage;
            } else if (updatedArray[i].includes("Amount should be in multiples of")) {
                updatedArray[i] = newValues?.multiplierMessage;
            }
        }
        return updatedArray;
    };

    const filterErrorMessages = (errors) => {
        let message = "SIP is not allowed for this scheme";
        let updatedArray = [];
        if (isEmpty(errors)) {
            return [];
        }
        let array = [...errors];
        updatedArray = array.filter(value => value === message);
        return updatedArray;
    };

    const checkIsSipAllowed = ((limitsArray) => {
        if (isEmpty(limitsArray)) {
            return false;
        }
        let isFPLimit = [];
        isFPLimit = limitsArray.find((item) => {
            return item?.TRXNType === "FP";
        });

        let isAPLimit = [];
        isAPLimit = limitsArray.find((item) => {
            return item?.TRXNType === "AP";
        });
        if (isEmpty(isAPLimit) && isEmpty(isFPLimit)) {
            return false;
        }
        return true;
    });

    const getErrors = (amount, selectedFund, folioNumber, newSipDate, dateType, selectedDate,isMax) => {
        let errors = [];
        let allLimits = selectedFund.details.limits;
        let sipAllowed = selectedFund.details.sipAllowed;
        let isFP = isEmpty(folioNumber);
        let sipDateForFund = isEmpty(newSipDate) ? selectedFund?.selectedDate : newSipDate;
        let selectedLimit = {};
        let datesAllowedDates = isEmpty(selectedFund?.sipDate) ? selectedFund?.allowedDates : selectedFund?.sipDate;
        let isSipAllowed = checkIsSipAllowed(allLimits);
        let dateErrors=setDateError(selectedFund, dateType, selectedDate,sipDateForFund,isMax);

        if (!sipAllowed || isEmpty(datesAllowedDates) || !isSipAllowed) {
            let emptyErrorArray = ["SIP is not allowed for this scheme"];
            return emptyErrorArray;
        }

        let uniqueErrors = errors;

        if (isEmpty(allLimits)) {
            return errors;
        }
        let emptyDate = 'Please select date';
        selectedLimit = getProductMinAndMaxValue(folioNumber, allLimits);
        if (isFP && isEmpty(selectedLimit)) {
            let emptyFPErrorArray = ["Fresh Purchase is not allowed for this scheme"];
            return emptyFPErrorArray;
        } else if (!isFP && isEmpty(selectedLimit)) {
            let emptyAPErrorArray = ["Additional Purchase is not allowed for this scheme"];
            return emptyAPErrorArray;
        } else if (isEmpty(amount)) {
            let emptyErrorArray = [];
            emptyErrorArray = ["Please enter amount"];
            if (isEmpty(selectedFund?.selectedDate) && isEmpty(newSipDate)) {
                emptyErrorArray = ["Please enter amount", emptyDate];
            }
            emptyErrorArray.push(...dateErrors);
            return emptyErrorArray;
        } else if (amount <= 0) {
            let zeroErrorArray = [];
            zeroErrorArray = ["Amount should not be zero"];
            if (isEmpty(selectedFund?.selectedDate) && isEmpty(newSipDate)) {
                zeroErrorArray = ["Please enter amount", emptyDate];
            }
            zeroErrorArray.push(...dateErrors);
            return zeroErrorArray;
        } else {

            //get limit details from array of all limits using txnType
            let selectedMultiplier = (selectedLimit?.productMultipleValue < 100) ? 100 : selectedLimit?.productMultipleValue;

            let minValue = selectedLimit?.productMinValue;
            let maxValue = selectedLimit?.productMaxValue;
            let multiplier = selectedMultiplier;

            let minimumAmountMessage = 'Minimum amount required is ' + minValue;
            let maximumAmountMessage = 'Maximum amount allowed is ' + maxValue;
            let multiplierMessage = 'Amount should be in multiples of ' + multiplier;
            // let emptyDate = 'Please select date';
            let dateNotAllowed = 'Selected Date is not allowed';

            let valuesArray = {
                minimumAmountMessage: minimumAmountMessage,
                maximumAmountMessage: maximumAmountMessage,
                multiplierMessage: multiplierMessage
            };

            errors = replaceExistingMessage(errors, valuesArray);

            if (!isEmpty(selectedLimit)) {

                if (isEmpty(sipDateForFund) && !isEmpty(selectedFund?.sipDate)) {
                    errors.push(emptyDate)
                } else {
                    errors = errors.filter(value => value !== emptyDate);
                }

                if (!isEmpty(sipDateForFund) && !isSelectedDateAllowed(selectedFund?.sipDate, sipDateForFund)) {
                    errors.push(dateNotAllowed)
                } else {
                    errors = errors.filter(value => value !== dateNotAllowed);
                }

                if (amount > selectedLimit?.productMaxValue) {
                    errors.push(maximumAmountMessage)
                } else {
                    errors = errors.filter(value => value !== maximumAmountMessage);
                }

                if (amount < selectedLimit?.productMinValue) {
                    errors.push(minimumAmountMessage)
                } else {
                    errors = errors.filter(value => value !== minimumAmountMessage);
                }

                if (isMultiple(amount, selectedMultiplier)) {
                    errors = errors.filter(value => value !== multiplierMessage);

                } else {
                    errors.push(multiplierMessage)
                }

                uniqueErrors = [...new Set(errors)];
            }

            let filteredErrors = filterErrorMessages(uniqueErrors);

            if (!isEmpty(filteredErrors)) {
                filteredErrors.push(...dateErrors);
                return filteredErrors;
            }

            uniqueErrors.push(...dateErrors);
            return uniqueErrors
        }
    };

    const setDateError = (selectedFund, dateType, selectedDate,sipdate,isMax) => {
        let dateError = [];
        let selectedDates = moment(selectedDate).format('YYYY-MM-DD');
        let selectedEndDate = moment(selectedFund.endDate).format('YYYY-MM-DD');
        let selectedStartDate = moment(selectedFund.startDate).format('YYYY-MM-DD');
        let selected = moment(selectedDate);
        let isMaxPeriodSelected=isEmpty(isMax) ? selectedFund.isMaxPeriodSelected : isMax;
        let dayOfMonth = selected.format('DD');
        let dateAfter7Days = moment().add(8, 'days').format('YYYY-MM-DD');
        if (dateType === 'startDate') {
            let selectStartDateError = 'Please select start date';
            if (isEmpty(selectedDate)) {
                dateError.push(selectStartDateError)
            }
            else {
                dateError = dateError.filter(value => value !== selectStartDateError);
            }

            if (isEmpty(selectedFund.endDate) && !selectedFund.isMaxPeriodSelected) {
                dateError.push('Please select end date')
            }

            let sevenDayError = 'SIP Start date should be 7 days higher than the current date';
            if (dateAfter7Days > selectedDates) {
                dateError.push(sevenDayError)
            }
            else{
                dateError = dateError.filter(value => value !== sevenDayError);
            }
            let sipStartDateMatchWithSIP = 'SIP start date should match with SIP date';
            if (!isEmpty(sipdate) && !isEmpty(selectedDate) && !(parseInt(dayOfMonth) === parseInt(sipdate))) {
                dateError.push(sipStartDateMatchWithSIP)
            }
            else if(!isEmpty(selectedFund.sipDate) && !isEmpty(selectedDate)){
                dateError = dateError.filter(value => value !== sipStartDateMatchWithSIP);
            }
            let selectedEND = moment(selectedFund.endDate);
            let ENDDayOfMonth = selectedEND.format('DD');
            let sipENDDateMatchWithSIP = 'SIP end date should match with SIP date';
            if (!isEmpty(sipdate) && !isEmpty(selectedFund.endDate) && !(parseInt(ENDDayOfMonth) === parseInt(sipdate)) && !selectedFund.isMaxPeriodSelected) {
                dateError.push(sipENDDateMatchWithSIP)
            }
            else if(!isEmpty(selectedFund.endDate)){
                dateError = dateError.filter(value => value !== sipENDDateMatchWithSIP);
            }
            let installmentError = 'Minimum 12 installments required';
            let minEndDate = moment(selectedDate).add(12, 'months').format('YYYY-MM-DD');
            if (!isEmpty(selectedFund.startDate) && !selectedFund.isMaxPeriodSelected) {
                if (minEndDate > selectedEndDate) {
                    dateError.push(installmentError)
                }
                else{
                    dateError = dateError.filter(value => value !== installmentError);
                }
            }
        }

        if (dateType === 'endDate') {
            let selectDateError = 'Please select end date';
            if (isEmpty(selectedDate) && !selectedFund.isMaxPeriodSelected) {
                dateError.push(selectDateError)
            }
            else {
                dateError = dateError.filter(value => value !== selectDateError);
            }

            if (isEmpty(selectedFund.startDate)) {
                dateError.push('Please select start date')
            }

            let installmentError = 'Minimum 12 installments required';
            let minEndDate = moment(selectedFund.startDate).add(12, 'months').format('YYYY-MM-DD');
            if (!isEmpty(selectedFund.startDate) && !selectedFund.isMaxPeriodSelected) {
                if (minEndDate > selectedDates) {
                    dateError.push(installmentError)
                }
                else{
                    dateError = dateError.filter(value => value !== installmentError);
                }
            }

            let sevenDayError = 'SIP Start date should be 7 days higher than the current date'
            if (!isEmpty(selectedFund.startDate) && dateAfter7Days > selectedStartDate) {
                dateError.push(sevenDayError)
            }
            else{
                dateError = dateError.filter(value => value !== sevenDayError);
            }

            let sipStartDateMatchWithSIP = 'SIP end date should match with SIP date';
            if (!isEmpty(sipdate) && !isEmpty(selectedDate) && !(parseInt(dayOfMonth) === parseInt(sipdate)) && !selectedFund.isMaxPeriodSelected) {
                dateError.push(sipStartDateMatchWithSIP)
            }
            else if(!isEmpty(selectedFund.sipDate) && !isEmpty(selectedDate)){
                dateError = dateError.filter(value => value !== sipStartDateMatchWithSIP);
            }
            let selected = moment(selectedFund.startDate);
            let startDayOfMonth = selected.format('DD');
            let sipENDDateMatchWithSIP = 'SIP start date should match with SIP date';
            if (!isEmpty(sipdate) && !isEmpty(selectedFund.startDate) && !(parseInt(startDayOfMonth) === parseInt(sipdate))) {
                dateError.push(sipENDDateMatchWithSIP)
            }
            else if(!isEmpty(selectedFund.startDate)){
                dateError = dateError.filter(value => value !== sipENDDateMatchWithSIP);
            }
        }

        if (dateType !== 'startDate' && dateType !== 'endDate') {
            if (isEmpty(selectedFund.endDate) && !isMaxPeriodSelected) {
                dateError.push('Please select end date')
            }
            if (isEmpty(selectedFund.startDate)) {
                dateError.push('Please select start date')
            }
            if(!isEmpty(selectedFund.startDate)){
                let selectedDatett = moment(selectedFund.startDate).format('YYYY-MM-DD');
                let selected = moment(selectedFund.startDate);
                let dayOfMonth = selected.format('DD');
                let sevenDayError = 'SIP Start date should be 7 days higher than the current date';
                if (dateAfter7Days > selectedDatett) {
                    dateError.push(sevenDayError)
                }
                else{
                    dateError = dateError.filter(value => value !== sevenDayError);
                }
                let sipStartDateMatchWithSIP = 'SIP start date should match with SIP date';
                if (!isEmpty(sipdate) && !isEmpty(selectedFund.startDate) && !(parseInt(dayOfMonth) === parseInt(sipdate))) {
                    dateError.push(sipStartDateMatchWithSIP)
                }
                else if(!isEmpty(selectedFund.startDate)){
                    dateError = dateError.filter(value => value !== sipStartDateMatchWithSIP);
                }

            }
            if(!isEmpty(selectedFund.endDate)){
                let selectedDatett = moment(selectedFund.endDate).format('YYYY-MM-DD');
                let selected = moment(selectedFund.endDate);
                let dayOfMonth = selected.format('DD');
                let installmentError = 'Minimum 12 installments required';
                if (!isEmpty(selectedFund.endDate) && !isEmpty(selectedFund.startDate)  && !isMaxPeriodSelected) {
                    let minEndDate = moment(selectedFund.startDate).add(12, 'months').format('YYYY-MM-DD');
                    if (minEndDate > selectedDatett) {
                        dateError.push(installmentError)
                    }
                    else{
                        dateError = dateError.filter(value => value !== installmentError);
                    }
                }

                let sipStartDateMatchWithSIP = 'SIP end date should match with SIP date';
                if (!isEmpty(sipdate) && !isEmpty(selectedFund.endDate) && !(parseInt(dayOfMonth) === parseInt(sipdate)) && !isMaxPeriodSelected) {
                    dateError.push(sipStartDateMatchWithSIP)
                }
                else if(!isEmpty(selectedFund.endDate)){
                    dateError = dateError.filter(value => value !== sipStartDateMatchWithSIP);
                }

            }

        }

        return dateError;
    };

    const isSelectedDateAllowed = (allowedDates, selectedDate) => {
        if (isEmpty(allowedDates)) {
            return true;
        }
        let flag = allowedDates.find((obj) => {
            return parseInt(obj.value) === parseInt(selectedDate)
        });

        return !isEmpty(flag);
    };

    const onchangeValue = (name, value) => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            [name]: value,
            [name + "Error"]: ''
        }))
    };

    const getAllocation = (amountSelected, investmentAmount) => {

        if (isEmpty(amountSelected) || isEmpty(investmentAmount)) {
            return ''
        }

        let totalPercentage = (amountSelected / investmentAmount) * 100;
        return isEmpty(totalPercentage) ? '' : totalPercentage.toFixed(2)
    };

    const changeAmount = (newAmount, productId) => {
        const {funds, investmentAmount} = purchaseTransactionState;
        let totalAllocationAmount = 0;
        let allFunds = funds.map(fund => {
            if (fund.productId === productId) {
                let allocationAmount = getAllocation(newAmount, investmentAmount);
                totalAllocationAmount += parseInt(newAmount);
                return {
                    ...fund,
                    amount: newAmount,
                    errors: getErrors(newAmount, fund, fund?.folioNumber),
                    isSipAmountError: getAmountErrors(newAmount, fund),
                    allocationAmount: allocationAmount,
                    allocation: isEmpty(allocationAmount) ? '-' : allocationAmount + '%'
                };
            } else {
                totalAllocationAmount += parseInt(fund?.amount)
            }
            return fund;
        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            warningMessage: (totalAllocationAmount !== parseInt(investmentAmount)) ? 'Investment Amount is not matching with the total amount of all the Schemes.' : '',
            funds: allFunds
        }))
    };

    const clearSelectedPortfolio = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: [],
            totalAllocationAmount: "",
            totalAllocationPercentage: "",
            warningMessage: '',
            totalSelectedAmount: '',
            responseErrors: '',
            isDoneClicked: false
        }))
    };

    const deleteFund = (productId) => {
        const {funds, investmentAmount, responseErrors} = purchaseTransactionState;
        let allFunds = funds.filter(product => product.productId !== productId);

        const totalAllocationAmount = allFunds.reduce((sum, fund) => {
            return sum + parseInt(fund?.amount || 0);
        }, 0);

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: allFunds,
            responseErrors: (!isEmpty(allFunds) ? responseErrors : ''),
            warningMessage: (!isEmpty(allFunds) && totalAllocationAmount !== parseInt(investmentAmount)) ? 'Investment Amount is not matching with the total amount of all the Schemes.' : '',
        }))
    };

    const closeAddSchemeModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenAddSchemeModal: false,
            productModalError: '',
            searchFundError: '',
            searchProduct: '',
            searchKeyword: '',
            products: [],
            addedFund: '',
            isReinvested: false,
            addedProductAmountError: '',
            addedProductDateError: '',
            productMinValue: '',
            productMaxValue: '',
            selectedProductDate: '',
            productMultipleValue: '',
            addedProductAmount: '',
            addedFolioNumber: '',
            selectedProductId: '',
            startDate: "",
            endDate: "",
            isMaxPeriodSelected: false
        }))
    };

    const openAddSchemeModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenAddSchemeModal: true
        }))
    };

    const redirectScreen = (action, data) => {
        let transactionRequestId = getTransactionRequestId();
        let clientRequestId = getClientRequestId();
        if (action === 'SELECT-FOLIO') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/financial-transaction/' + transactionRequestId + '/select-portfolio', {portfolio: data});
        }
        if (action === 'DISCARD') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/financial-transaction/' + transactionRequestId);
        }
    };

    const validateTransaction = (updateFundsWithErrors) => {
        let allErrors = [];
        updateFundsWithErrors?.map((selectedFund) => {
            let errors = isEmpty(selectedFund?.errors) ? [] : selectedFund?.errors;
            allErrors = [...allErrors, ...errors]
        });
        return allErrors;
    };

    let searchProductAPI = (keyword) => {
        let payload = {keyword: keyword, limitCount: 30};
        serachPurchaseProducts(payload).then(res => {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                searchFundError: '',
                products: res.data?.products,
                productModalError: '',
                isShowInlineLoader: false,
            }))
        });
    };

    const searchProduct = (keyword) => {

        if (isEmpty(keyword)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                searchKeyword: '',
                products: []
            }));
            return
        }

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            searchKeyword: keyword,
            isShowInlineLoader: true,
        }));

        searchProductAPI(keyword)

    };

    const setReinvest = (selectedFund) => {

        if (selectedFund?.reinvestFlag === true) {
            return true;
        } else if (selectedFund?.reinvestFlag === false) {
            return false;
        }
        return selectedFund?.details?.reinvestTag !== 'N';

    };

    const changeFolioNumber = (newFolioNumber, productId) => {
        const {funds} = purchaseTransactionState;
        let allFunds = funds.map(fund => {
            if (fund.productId === productId) {
                return {
                    ...fund,
                    errors: getErrors(fund?.amount, fund, newFolioNumber),
                    folioNumber: newFolioNumber
                };
            }
            return fund;
        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: allFunds
        }))
    };

    const setSipError = (newSipDate, selectedFund) => {

        let errors = selectedFund?.errors;
        let uniqueError = errors;
        let selectedDate = "Selected SIP date is wrong";
        if (!isEmpty(selectedFund)) {

            if (isEmpty(newSipDate)) {
                errors.push(selectedDate)
            } else {
                errors = errors?.filter(value => value !== selectedDate);
            }
            uniqueError = [...new Set(errors)];
        }
        return uniqueError
    };

    const changeSIPDate = (newSipDate, productId) => {
        const {funds} = purchaseTransactionState;
        let allFunds = funds.map(fund => {
            if (fund.productId === productId) {
                return {
                    ...fund,
                    selectedDate: newSipDate,
                    //errors: setSipError(newSipDate, fund),
                    errors: getErrors(fund.amount, fund, fund?.folioNumber, newSipDate),
                    isDateSelected: !isEmpty(newSipDate)
                };
            }
            return fund;
        });
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: allFunds
        }))
    };

    const changeSelectedSIPDate = (newSipDate) => {

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            selectedProductDate: newSipDate,
            addedProductDateError: '',
        }))
    };

    const validateChanges = (name, compState) => {
        const {startDate, endDate,selectedProductDate} = compState;
        let errorObj = {...purchaseTransactionState.errors};
        let todayDate = moment().format('YYYY-MM-DD');
        let dateAfter7Days = moment().add(8, 'days').format('YYYY-MM-DD');
        if (name === "startDate" || name === APP_CONST.FORM_SUBMISSION) {
            errorObj.startDateError='';
            errorObj.startDateError = isEmpty(startDate) ? "Please select start date" : "";
        }

        if ((name === APP_CONST.FORM_SUBMISSION && !isEmpty(startDate))|| name === "startDate") {
            let startDates = moment(startDate).format('YYYY-MM-DD');
            if(dateAfter7Days > startDates){
                errorObj.startDateError ="SIP Start date should be 7 days higher than the current date";
            }else if(!isEmpty(startDate) && !isEmpty(selectedProductDate)){
                let selectedEND = moment(startDate);
                let ENDDayOfMonth = selectedEND.format('DD');
                errorObj.startDateError=(parseInt(ENDDayOfMonth) !== parseInt(selectedProductDate)) ? 'SIP start date should match with SIP date':'';
            }else{
                errorObj.startDateError='';
            }
        }


        if ((name === "endDate" || name === APP_CONST.FORM_SUBMISSION) && !purchaseTransactionState.isMaxPeriodSelected) {
            errorObj.endDateError='';
            errorObj.endDateError = isEmpty(endDate) ? "Please select end date" : "";
        }

        if (name === APP_CONST.FORM_SUBMISSION && !isEmpty(endDate) && !isEmpty(startDate) && !purchaseTransactionState.isMaxPeriodSelected) {
            let minEndDate = moment(startDate).add(12, 'months').format('YYYY-MM-DD');
            let endDates = moment(endDate).format('YYYY-MM-DD');
            errorObj.endDateError = minEndDate > endDates ? "Minimum 12 installments required" : "";
        }

        if ((name === APP_CONST.FORM_SUBMISSION && !isEmpty(endDate) && !purchaseTransactionState.isMaxPeriodSelected)|| name === "endDate" && !purchaseTransactionState.isMaxPeriodSelected) {
            let minEndDate = moment(startDate).add(12, 'months').format('YYYY-MM-DD');
            let endDates = moment(endDate).format('YYYY-MM-DD');
            if(minEndDate > endDates){
                errorObj.endDateError = "Minimum 12 installments required";
            }else if(!isEmpty(endDate) && !isEmpty(selectedProductDate) && !purchaseTransactionState.isMaxPeriodSelected){
                let selectedEND = moment(endDate);
                let ENDDayOfMonth = selectedEND.format('DD');
                errorObj.endDateError=!(parseInt(ENDDayOfMonth) === parseInt(selectedProductDate)) ? 'SIP end date should match with SIP date':''
            }
        }

        return errorObj;
    };


    const handleSelecetdDate = (name, value) => {
        let updatedState = {...purchaseTransactionState};
        updatedState[name] = value;
        const validateObject = validateChanges(name, updatedState);

        setPurchaseTransactionState(prevStep => ({
            ...prevStep,
            ...updatedState,
            modalErrorMessage: '',
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));
    };

    const handleInitiate = () => {
        const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, purchaseTransactionState);
        const {startDate, endDate, isMaxPeriodSelected} = purchaseTransactionState


        if(isEmpty(validateObj?.startDateError)){
            setPurchaseTransactionState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    startDateError:''
                }
            }));
        }
        if(isEmpty(validateObj?.endDateError)){
            setPurchaseTransactionState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    endDateError:''
                }
            }));
        }
        if (!isEmpty(validateObj?.startDateError)) {
            setPurchaseTransactionState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return false;
        }
        if (!isEmpty(validateObj?.endDateError) && !purchaseTransactionState.isMaxPeriodSelected) {
            setPurchaseTransactionState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return false;
        }

        return true;


    };


    const handleDoneCLick = () => {
        const {funds} = purchaseTransactionState;
        let isError = false;
        let allFunds = funds.map(fund => {
            if (getAmountErrors(fund?.amount, fund)) {
                isError = true
            }
            return {
                ...fund,
                errors: getErrors(fund?.amount, fund, fund?.folioNumber),
                isSipAmountError: getAmountErrors(fund?.amount, fund),
                isDateSelected: !isEmpty(fund?.selectedDate)
            };
        });

        return allFunds
    };

    const submitTransactionDetails = () => {
        const {funds} = purchaseTransactionState;
        let transactionRequestId = getTransactionRequestId();
        let updateFundsWithErrors = handleDoneCLick();

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: isEmpty(updateFundsWithErrors) ? [] : updateFundsWithErrors,
            isDoneClicked: true
        }));

        let products = [];
        updateFundsWithErrors?.map((selectedFund) => {
            products.push({
                productId: selectedFund?.productId,
                allocation: parseInt(selectedFund?.allocationAmount),
                amount: parseInt(selectedFund?.amount),
                reinvestFlag: setReinvest(selectedFund),
                startDate: isEmpty(selectedFund?.startDate) ? '' : moment(selectedFund?.startDate).format('YYYY-MM-DD'),
                endDate: isEmpty(selectedFund?.endDate) ? '' : moment(selectedFund?.endDate).format('YYYY-MM-DD'),
                folioNumber: isEmpty(selectedFund?.folioNumber)? '': selectedFund?.folioNumber,
                isForMaxPeriod: isEmpty(selectedFund?.isMaxPeriodSelected) ? false :selectedFund?.isMaxPeriodSelected,
                frequencyDetails: {
                    dayOfMonth: parseInt(selectedFund?.selectedDate)
                },
            })
        });

        let transactionErrors = validateTransaction(updateFundsWithErrors);
        if (transactionErrors.length > 0) {
            store.dispatch(showToastMessage('warning', transactionErrors?.length === 1 ? 'Error exists, changes are not saved.' : 'Errors exist, changes are not saved.'))
            return;
        }

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true,
        }));


        let payload = {
            transactionRequestId: parseInt(transactionRequestId),
            products: products,
            txnRequestType: "SIP"
        };

        submitFundRequest(payload, true)
            .then(res => {
                if (res.success) {
                    setPurchaseTransactionState((prevState) => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                    redirectScreen('DISCARD')
                } else {
                    setPurchaseTransactionState((prevState) => ({
                        ...prevState,
                        responseErrors: typeof res?.__error === 'string' ? res?.__error : '',
                        funds: typeof res?.__error === 'object' ? handleFundsWithErrors(res?.__error, updateFundsWithErrors) : updateFundsWithErrors,
                        isShowLoader: false
                    }))

                }
            })
    };

    const handleFundsWithErrors = (errors, funds) => {
        if (isEmpty(errors) || isEmpty(funds)) {
            return funds;
        }
        let allFunds = funds.map(fund => {
            let productId = fund?.productId;
            let backEndError = errors.find((obj) => {
                return obj?.productId === productId
            });
            return {
                ...fund,
                errors: isEmpty(backEndError) ? [] : backEndError?.errors
            };
        });
        return allFunds;
    };

    const convertToCrores = ((amount) => {
        return (amount / 10000000);
    });


    const checkIfProductPresent = (funds, productId) => {
        if (isEmpty(funds)) {
            return false;
        }
        const scheme = funds.find((item) => {
            return item?.productId === productId;
        });
        return !isEmpty(scheme);
    };

    const addProduct = (id) => {
        const {products, funds} = purchaseTransactionState;
        const selectedProduct = products.find(item => id === item.id);
        let transactionRequestId = getTransactionRequestId();
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));
        if (isEmpty(selectedProduct)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedFund: '',
                selectedProductId: '',
                searchKeyword: '',
                selectedProductDate: '',
                isMaxPeriodSelected: false,
                startDate: "",
                endDate: "",
                products: [],
                productModalError: '',
                addedProductAmount: '',
                addedFolioNumber: '',
                productMinValue: '',
                productMaxValue: '',
                productMultipleValue: '',
                addedProductAmountError: '',
                isShowLoader: false,
                isReinvested: false,
                addedProductDateError:'',
                errors:{
                    ...prevState.errors,
                    startDateError:'',
                    endDateError:''
                }
            }));
            return
        }

        let productId = selectedProduct?.id;

        if (checkIfProductPresent(funds, productId)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedFund: selectedProduct?.description,
                selectedProductId: productId,
                productModalError: "Cannot add fund as it's already added for this transaction",
                isShowLoader: false
            }));
            return;
        }

        selectProduct(transactionRequestId, productId).then(res => {
            if (res?.success) {
                let limits = getProductMinAndMaxValue("", res?.data?.productLimits);
                let isSipAllowed = checkIsSipAllowed(res?.data?.productLimits);
                if (isEmpty(limits)) {
                    limits = {
                        productMinValue: '',
                        productMaxValue: '',
                        productMultipleValue: '',
                    }
                }
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    ...limits,
                    selectedScheme: res.data,
                    addedFund: selectedProduct?.description,
                    selectedProductDropDown: setDropdown(res?.data?.allowedDates),
                    selectedProductId: productId,
                    productModalError: !isSipAllowed ? "SIP is not allowed for this scheme" : '',
                    searchKeyword: '',
                    isShowLoader: false,
                    isReinvested: isReinvestChecked(res.data)
                }))
            } else {
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    addedFund: selectedProduct?.description,
                    selectedProductId: productId,
                    productModalError: res?.__error,
                    isShowLoader: false
                }))
            }
        });
    };

    const reinvestProduct = (value) => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isReinvested: value,
        }))
    };

    const validate = () => {
        const {
            selectedProductDate
        } = purchaseTransactionState;

        if (isEmpty(selectedProductDate)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductDateError: 'Please select date'
            }));
            return false
        }

        return true
    };

    const validateAmount = () => {
        const {
            addedProductAmount,
            productMinValue,
            productMaxValue,
            productMultipleValue,
            selectedProductId,
            addedFolioNumber,
        } = purchaseTransactionState;

        if (isEmpty(selectedProductId)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                searchFundError: 'Please select Product'
            }));
            return false
        }

        if (isEmpty(addedProductAmount)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Please enter amount'
            }));
            return false
        }

        if (addedProductAmount <= 0) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Amount should not be 0'
            }));
            return false
        }

        if (isEmpty(productMaxValue)) {
            if (isEmpty(addedFolioNumber)) {
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    addedProductAmountError: 'Fresh purchase is not allowed for this scheme'
                }));
                return false
            } else {
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    addedProductAmountError: 'Additional purchase is not allowed for this scheme'
                }));
                return false
            }
        }

        if (addedProductAmount > productMaxValue) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Maximum amount allowed is ' + productMaxValue
            }));
            return false
        }

        if (addedProductAmount < productMinValue) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Minimum amount required is ' + productMinValue
            }));
            return false
        }

        if (!isMultiple(addedProductAmount, productMultipleValue)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Amount should be in multiples of ' + productMultipleValue
            }));
            return false
        }

        return true
    };

    const addScheme = () => {
        const {
            addedProductAmount,
            addedFolioNumber,
            selectedProductId,
            funds,
            investmentAmount,
            isReinvested,
            selectedProductDropDown,
            selectedProductDate
        } = purchaseTransactionState;
        let transactionRequestId = getTransactionRequestId();
        let CheckDate = handleInitiate();
        let isValidAmount = validateAmount();
        let isDateSelected = validate();
        if (!isValidAmount || !isDateSelected || !CheckDate) {
            return
        }

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {

            txnRequestId: parseInt(transactionRequestId),
            productId: selectedProductId,
            amount: parseInt(addedProductAmount),
            folioNumber: addedFolioNumber,
            sipDate: selectedProductDate,
            reinvestFlag: isReinvested
        };

        addPurchaseScheme(payload).then(res => {
            if (res?.success) {
                let resp = res?.data[0];
                let allocationAmount = getAllocation(resp?.amount, investmentAmount);
                let respFunds = [{
                    productId: resp?.productId,
                    productName: resp?.productName,
                    amount: resp?.amount,
                    allocationAmount: resp?.allocation,
                    allocation: isEmpty(allocationAmount) ? '-' : (allocationAmount + '%'),
                    errors: getErrors(resp?.amount, resp, addedFolioNumber, selectedProductDate),
                    details: resp?.details,
                    startDate: purchaseTransactionState.startDate,
                    endDate: purchaseTransactionState.endDate,
                    isMaxPeriodSelected: purchaseTransactionState.isMaxPeriodSelected,
                    sipDate: selectedProductDropDown,
                    selectedDate: selectedProductDate,
                    isDateSelected: !isEmpty(selectedProductDate),
                    folioNumber: isEmpty(addedFolioNumber) ? "" : addedFolioNumber,
                    reinvestFlag: resp?.reinvestFlag
                }];
                const mappedProducts = funds.concat(respFunds);
                const totalAllocationAmount = mappedProducts.reduce((sum, fund) => {
                    return sum + parseInt(fund?.amount || 0);
                }, 0);

                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    funds: mappedProducts,
                    addedFolioNumber: '',
                    addedProductAmount: '',
                    startDate: "",
                    endDate: "",
                    errors: {
                        startDateError: '',
                        endDateError: '',
                    },
                    isForMaxPeriod: false,
                    addedFund: '',
                    selectedProductId: '',
                    searchKeyword: '',
                    isOpenAddSchemeModal: false,
                    warningMessage: (parseInt(investmentAmount) !== totalAllocationAmount) ? 'Investment Amount is not matching with the total amount of all the Schemes.' : '',
                    isShowLoader: false,
                }))
            } else {
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    productModalError: res?.__error,
                    isShowLoader: false
                }))
            }
        });
    };

    const closeAddDiscardModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: false
        }))
    };

    const isReinvestChecked = (selectedScheme) => {
        let flag = false;
        if (isEmpty(selectedScheme)) {
            return;
        }
        if (selectedScheme.reinvestTag === 'X' || selectedScheme.reinvestTag === 'Y' || selectedScheme.reinvestTag === 'Z') {
            flag = true
        }
        return flag
    };

    const isCheckBoxDisabled = (selectedScheme) => {
        let flag = false;
        if (isEmpty(selectedScheme)) {
            return;
        }
        if (selectedScheme?.reinvestTag === 'N' || selectedScheme?.reinvestTag === 'Y' || selectedScheme?.reinvestTag === 'Z') {
            flag = true
        }
        return flag
    };

    const openDiscardModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: true
        }))
    };

    const handleDate = (startDate) => {
        return convertUTCToLocalTime(startDate, 'DD-MM-YYYY');
    };

    if (isEmpty(purchaseTransactionState?.UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    const handleChangeDate = (type, productId, selelectedDate) => {


        const {funds} = purchaseTransactionState;
        let allFunds = funds.map(fund => {
            if (fund.productId === productId) {
                return {
                    ...fund,
                    [type]: isEmpty(selelectedDate) ? '' : selelectedDate,
                    errors: getErrors(fund.amount, fund, fund.folioNumber, fund.selectedDate, type, selelectedDate),
                };
            }
            return fund;
        });
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: allFunds
        }))
    };

    const handleCheckBox = (name, productId) => {
        if (name === 'fund') {
            const {funds} = purchaseTransactionState;
            let allFunds = funds.map(fund => {
                if (fund.productId === productId) {
                    return {
                        ...fund,
                        endDate: !fund?.isMaxPeriodSelected ? '' : fund?.endDate,
                        isMaxPeriodSelected: !fund?.isMaxPeriodSelected,
                        errors: getErrors(fund.amount, fund, fund.folioNumber, fund.selectedDate, name,'',!fund?.isMaxPeriodSelected),
                    };
                }
                return fund;
            });
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                funds: allFunds
            }))
        }
        if (name === 'selectedFund') {
            setPurchaseTransactionState(prevState => ({
                ...prevState,
                isMaxPeriodSelected: !prevState.isMaxPeriodSelected,
                endDate: null,
                errors: {
                    ...prevState.errors,
                    endDateError: ''
                }
            }))
        }
    };

    return (
        <PurchaseSIPTransactionView
            {...props}
            {...purchaseTransactionState}
            onchangeValue={onchangeValue}
            changeAmount={changeAmount}
            clearSelectedPortfolio={clearSelectedPortfolio}
            deleteFund={deleteFund}
            closeAddSchemeModal={closeAddSchemeModal}
            openAddSchemeModal={openAddSchemeModal}
            redirectScreen={redirectScreen}
            validateTransaction={validateTransaction}
            submitTransactionDetails={submitTransactionDetails}
            searchProduct={searchProduct}
            addProduct={addProduct}
            reinvestProduct={reinvestProduct}
            addScheme={addScheme}
            closeAddDiscardModal={closeAddDiscardModal}
            openDiscardModal={openDiscardModal}
            isCheckBoxDisabled={isCheckBoxDisabled}
            isReinvestChecked={isReinvestChecked}
            changeFolioNumber={changeFolioNumber}
            changeSIPDate={changeSIPDate}
            convertToCrores={convertToCrores}
            changeSelectedSIPDate={changeSelectedSIPDate}
            openSelectFolioModal={openSelectFolioModal}
            closeSelectFolioModal={closeSelectFolioModal}
            getSelectedPortfolioData={getSelectedPortfolioData}
            filterErrorMessages={filterErrorMessages}
            handleDate={handleDate}
            handleChangeDate={handleChangeDate}
            handleCheckBox={handleCheckBox}
            handleSelecetdDate={handleSelecetdDate}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    isMDView: state.appState.deviceInfo.isMDView,
});


export default connect(mapStateToProps, {})(PurchaseSIPTransaction);