import {connect} from "react-redux";
import {useEffect, useState} from "react";

import store from "../../../../../../store";
import {APP_CONST} from "../../../../../../AppComponents/AppConstants";
import {isEmpty} from "../../../../../../utils/validations";
import {getTransactionDetails, selectProduct, submitFundRequest} from "../../../../FTAction";
import {showToastMessage} from "../../../../../../AppComponents/AppActions";

import PurchaseSwpTransactionView from "./PurchaseSwpTransactionView";
import moment from "moment/moment";
import {getSWPFrequency} from "../../../../../../clientRequest/ClientRequestsActions";


const PurchaseSwpTransaction = (props) => {
    const [purchaseTransactionState, setPurchaseTransactionState] = useState({
        selectedScheme: [],
        UIState: '',
        isDoneClicked: false,
        isOpenDiscardModal: false,
        isShowLoader: false,
        isShowInlineLoader: false,
        responseErrors: '',
        requestData: '',
        selectedFrequency:''
    });

    useEffect(() => {
        let mode = props?.location?.state?.mode;
        getTransactionData(mode)
    }, []);

    const getClientRequestId = () => {
        return parseInt(props.match?.params?.clientRequest);
    };

    const getTransactionRequestId = () => {
        return parseInt( props.match?.params?.transactionId)
    };

    const redirectScreen = () => {
        let transactionRequestId = getTransactionRequestId();
        let clientRequestId = getClientRequestId();

        props.history.replace(
            '/client-requests/' + clientRequestId + '/financial-transaction/' + transactionRequestId);
    };

    const getTransactionData = () => {
        const txnRequestId = getTransactionRequestId();
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true,
        }));
        getTransactionDetails(txnRequestId).then(transactionsRes => {
            if (transactionsRes?.success) {
                let requestData = transactionsRes.data;
                getSWPFrequency().then(resFrequency => {
                    let frequency = []
                    if (resFrequency?.success) {
                        frequency = resFrequency?.data?.frequencies
                    }
                    setTransactionData(requestData, frequency)
                })
            }else{
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const getFrequency = (type, allFrequency) => {
        const status = allFrequency.find(status => status.code === type);
        return status?.description;
    };

    const setTransactionData = (selectedPortfolio,frequencies) => {
        let allFunds = [];
        let response = selectedPortfolio?.funds;
        let frequency = getFrequency(selectedPortfolio?.txnDetails?.frequency, frequencies)

        if (response?.length === 0 || isEmpty(response)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                requestData:selectedPortfolio,
                selectedScheme: [],
                isShowLoader: false,
                selectedFrequency: isEmpty(frequency) ? '-' : frequency,
                UIState: APP_CONST.CONTENT_UNAVAILABLE
            }));
            return;
        }

        response?.map((selectedFund) => {
            let dateDropDownValues = setDropdown(selectedFund?.allowedDates);
            allFunds.push({
                fundInvestmentId: selectedFund?.submissionDetails?.fundInvestmentId,
                details: selectedFund?.details,
                sourceScheme: isEmpty(selectedFund?.productName) ? '' : selectedFund?.productName,
                productId: selectedFund?.productId,
                sourceSchemeAmount: selectedFund?.submissionDetails?.valuation,
                startDate: selectedFund?.startDate,
                type: 'AMOUNT',
                endDate: selectedFund?.endDate,
                isForMaxPeriod: selectedFund?.isForMaxPeriod,
                swpAllowedDates: dateDropDownValues,
                selectedSwpDate: selectedFund?.frequencyDetails?.dayOfMonth,
                redeemAmount: selectedFund?.value,
                errors: [],
                folioNo: selectedFund?.folioNumber,
                nav: selectedFund?.submissionDetails?.nav,
                navAsOfDate: selectedFund?.submissionDetails?.navAsOfDate,
                valuation: selectedFund?.submissionDetails?.valuation,
                UIState: APP_CONST.CONTENT_AVAILABLE
            })
        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            requestData: selectedPortfolio,
            selectedScheme: allFunds,
            isShowLoader: false,

            selectedFrequency: isEmpty(frequency) ? '-' : frequency,
            UIState: APP_CONST.CONTENT_AVAILABLE
        }))
    };


    const handleDoneCLick = () => {
        const {selectedScheme} = purchaseTransactionState;
        let allFunds = selectedScheme.map(scheme => {
            return {
                ...scheme,
                errors: getErrors(scheme, scheme?.redeemAmount)
            };
        });

        return allFunds;
    };

    const handleFundsWithSwpErrors = (errors, funds) => {
        if (isEmpty(errors) || isEmpty(funds)) {
            return funds;
        }
        let allFunds = funds.map(scheme => {
            let productId = scheme?.productId;
            let backEndError = errors.find((obj) => {
                return obj?.productId === productId
            });

            return {
                ...scheme,
                errors: isEmpty(backEndError) ? [] : backEndError?.errors
            };
        });
        return allFunds;
    };

    const submitTransactionDetails = () => {

        let transactionRequestId = getTransactionRequestId();
        let updateFundsWithErrors = handleDoneCLick();

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            selectedScheme: isEmpty(updateFundsWithErrors) ? [] : updateFundsWithErrors,
            isDoneClicked: true
        }));
        let products = [];


        updateFundsWithErrors?.map((selectedFund) => {
            products.push({
                productId: isEmpty(selectedFund?.productId)? '' : selectedFund?.productId,
                type: "AMOUNT",
                value: isEmpty(selectedFund?.redeemAmount)? '' : selectedFund?.redeemAmount,
                targetFundId: isEmpty(selectedFund?.targetSchemeId)? '' : selectedFund?.targetSchemeId,
                reinvestFlag: false,
                startDate: isEmpty(selectedFund?.startDate)? '' : moment(selectedFund?.startDate).format("YYYY-MM-DD"),
                endDate: isEmpty(selectedFund?.endDate)? '' :moment(selectedFund?.endDate).format("YYYY-MM-DD"),
                folioNumber: isEmpty(selectedFund?.folioNo)? '' : selectedFund?.folioNo,
                isForMaxPeriod: isEmpty(selectedFund?.isForMaxPeriod)? '' : selectedFund?.isForMaxPeriod,
                frequencyDetails: {
                    dayOfMonth: isEmpty(selectedFund?.selectedSwpDate)? '' : Number(selectedFund?.selectedSwpDate)
                },
                submissionDetails:{
                    fundInvestmentId: isEmpty(selectedFund?.fundInvestmentId) ? '' : selectedFund?.fundInvestmentId,
                    fundId: isEmpty(selectedFund?.productId)? '' : selectedFund?.productId,
                    folioNumber: isEmpty(selectedFund?.folioNo)? '' : selectedFund?.folioNo,
                    availableUnits: isEmpty(selectedFund?.units)? '' : selectedFund?.units,
                    nav: isEmpty(selectedFund?.nav)? '' : selectedFund?.nav,
                    navAsOfDate: isEmpty(selectedFund?.navAsOfDate)? '' : selectedFund?.navAsOfDate,
                    valuation: isEmpty(selectedFund?.valuation)? '' : selectedFund?.valuation
                }
            })
        });

        let transactionErrors = validateTransaction(updateFundsWithErrors);

        if (transactionErrors.length > 0) {
            store.dispatch(showToastMessage('warning', transactionErrors?.length === 1 ? 'Error exists, changes are not saved.' : 'Errors exist, changes are not saved.'))
            return;
        }

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            transactionRequestId: transactionRequestId,
            products: products,
            txnRequestType: "SWP"
        };

        submitFundRequest(payload, true)
            .then(res => {
                if (res.success) {
                    setPurchaseTransactionState((prevState) => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                    redirectScreen('DISCARD')
                } else {
                    setPurchaseTransactionState((prevState) => ({
                        ...prevState,
                        responseErrors: typeof res?.__error === 'string'? res?.__error : '',
                        selectedScheme: typeof res?.__error === 'object'? handleFundsWithSwpErrors(res?.__error, updateFundsWithErrors) : updateFundsWithErrors,
                        isShowLoader: false
                    }))
                }
            })
    };

    const validateTransaction = (updateSchemeWithErrors) => {
        let allErrors = [];
        if (isEmpty(updateSchemeWithErrors)) {
            return allErrors;
        }
        updateSchemeWithErrors?.map((selectedFund) => {
            let errors = isEmpty(selectedFund?.errors) ? [] : selectedFund?.errors;
            allErrors = [...allErrors, ...errors]
        });
        return allErrors;
    };

    const setDateError = (selectedFund, dateType, selectedDate, swpdate, isMax) => {

        let dateError = [];
        let selected = moment(selectedDate);
        let isForMaxPeriod=isEmpty(isMax) ? selectedFund.isForMaxPeriod : isMax;
        let dateAfter7Days = moment().add(8, 'days').format('YYYY-MM-DD');

        let selectedDates = moment(selectedDate).format('YYYY-MM-DD');
        let selectedEndDate = moment(selectedFund.endDate).format('YYYY-MM-DD');
        let selectedStartDate = moment(selectedFund.startDate).format('YYYY-MM-DD');


        let dayOfMonth = selected.format('DD');

        if (dateType === 'startDate') {
            let selectStartDateError = 'Please select start date';
            if (isEmpty(selectedDate)) {
                dateError.push(selectStartDateError)
            }
            else {
                dateError = dateError.filter(value => value !== selectStartDateError);
            }

            if (isEmpty(selectedFund.endDate) && !selectedFund.isForMaxPeriod) {
                dateError.push('Please select end date')
            }

            let sevenDayError = 'SWP Start date should be 7 days higher than the current date';
            if (dateAfter7Days > selectedDates) {
                dateError.push(sevenDayError)
            }
            else{
                dateError = dateError.filter(value => value !== sevenDayError);
            }


            let swpStartDateMatchWithSWP = 'SWP start date should match with SWP date';
            if (!isEmpty(swpdate) && !isEmpty(selectedDate) && !(parseInt(dayOfMonth) === parseInt(swpdate))) {
                dateError.push(swpStartDateMatchWithSWP)
            }
            else if(!isEmpty(selectedFund.stpDate) && !isEmpty(selectedDate)){
                dateError = dateError.filter(value => value !== swpStartDateMatchWithSWP);
            }

            let selectedEND = moment(selectedFund.endDate);
            let ENDDayOfMonth = selectedEND.format('DD');
            let stpENDDateMatchWithSWP = 'SWP end date should match with SWP date';
            if (!isEmpty(swpdate) && !isEmpty(selectedFund.endDate) && !(parseInt(ENDDayOfMonth) === parseInt(swpdate)) && !selectedFund.isMaxPeriodSelected) {
                dateError.push(stpENDDateMatchWithSWP)
            }
            else if(!isEmpty(selectedFund.endDate)){
                dateError = dateError.filter(value => value !== stpENDDateMatchWithSWP);
            }


            let installmentError = 'Minimum 6 installments required';
            let minEndDate = moment(selectedDate).add(6, 'months').format('YYYY-MM-DD');

            if (!isEmpty(selectedFund.startDate) && !selectedFund.isForMaxPeriod) {
                if (minEndDate > selectedEndDate) {
                    dateError.push(installmentError)
                }
                else{
                    dateError = dateError.filter(value => value !== installmentError);
                }
            }
        }

        if (dateType === 'endDate') {
            let selectDateError = 'Please select end date';
            if (isEmpty(selectedDate) && !selectedFund.isForMaxPeriod) {
                dateError.push(selectDateError)
            }
            else {
                dateError = dateError.filter(value => value !== selectDateError);
            }

            if (isEmpty(selectedFund.startDate)) {
                dateError.push('Please select start date')
            }

            let installmentError = 'Minimum 6 installments required';
            let minEndDate = moment(selectedFund.startDate).add(6, 'months').format('YYYY-MM-DD');
            if (!isEmpty(selectedFund.startDate) && !selectedFund.isForMaxPeriod) {
                if (minEndDate > selectedDates) {
                    dateError.push(installmentError)
                }
                else{
                    dateError = dateError.filter(value => value !== installmentError);
                }
            }

            let sevenDayError = 'SWP Start date should be 7 days higher than the current date';
            if (!isEmpty(selectedFund.startDate) && dateAfter7Days > selectedStartDate) {
                dateError.push(sevenDayError)
            }
            else{
                dateError = dateError.filter(value => value !== sevenDayError);
            }

            let swpStartDateMatchWithSWP = 'SWP end date should match with SWP date';
            if (!isEmpty(swpdate) && !isEmpty(selectedDate) && !(parseInt(dayOfMonth) === parseInt(swpdate)) && !selectedFund.isForMaxPeriod) {
                dateError.push(swpStartDateMatchWithSWP)
            }
            else if(!isEmpty(selectedFund.swpdate) && !isEmpty(selectedDate)){
                dateError = dateError.filter(value => value !== swpStartDateMatchWithSWP);
            }


            let selected = moment(selectedFund.startDate);
            let startDayOfMonth = selected.format('DD');
            let stpENDDateMatchWithSWP = 'SWP start date should match with SWP date';
            if (!isEmpty(swpdate) && !isEmpty(selectedFund.startDate) && !(parseInt(startDayOfMonth) === parseInt(swpdate))) {
                dateError.push(stpENDDateMatchWithSWP)
            }
            else if(!isEmpty(selectedFund.startDate)){
                dateError = dateError.filter(value => value !== stpENDDateMatchWithSWP);
            }
        }

        if (dateType !== 'startDate' && dateType !== 'endDate') {
            if (isEmpty(selectedFund.startDate)) {
                dateError.push('Please select start date')
            }
            if (isEmpty(selectedFund.endDate) && !isForMaxPeriod) {
                dateError.push('Please select end date')
            }

            if(!isEmpty(selectedFund.startDate)){
                let selectedDatett = moment(selectedFund.startDate).format('YYYY-MM-DD');
                let selected = moment(selectedFund.startDate);
                let dayOfMonth = selected.format('DD');
                let sevenDayError = 'SWP Start date should be 7 days higher than the current date';
                if (dateAfter7Days > selectedDatett) {
                    dateError.push(sevenDayError)
                }
                else{
                    dateError = dateError.filter(value => value !== sevenDayError);
                }
                let swpStartDateMatchWithSWP = 'SWP start date should match with SWP date';
                if (!isEmpty(swpdate) && !isEmpty(selectedFund.startDate) && !(parseInt(dayOfMonth) === parseInt(swpdate))) {
                    dateError.push(swpStartDateMatchWithSWP)
                }
                else if(!isEmpty(selectedFund.startDate)){
                    dateError = dateError.filter(value => value !== swpStartDateMatchWithSWP);
                }

            }
            if(!isEmpty(selectedFund.endDate)){
                let selectedDatett = moment(selectedFund.endDate).format('YYYY-MM-DD');
                let selected = moment(selectedFund.endDate);
                let dayOfMonth = selected.format('DD');
                let installmentError = 'Minimum 6 installments required';
                if (!isEmpty(selectedFund.endDate) && !isEmpty(selectedFund.startDate)  && !isForMaxPeriod) {
                    let minEndDate = moment(selectedFund.startDate).add(6, 'months').format('YYYY-MM-DD');
                    if (minEndDate > selectedDatett) {
                        dateError.push(installmentError)
                    }
                    else{
                        dateError = dateError.filter(value => value !== installmentError);
                    }
                }

                let swpStartDateMatchWithSWP = 'SWP end date should match with SWP date';
                if (!isEmpty(swpdate) && !isEmpty(selectedFund.endDate) && !(parseInt(dayOfMonth) === parseInt(swpdate)) && !isForMaxPeriod) {
                    dateError.push(swpStartDateMatchWithSWP)
                }
                else if(!isEmpty(selectedFund.endDate)){
                    dateError = dateError.filter(value => value !== swpStartDateMatchWithSWP);
                }

            }
        }
        return dateError;
    };

    const filterErrorMessages = (errors) => {
        let message = "SWP is not allowed for this scheme";
        let updatedArray = [];
        if (isEmpty(errors)) {
            return [];
        }
        let array = [...errors];
        updatedArray = array.filter(value => value === message);
        return updatedArray;
    };

    const getErrors = (selectedScheme ,selectedAmount, dateType, selectedDate) => {
        if (isEmpty(selectedScheme)) {
            redirectScreen('DISCARD')
        }

        let swpNotAllowed = isEmpty(selectedScheme?.swpAllowedDates) || (selectedScheme?.details?.swpAllowedCode !== 'Y');
        if(swpNotAllowed){
            return ["SWP is not allowed for this scheme"]
        }
        let dateErrors= setDateError(selectedScheme, dateType, selectedDate, selectedScheme?.selectedSwpDate);

        let errors = [...dateErrors];

        if (isEmpty(selectedScheme?.selectedSwpDate)) {
            errors.push('Please select date');
        }
        if (isEmpty(selectedScheme?.type)) {
            errors.push("Please select type");
        }
        if (isEmpty(selectedAmount) && isEmpty(selectedScheme?.redeemAmount)) {
            errors.push('Please enter amount.');
        }else if (selectedAmount <= 0) {
            errors.push('Minimum 1 required.');
        }else if (selectedAmount > selectedScheme.sourceSchemeAmount) {
            errors.push('Maximum ' + selectedScheme.sourceSchemeAmount + ' amount allowed.');
        }
        let filteredErrors = filterErrorMessages(errors);

        if (!isEmpty(filteredErrors)) {
            return filteredErrors;
        }
        return errors
    };

    const setDropdown = (numbers) => {
        if (isEmpty(numbers)) {
            return []
        }
        return numbers.map(num => ({
            label: num.toString(),
            value: num.toString()
        }));
    };

    const openDiscardModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: true
        }))
    };

    const closeAddDiscardModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: false
        }))
    };

    const setUiState = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            UIState: APP_CONST.CONTENT_AVAILABLE
        }))
    };

    const addSchemeToList = async (portfolio) => {

        let transactionRequestId = getTransactionRequestId();
        // Use Promise.all to wait for all async operations
        const updatedSelectedScheme = await Promise.all(
            portfolio.map(async (scheme) => {
                const res = await selectProduct(transactionRequestId, scheme?.productDetails?.id);
                // let dateDropDownValues = setDropdown(isMonthlySwp ? res?.data?.swpAllowedDates : res?.data?.allowedDays);
                let dateDropDownValues = setDropdown(res?.data?.swpAllowedDates);
                return {
                    fundInvestmentId: scheme?.id,
                    details: scheme?.productDetails,
                    sourceScheme: scheme?.productDetails?.description || '',
                    productId: scheme?.productDetails?.id || '',
                    sourceSchemeAmount: scheme?.valuation,
                    startDate: '',
                    type: 'AMOUNT',
                    endDate: '',
                    isForMaxPeriod: false,
                    swpAllowedDates: dateDropDownValues,
                    selectedSwpDate: '',
                    redeemAmount: '',
                    errors: [],
                    folioNo: scheme?.folioNo,
                    nav: scheme?.nav,
                    units: scheme?.units,
                    navAsOfDate: scheme?.date,
                    valuation: scheme?.valuation,
                };
            })
        );

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            selectedScheme: updatedSelectedScheme,
            isDoneClicked: false,
            responseErrors:''
        }));
    };

    const handleDelete = (id) => {
        const {selectedScheme} = purchaseTransactionState;
        const updatedSelectedScheme = selectedScheme.filter(scheme => id !== scheme.id);

        setPurchaseTransactionState(prevState => ({
            ...prevState,
            selectedScheme: updatedSelectedScheme,
            responseErrors:''
        }));
    };

    const handleChangeDate = (type, selectedDate, index) => {

        const {selectedScheme} = purchaseTransactionState;
        let updatedSchemeDetails = selectedScheme.map((scheme, id) => {
            if (id === index) {
                return {
                    ...scheme,
                    [type]: isEmpty(selectedDate) ? '' : selectedDate,
                    errors: getErrors(scheme, scheme?.redeemAmount, type, selectedDate),
                };
            }
            return scheme;
        });
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            selectedScheme: updatedSchemeDetails,
            responseErrors: ''
        }))
    };

    const handleMaximumPeriodCheckBox = (index) => {
        const {selectedScheme} = purchaseTransactionState;
        let allFunds = selectedScheme.map((scheme, id) => {
            if (id === index) {
                let updatedScheme = {
                    ...scheme,
                    endDate: scheme?.isForMaxPeriod ? '' : scheme?.endDate,
                    isForMaxPeriod: !scheme?.isForMaxPeriod
                };
                return {
                    ...scheme,
                    ...updatedScheme,
                    errors: getErrors(updatedScheme, scheme.redeemAmount),
                };
            }
            return scheme;
        });
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            selectedScheme: allFunds,
            responseErrors:''
        }))
    };

    const changeSWPDate = (date, index) => {
        const {selectedScheme} = purchaseTransactionState;
        let updatedSchemeDetails = selectedScheme.map((scheme, id) => {
            if (id === index) {
                let updatedScheme = {
                    ...scheme,
                    selectedSwpDate: date
                };
                return {
                    ...scheme,
                    errors: getErrors(updatedScheme, updatedScheme?.redeemAmount),
                    selectedSwpDate: date
                };
            }
            return scheme;
        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            selectedScheme: updatedSchemeDetails,
            responseErrors:''
        }))
    };

    const changeTransferAmount = (amount, index) => {
        const {selectedScheme} = purchaseTransactionState;
        let selectedNumber = ((typeof amount === 'string') ? Number(amount.replace(/,/g, "")) : amount)
        let updatedSchemeDetails = selectedScheme.map((scheme, id) => {
            if (id === index) {
                return {
                    ...scheme,
                    redeemAmount: selectedNumber,
                    errors: getErrors(scheme, selectedNumber),
                };
            }
            return scheme;
        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            selectedScheme: updatedSchemeDetails,
            responseErrors:''
        }))
    };

    return (
        <PurchaseSwpTransactionView
            {...props}
            {...purchaseTransactionState}
            changeSWPDate={changeSWPDate}
            submitTransactionDetails={submitTransactionDetails}
            openDiscardModal={openDiscardModal}
            closeAddDiscardModal={closeAddDiscardModal}
            handleDelete={handleDelete}
            addSchemeToList={addSchemeToList}
            redirectScreen={redirectScreen}
            handleChangeDate={handleChangeDate}
            changeTransferAmount={changeTransferAmount}
            handleMaximumPeriodCheckBox={handleMaximumPeriodCheckBox}
            setUiState={setUiState}
            filterErrorMessages={filterErrorMessages}
        />
    )
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(PurchaseSwpTransaction);
