import React from "react";
import moment from "moment";

import appTheme from "../../../../../../assets/appTheme";

import {
    redemptionSelectedSchemeCellConfigXSView,
    stpSelectedSchemeCellConfig,
    stpSelectedSchemeHeaderData, stpSelectedSchemeHeaderDataForMonthly,
    stpSelectedSchemeHeaderDataForWeekly
} from "../../../../FTModal";

import {convertAmountInLakhAndCrore} from "../../../../../../utils/helper";
import {isEmpty} from "../../../../../../utils/validations";

import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLCheckbox from "../../../../../../core/components/DLCheckbox/DLCheckbox";
import DLTable from "../../../../../../core/components/DLTable/DLTable";
import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLDropDown from "../../../../../../core/components/DLDropdown/DLDropDown";
import DLInputTextField from "../../../../../../core/components/DLInputTextField/DLInputTextField";
import DLDatePicker from "../../../../../../core/components/DLDatePicker/DLDatePicker";
import DLSearchDropdownTest from "../../../../../../core/components/DLSearchDropdownTest/DLSearchDropdownTest";
import ExistingPortfolio from "../existingPortfolio/ExistingPortfolio";

const PurchaseSTPTransactionView = (props) => {
    const {
        isMobileView,
        handleDelete,
        handleChange,
        selectedScheme,
        products,
        searchKeyword,
        addProduct,
        handleChangeDate,
        changeTransferAmount,
        changeSTPDate,
        handleMaximumPeriodCheckBox,
        responseErrors,
        frequency
    } = props;

    const renderErrorUI = () => {
        const {responseErrors} = props;

        if (isEmpty(responseErrors)) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={isMobileView ? 'mt-1' : 'mt-3'}>
                {!isEmpty(responseErrors) && <div className={isMobileView ? 'px-3 py-3 error-container' : 'pb-1 error-container'}>
                    <div className='error-vertical-line'/>
                    <div className={'error-panel'}>
                        {!isEmpty(responseErrors) ? responseErrors : ''}
                    </div>
                </div>}
            </div>
        );
    };

    const getFormattedTableData = () => {
        let tableData = [];
        selectedScheme?.map((selectedTransaction, index) => {
            let transactionRecords = [];
            let transactionDetails = {};
            if (!isMobileView) {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderProductNameUI(selectedTransaction)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderSearchBar(selectedTransaction, index)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderDate(selectedTransaction, index)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderSTPDatesDropdown(selectedTransaction, index)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderTransferAmount(selectedTransaction, index)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderAction(selectedTransaction?.id)
                });
            } else {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderSelectedSchemeTableMobileView(selectedTransaction, index)
                })
            }
            transactionDetails.id = selectedTransaction?.clientRequestId;
            transactionDetails.data = transactionRecords;
            tableData.push(transactionDetails)
        });
        return tableData;
    };

    const getMinimumDate = (selectedTransaction) => {
        if (isEmpty(selectedTransaction?.startDate)) {
            return moment().add().format('YYYY-MM-DD')
        }
        if (frequency === "Weekly") {
           return moment(selectedTransaction?.startDate).add(6, 'weeks').format('YYYY-MM-DD')
        } else {
            return moment(selectedTransaction?.startDate).add(6, 'months').format('YYYY-MM-DD')
        }
    };


    const renderDate = (selectedTransaction, index) => {
        return (
            <div className='h-100 py-2 d-flex flex-column '
                 style={{verticalAlign: 'Top'}}>
                <div className='d-flex align-items-center'>
                    <DLDatePicker id={'start-date'}
                                  value={selectedTransaction?.startDate}
                                  minimumDate={moment()}
                                  isClearable={true}
                                  isRequired={true}
                                  onChangeValue={(value) => {
                                      handleChangeDate('startDate', value, index);
                                  }}
                                  minWidth={'150px'}
                                  disableHelperMessageHeight={true}
                    />
                    <div className='px-1'>
                        <DLText id={''}
                                text={'&'}
                        />
                    </div>
                    <DLDatePicker id={'start-date'}
                                  value={selectedTransaction?.endDate}
                                  minimumDate={
                                      getMinimumDate(selectedTransaction)
                                  }
                                  maximumDate={moment().add(30, 'years').format('YYYY-MM-DD')}
                                  isClearable={true}
                                  isRequired={true}
                                  onChangeValue={(value) => {
                                      handleChangeDate('endDate', value, index);
                                  }}
                                  minWidth={'150px'}
                                  disableHelperMessageHeight={true}
                                  isDisabled={selectedTransaction?.isMaxPeriodSelected}
                    />
                </div>
                <div className='d-flex align-items-center  justify-content-end pt-1'>
                    <DLCheckbox
                        id={'mark-as-reviewed-dayCheck'}
                        fontSize={"sm"}
                        disableHelperMessageHeight={true}
                        onChangeValue={() => {
                           handleMaximumPeriodCheckBox(index)
                        }}
                        labelPlacement={'end'}
                        marginBottom={'none'}
                        checkboxcolor={'black'}
                        labelColor={appTheme.appColor.black}
                        isChecked={selectedTransaction?.isMaxPeriodSelected}
                    />
                    <div>
                        <DLText id={''}
                                text={'Maximum Period'}
                                marginBottom={'none'}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderErrorMessages = (errors) => {
        const {isDoneClicked, filterErrorMessages} = props;
        if (isEmpty(errors)) {
            return '';
        }
        errors = isDoneClicked ? errors : filterErrorMessages(errors);

        return (
            errors?.map((error, index) => {
                return <div className='pb-2 d-flex align-items-top' key={"error-" + index}>
                    <i className="fa-solid fa-circle-info error-icon pe-1"
                       style={{paddingTop:'2px'}}
                    />
                    <DLText id={''}
                            text={error}
                            fontSize={"xs"}
                            fontColor={"danger"}
                            marginBottom={'none'}
                    />
                </div>
            })
        )
    };

    const renderProductNameUI = (selectedTransaction) => {
        let sourceScheme = selectedTransaction?.sourceScheme;
        let errors = selectedTransaction?.errors;
        const errorUI = renderErrorMessages(errors);

        return (
            <div>
                <div className='pt-2'>
                    <DLText id={''}
                            text={sourceScheme}
                            fontSize={"xs"}
                            marginBottom={'none'}
                    />
                    <DLText id={''}
                            text={selectedTransaction?.folioNo}
                            fontSize={"xs"}
                            fontColor={"grayDark"}
                            marginBottom={'none'}
                    />
                </div>
                {!isEmpty(errorUI) && <div className='pt-2'>
                    {errorUI}
                </div>}
            </div>
        )
    };

    const renderTransferAmount = (selectedTransaction, index) => {
        let amount = selectedTransaction?.transferAmount;
        return (
            <div className={isMobileView? 'py-2' : 'h-100 py-2'}
                 style={{verticalAlign: 'Top'}}>
                <DLInputTextField id={'folio-number'}
                                  onChangeValue={(e) => {
                                      changeTransferAmount(e, index)
                                  }}
                                  isClearable={false}
                                  value={amount}
                                  placeholder={''}
                                  isSearchable={true}
                                  marginBottom={"none"}
                                  disableHelperMessageHeight={true}
                                  inputType={"decimal"}
                />
            </div>
        )
    };

    const renderSTPDatesDropdown = (selectedTransaction, index) => {
        return (
            <div className='h-100 py-2'
                 style={{verticalAlign: 'Top'}}>
                <DLDropDown
                    id={"status-filter"}
                    label={''}
                    labelFontColor={'black'}
                    fontSize={"xs"}
                    labelPlacement={'top'}
                    value={isEmpty(selectedTransaction?.selectedStpDate)?'':selectedTransaction?.selectedStpDate}
                    options={selectedTransaction?.stpDates}
                    onSelect={(e) => {
                        changeSTPDate(e, index)
                    }}
                    isDisabled={isEmpty(selectedTransaction?.stpDates)}
                    marginBottom={"none"}
                    disableHelperMessageHeight={true}
                    minWidth={'120px'}
                />
            </div>
        )
    };

    const renderAction = (id) => {
        return (
            <div className={'pt-3 cursor'} onClick={() => handleDelete(id)}>
                <i className="fa-regular fa-lg fa-trash-can"/>
            </div>
        )
    };

    const renderSelectedSchemeTableMobileView = (selectedScheme, index) => {

        const renderMobileSourceScheme = () => {
            let productName = selectedScheme.scheme;

            return (
                <div className='d-flex justify-content-between'>
                    <div className='w-75 px-0 mx-0'>
                        <DLText id={'scheme-title'}
                                text={productName}
                                fontWeight={'normal'}
                                fontSize={"xs"}
                        />
                    </div>
                    <div className='h-100 px-2'>
                        <i className='fa-regular fa-xl fa-trash-can default-icon cursor'
                           onClick={() => {
                               handleDelete(selectedScheme?.id)
                           }}
                        />
                    </div>
                </div>
            );
        };

        const renderMobileFolioNumberUI = () => {
            return (
                <div className='py-2'>
                    <DLText
                        id={"folioNo"}
                        text={"Folio no. "}
                        fontColor={"grayDark"}
                        fontSize={"xs"}
                        marginBottom={"none"}
                        isInline={true}
                    />
                    <DLText
                        id={'folio-no'}
                        text={selectedScheme?.folioNo}
                        fontSize={"xs"}
                        marginBottom={"none"}
                        isInline={true}
                    />
                </div>
            )
        };

        const errorUI = () => {
            let errors = selectedScheme?.errors;
            return (
                <div>
                    {isEmpty(errors) ? '' :
                        errors.map((error, index) => {
                            return <div className='py-2 d-flex align-items-center' key={"error-" + index}>
                                <i className="fa-solid fa-circle-info error-icon pe-1"/>
                                <DLText id={''}
                                        text={error}
                                        fontSize={"xs"}
                                        fontColor={"danger"}
                                        marginBottom={'none'}
                                />
                            </div>
                        })}
                </div>
            )
        };

        const renderMobileRedeemUnitsAmount = () => {
            return (
                <div className={'d-flex justify-content-between flex-wrap gap-2 gap-wrap-3'}>
                    <div className="pe-2" id={'transferUnits-field-' + index} style={{flex: 1}}>
                        <DLDropDown
                            id={"type"}
                            label={'Type'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'xs'}
                            value={selectedScheme?.type}
                            minWidth={"100%"}
                            options={[
                                {label: 'Amount', value: 'AMOUNT'},
                                {label: 'Units', value: 'UNIT'},
                                {label: 'All', value: 'ALL'},
                            ]}
                            onSelect={(value) => handleChange(value, selectedScheme?.id, 'type')}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                            placeholder={'Select'}
                            error={selectedScheme?.typeError}
                        />
                    </div>
                    <div className="pe-2" id={'transferUnits-field-' + index} style={{flex: 1}}>
                        {selectedScheme?.type === 'UNIT' && selectedScheme?.type !== 'ALL' ? (
                            <div className={'pe-1'}>
                                <DLInputTextField
                                    id="transfer-unit"
                                    onChangeValue={(value) => handleChange(value, selectedScheme?.id, 'transferUnits')}
                                    disableHelperMessageHeight={true}
                                    value={
                                        isEmpty(selectedScheme?.redeemUnits)
                                            ? selectedScheme?.redeemUnits
                                            : convertAmountInLakhAndCrore(selectedScheme?.redeemUnits)
                                    }
                                    error={selectedScheme?.redeemUnitsError}
                                    isClearable={false}
                                    marginBottom={"none"}
                                    fontSize={"xs"}
                                    inputType={"number"}
                                    label={'Transfer Units'}
                                    labelFontSize={'xs'}
                                />
                            </div>
                        ) : (
                            <div className="pe-2 pt-1">
                                <DLText
                                    id={"units"}
                                    text={"Transfer Units"}
                                    fontColor={"grayDark"}
                                    fontSize={"xs"}
                                    marginBottom={"none"}
                                />
                                <div className={'pt-2'}>
                                    <DLText
                                        id={"amount"}
                                        text={convertAmountInLakhAndCrore(selectedScheme?.redeemUnits) || 0}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="" id={'transferAmount-field-' + index} style={{flex: 1}}>
                        {selectedScheme?.type === 'AMOUNT' && selectedScheme?.type !== 'ALL' ? (
                            <div className={'ps-2'}>
                                <DLInputTextField
                                    id="transfer-amount"
                                    onChangeValue={(value) => handleChange(value, selectedScheme?.id, 'transferAmount')}
                                    disableHelperMessageHeight={true}
                                    value={convertAmountInLakhAndCrore(selectedScheme?.redeemAmount)}
                                    error={selectedScheme?.redeemAmountError}
                                    isClearable={false}
                                    marginBottom={"none"}
                                    fontSize={"xs"}
                                    inputType={"number"}
                                    label={'Transfer Amount'}
                                    labelFontSize={'xs'}
                                />
                            </div>
                        ) : (
                            <div className={" ps-2 pt-1"}>
                                <DLText
                                    id={"units"}
                                    text={"Transfer Amount"}
                                    fontColor={"grayDark"}
                                    fontSize={"xs"}
                                    marginBottom={"none"}
                                />
                                <div className={'pt-2'}>
                                    <DLText
                                        id="units"
                                        text={convertAmountInLakhAndCrore(selectedScheme?.redeemAmount) || 0}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        };

        return (
            <div>
                {renderMobileSourceScheme()}
                {renderMobileFolioNumberUI()}
                {renderMobileRedeemUnitsAmount()}
                {errorUI()}
            </div>
        );
    };

    const renderFrequencyDetails = () => {
        return (
            <div className='d-flex pb-3'>
                <div className='pe-4'>
                    <DLText
                        id={'frequency-title'}
                        type={'normal'}
                        text={`Frequency: `}
                        fontSize={'md'}
                        fontWeight={'normal'}
                        fontColor={'black'}
                        marginBottom={"none"}
                        isInline={true}
                    />
                    <DLText
                        id={'frequency-value'}
                        type={'normal'}
                        text={frequency}
                        fontSize={'md'}
                        fontWeight={'bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                        isInline={true}
                    />
                </div>
            </div>
        )
    };

    const renderSelectedPortfolio = () => {
        return (
            <div className={"bg-white border-rounded p-3"}>
                {renderFrequencyDetails()}
                {isEmpty(selectedScheme) ?
                    <div className={'d-flex justify-content-center align-items-center border-rounded border'}
                         style={{
                             minHeight: 'calc(100dvh - 598px)',
                             maxHeight: 'calc(100dvh - 598px)'
                         }}>
                        <DLText
                            id={'empty-ui'}
                            text={'Add a scheme from the existing portfolio for transfer.'}
                        />
                    </div> :
                    <div className={''}>
                        {renderSelectedSchemeTable()}
                    </div>
                }
            </div>
        )
    };

    const getFormattedData = (data) => {
        return data?.map(item => ({
            value: item.id,
            label: item.description
        }));
    };

    const renderSearchBar = (selectedScheme, index) => {
        const {searchProduct, isShowInlineLoader, productModalError} = props;
        if(selectedScheme?.isTargetSchemeSelected){
            return(
                <div className='pt-2'>
                    <DLText id={''}
                            text={selectedScheme?.targetScheme}
                            fontSize={"xs"}
                            marginBottom={'none'}
                    />
                    <DLText id={''}
                            text={'Change Scheme'}
                            isClickable={true}
                            fontSize={"xs"}
                            onClick={() => {
                                addProduct('', index)
                            }}
                    />
                </div>
            )
        }
        return (
            <div className={"pt-2"}>
                <div className={"w-100"}>
                    <DLSearchDropdownTest
                        placeholder={"Search..."}
                        isShowInlineLoader={isShowInlineLoader}
                        data={getFormattedData(products)}
                        onChangeData={(e) => searchProduct(e, selectedScheme?.AMCCode)}
                        value={searchKeyword}
                        onClickItem={(id) => addProduct(id, index)}
                        disableHelperMessageHeight={true}
                        dropdownHeight={"calc(100vh - " + (productModalError ? "545px" : "490px") + ")"}
                    />
                </div>
            </div>
        )
    };

    const renderSelectedSchemeTable = () => {
        const tableData = getFormattedTableData();
        if (isMobileView) {
            return (
                <div>
                    <DLTable
                        id={'selected-list'}
                        headerData={[]}
                        tableData={tableData}
                        tableMaxHeight={'auto'}
                        tableMinHeight={'auto'}
                        isRowClickable={false}
                        isShowPagination={false}
                        isShowTotalCount={true}
                        cellConfig={redemptionSelectedSchemeCellConfigXSView}
                    />
                </div>
            )
        }
        return (
            <div>
                <DLTable
                    id={'selected-list'}
                    headerData={frequency === "Monthly"? stpSelectedSchemeHeaderDataForMonthly : stpSelectedSchemeHeaderDataForWeekly}
                    tableData={tableData}
                    isDynamicHeight={true}
                    isRowClickable={false}
                    isShowPagination={false}
                    isShowTotalCount={true}
                    cellConfig={stpSelectedSchemeCellConfig}
                    isShowCellSeprator={false}
                />
            </div>
        )
    };

    const renderBottomActionButtons = () => {
        const {submitTransactionDetails, openDiscardModal} = props;
        return (
            <div
                className={(isMobileView ? 'bg-white fixed-bottom' : '')}
                style={{
                    boxShadow: isMobileView ? "0px -2px 5px rgba(0, 0, 0, 0.1)" : "none",
                }}
            >
                <div
                    className={isMobileView ? 'd-flex py-2 justify-content-center align-items-center' : 'sticky-bottom-buttons '}
                    style={{
                        zIndex: 100,
                    }}>
                    <div>
                        <DLButton
                            id={'save-and-close-button'}
                            type={"danger"}
                            styleType={"outlined"}
                            sideMargin={'none'}
                            onClick={() => {
                                openDiscardModal()
                            }}
                            label={"Discard"}
                        />
                    </div>
                    <div className={isMobileView ? "ps-3" : ""}>
                        <DLButton id={'next-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  onClick={() => {
                                      submitTransactionDetails()
                                  }}
                                  isDisabled={!isEmpty(responseErrors)}
                                  label={"Done"}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderDiscardModal = () => {
        const {closeAddDiscardModal, isOpenDiscardModal, redirectScreen} = props;
        return (
            <DLModal
                history={props.history}
                maxWidth={"xs"}
                onClose={closeAddDiscardModal}
                open={isOpenDiscardModal}
                id={'discard-modal'}>
                <DLModalTitle
                    id={'discard-title'}
                    onClose={() => closeAddDiscardModal()}
                    title={'Confirmation'}/>
                <DLModalContainer
                    id={'discard-container'}>
                    <div className='mb-2 pb-1 text-center'>
                        <DLText id={''}
                                text={"Are you sure you want to discard this transaction?"}
                                fontSize={"sm"}
                                fontWeight={"normal"}
                                marginBottom={"none"}
                        />
                        <DLText id={''}
                                text={"This action cannot be undone."}
                                fontSize={"sm"}
                                fontColor={"danger"}
                                marginBottom={"none"}
                        />
                    </div>
                </DLModalContainer>
                <DLModalActions id={'discard-action'}>
                    <div className='mb-4' style={{paddingTop: '7px'}}>
                        <DLButton
                            id={'discard-cancelBtn'}
                            history={props.history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => closeAddDiscardModal()}/>
                        <DLButton
                            id={'discard-btn'}
                            history={props.history}
                            styleType={"contained"}
                            type={"danger"}
                            label={'Discard'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => {
                                redirectScreen('DISCARD')
                            }}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        );
    };

    const renderHeader = () => {
        if (isMobileView) {
            return (
                <div
                    className={'d-flex align-items-center align-content-center w-100 justify-content-between border-bottom px-3 pb-3'}>
                    <DLText id={"purchase-transaction-field"}
                            text={"STP Transaction: Edit"}
                            fontSize={"md"}
                            fontWeight={'semi-bold'}
                            marginBottom={"none"}
                    />
                </div>
            )
        }

        return (
            <div className='d-flex justify-content-between w-100'>
                <div className='page-header p-0 m-0'>
                    STP Transaction: Edit
                </div>
            </div>
        )
    };

    const renderUi = () => {
        return (
            <div>
                <div className="bg-light h-100">
                    <div className="bg-white mb-2 border-rounded border">
                        {renderExistingPortfolio()}
                    </div>
                    <div className={'bg-white border-rounded border pb-5 mb-3'}>
                        {renderSelectedPortfolio()}
                    </div>
                </div>
                {renderBottomActionButtons()}
            </div>
        );
    };

    const renderExistingPortfolio = () => {
        return(
            <ExistingPortfolio {...props}
                subTextLabel={"Select the schemes you would like to transfer"}
            />
        )
    };

    const renderMobileViewSelectedPortfolio = () => {
        return (
            <div className={"bg-white"}>
                {isEmpty(selectedScheme) ?
                    <div className={'d-flex justify-content-center align-items-center px-4'} style={{minHeight: "calc(100dvh - 365px)"}}>
                        <DLText
                            id={'empty-ui'}
                            text={'Add a scheme from the existing portfolio for systematic transfer.'}
                            customWrapperStyle={{
                                textAlign: 'center'
                            }}
                        />
                    </div> :
                    <div className={''} style={
                        !isMobileView
                            ? {
                                minHeight: 'calc(100dvh - 490px)',
                                maxHeight: 'calc(100dvh - 490px)',
                            }
                            : {}
                    }>
                        {renderSelectedSchemeTable()}
                    </div>
                }
            </div>
        )
    };

    const renderMobileViewUi = () => {
        return (
            <div>
                <div>
                    <div className="bg-white mb-3">
                        {renderExistingPortfolio()}
                    </div>
                    <div className={'bg-white'}>
                        {renderMobileViewSelectedPortfolio()}
                    </div>
                </div>
                {renderBottomActionButtons()}
            </div>
        );
    };

    return (
        <div className={isMobileView ? 'bg-light' : 'bg-light p-3'}>
            {renderHeader()}
            {renderErrorUI()}
            <div
                className={'bg-light'}>
                {isMobileView ? renderMobileViewUi() : renderUi()}
            </div>
            {renderDiscardModal()}
        </div>
    );
};

export default PurchaseSTPTransactionView;
