import React from "react";
import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import {isEmpty} from "../../../../../../core/components/DLComponentHelper";
import {numberWithCommas} from "../../../../../../utils/helper";
import {txnRequestTypes} from "../../../../../../clientRequest/ClientRequestConstants";

const TxnTableSwitchView = (props) => {

    const {
        isMobileView,
        investmentDetails,
        txnStage,
        handleAddOrEditTransactionDetails,
        isManagementUser,
        totalSwitchAmount
    } = props;

    const renderEmptyUi = () => {
        return (
            <div>
                <DLText id={''}
                        text={'No funds have been added for switch.'}
                        fontWeight={'normal'}
                        fontSize={"sm"}
                />
                {!isManagementUser && <div className='d-flex justify-content-center'>
                    <DLButton id={'add-button'}
                              onClick={() => handleAddOrEditTransactionDetails("ADD")}
                              hidden={txnStage !== "DRAFT"} // TBD remove or not
                              label={'Add'}/>
                </div>}
            </div>
        )
    };

    const renderHeadings = () => {
        return (
            <div className={"py-2 border-bottom row px-0 m-0"}>
                <div className='data-card-label col-4'>
                    <DLText id={'scheme'}
                            text={"Source Scheme"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-2'>
                    <DLText id={'folio-no'}
                            text={"Folio No."}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-4'>
                    <DLText id={'target-scheme'}
                            text={"Target Scheme"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>

                <div className='data-card-label col-2 text-end'>
                    <DLText id={'amount'}
                            text={"Amount"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
            </div>
        )
    };

    const renderData = () => {
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"py-2 row px-0 m-0"}
                                 key={index}>
                                <div className='data-card-label col-4'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item.SourceProductName) ? '-' : item.SourceProductName}
                                                fontWeight={"semi-bold"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='data-card-label col-2'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item.folioNumber) ? '-' : item.folioNumber}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                        />
                                    </div>

                                </div>

                                <div className='data-card-label col-4'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item.targetProductName) ? '-' : item.targetProductName}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                        />
                                    </div>

                                </div>


                                <div className='data-card-label col-2'>
                                    <div className='d-flex align-items-center justify-content-end h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item.value) ? '-' : numberWithCommas((item.value).toFixed(2))}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderTotalCount = () => {
        return (
            <div className={"py-2 border-top row px-0 m-0"}>
                <div className='data-card-label col-10'/>
                <div className='data-card-label col-2 d-flex justify-content-end'>
                    <div className={'text-end'} style={{maxWidth: "150px"}}>
                        <DLText id={'total-redemption'}
                                text={isEmpty(totalSwitchAmount) ? '-' : numberWithCommas((totalSwitchAmount).toFixed(2))}
                                fontWeight={"bold"}
                                marginBottom={"none"}
                                fontSize={"sm"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileData = () => {
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className='d-flex justify-content-between px-3 pt-3'>
                                <div className='w-100 px-0 mx-0'>
                                    <DLText
                                        id={"scheme"}
                                        text={"Source Scheme"}
                                        fontColor={"grayDark"}
                                        fontSize={"xs"}
                                        marginBottom={"none"}
                                        isInline={true}
                                    />
                                    <DLText id={'scheme-value'}
                                            text={isEmpty(item.SourceProductName) ? '-' : item.SourceProductName}
                                            fontWeight={'normal'}
                                            fontSize={"xs"}
                                            marginBottom={'none'}
                                    />
                                </div>
                            </div>

                            <div className='pt-2 pb-3 px-3'>
                                <DLText
                                    id={"type"}
                                    text={"Folio no. "}
                                    fontColor={"grayDark"}
                                    fontSize={"xs"}
                                    marginBottom={"none"}
                                    isInline={true}
                                />
                                <DLText
                                    id={'folio-no'}
                                    text={isEmpty(item.folioNumber) ? '-' : item.folioNumber}
                                    fontSize={"xs"}
                                    marginBottom={"none"}
                                    isInline={true}
                                />
                            </div>

                            <div className='d-flex justify-content-between px-3'>
                                <div className='w-100 px-0 mx-0'>
                                    <DLText
                                        id={'target-scheme'}
                                        text={"Target Scheme"}
                                        fontColor={"grayDark"}
                                        fontSize={"xs"}
                                        marginBottom={"none"}
                                        isInline={true}
                                    />
                                    <DLText id={'target-scheme-value'}
                                            text={isEmpty(item.targetProductName) ? '-' : item.targetProductName}
                                            fontWeight={'normal'}
                                            fontSize={"xs"}
                                            marginBottom={'none'}
                                    />
                                </div>
                            </div>


                            <div className={'d-flex justify-content-between flex-wrap gap-2 gap-wrap-3 px-3'}>
                                <div className="pe-2" id={'transferUnits-field-' + index} style={{flex: 1}}>
                                    <div className="pe-2 p-1">
                                        <DLText
                                            id={"amount"}
                                            text={"Amount"}
                                            fontColor={"grayDark"}
                                            fontSize={"xs"}
                                            marginBottom={"none"}
                                        />
                                        <div className={'pt-2'}>
                                            <DLText
                                                id={"amount-value"}
                                                text={isEmpty(item.value) ? '-' : numberWithCommas((item.value).toFixed(2))}
                                                fontWeight={'normal'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderMobileSwitchDetails = () => {
        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded">
                    <div className='d-flex justify-content-center align-items-center black-border-bottom h-100 bg-white'
                         style={{minHeight: '578px'}}>
                        <div className='text-center my-5'>
                            {renderEmptyUi()}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white">
                <div
                    className='d-flex justify-content-end align-items-center p-3 border-top-black border-bottom-black'>
                    <i className="fa-solid fa-pencil"
                       hidden={txnStage !== "DRAFT" || isManagementUser}
                       onClick={() => handleAddOrEditTransactionDetails("EDIT")}/>
                </div>
                <div className='p-0 w-100 h-100'>
                    <div className="h-100">
                        {renderMobileData()}
                    </div>
                </div>
            </div>
        )
    };

    const renderSwitchDetails = () => {

        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded h-100 p-3">
                    <div className='d-flex justify-content-center align-items-center h-100 panel rounded border'>
                        <div className='text-center'>
                            {renderEmptyUi()}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3 ">
                <div className='d-flex justify-content-end align-items-center column-gap-4'>
                    <DLButton
                        id={'edit-transaction'}
                        label={'Edit'}
                        hidden={txnStage !== "DRAFT" || isManagementUser}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => handleAddOrEditTransactionDetails("EDIT")}/>
                </div>
                <div className='pt-3'>
                    <div className='p-0 w-100 border-rounded panel h-100'>
                        <div className="p-2 h-100 ">
                            {renderHeadings()}
                            {renderData()}
                            {renderTotalCount()}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className={'h-100'}>
            {isMobileView ? renderMobileSwitchDetails() : renderSwitchDetails()}
            {!isEmpty(investmentDetails) && isMobileView && (
                <div
                    className={'d-flex justify-content-start align-items-center gap-1 p-3'}>
                    <DLText id={''}
                            text={'Total Switch Amount: '}
                            fontSize={"md"}
                            fontWeight={"normal"}
                            fontColor={"grayDark"}
                            marginBottom={"none"}
                    />
                    <DLText id={''}
                            text={'Rs.' + numberWithCommas(totalSwitchAmount)}
                            fontSize={"md"}
                            fontWeight={"bold"}
                            marginBottom={"none"}
                    />
                </div>
            )}
        </div>
    );
};

export default TxnTableSwitchView;
