import React, {useEffect, useState} from 'react';

import {APP_CONST} from "../../../../../AppComponents/AppConstants";
import {isEmpty} from "../../../../../utils/validations";

import {connect} from "react-redux";

import {
    initiateTransaction, getSipFrequency, getStpFrequency
} from "../../../../ClientRequestsActions";

import AddNewTransactionView from "./AddNewTransactionView";
import moment from "moment/moment";
import {getClientBankAccount} from "../../../../../clients/ClientsActions";
import {getTxnRequestType as getTransactionOptionsList} from "../../../../../AppComponents/MetadataActions";
import {txnRequestTypes} from "../../../../ClientRequestConstants";

const AddNewTransaction = (props) => {
    const [addNewTransactionState, setAddNewTransactionState] = useState({
        transactionOptions: [],
        amount: "",
        txnOption: '',
        isShowLoader: false,
        modalErrorMessage: '',
        isTxnOptionSelected: false,
        frequencyData: [],
        bankDetails: [],
        selectedFrequency: '',
        selectedBankAccountId: '',
        startDate: '',
        endDate: '',
        isMaxPeriodSelected: false,
        errors: {
            amountError: '',
            txnOptionError: '',
            startDateError: '',
            endDateError: '',
            selectedFrequencyError: '',
            accNumberError: '',
            selectedBankAccountIdError: '',
        },
    });

    useEffect(() => {
        getTransactionOptions()
    }, [])

    const getId = () => {
        return parseInt(props.match?.params?.id)
    };

    const getTransactionOptions = () => {
        setAddNewTransactionState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));


        getTransactionOptionsList().then(res => {
            if (res?.success) {
                setAddNewTransactionState(prevState => ({
                    ...prevState,
                    transactionOptions: setValuesForDropdown(res.data?.txnRequestTypes, 'name', 'id'),
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                    modalErrorMessage: ''
                }));
            } else {
                setAddNewTransactionState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    modalErrorMessage: res.__error
                }));
            }
        })
    };

    const getSipDetails = () => {
        setAddNewTransactionState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));
        getSipFrequency()
            .then((res) => {
                if (res?.success) {
                    setAddNewTransactionState(prevState => ({
                        ...prevState,
                        frequencyData: res.data.frequencies,
                        isShowLoader: false,
                    }))
                } else {
                    setAddNewTransactionState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }))
                }
            })

        getBankAccountDetails();
    }

    const getBankAccountDetails = () => {
        const {accountId} = props;
        setAddNewTransactionState(prevState => ({
            ...prevState,
            isShowLoader: true
        }))

        getClientBankAccount(accountId)
            .then((res) => {
                if (res?.success) {
                    setAddNewTransactionState(prevState => ({
                        ...prevState,
                        bankDetails: res.data.banks,
                        isShowLoader: false,
                    }))
                } else {
                    setAddNewTransactionState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }))
                }
            })
    }

    const handleChange = (name, value) => {

        let updatedState = {...addNewTransactionState};
        updatedState[name] = value;
        const validateObject = validateChanges(name, updatedState);

        setAddNewTransactionState(prevStep => ({
            ...prevStep,
            ...updatedState,
            modalErrorMessage: '',
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));
    };

    const setMaxPeriod = () => {
        setAddNewTransactionState(prevState => ({
            ...prevState,
            isMaxPeriodSelected: !prevState.isMaxPeriodSelected,
            endDate: null,
            errors: {
                ...prevState.errors,
                endDateError: ''
            }
        }))
    }

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return;
        }

        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    const getSTPFrequencyDetails = () =>{

        getStpFrequency()
            .then((res) => {
                if (res?.success) {
                    setAddNewTransactionState(prevState => ({
                        ...prevState,
                        frequencyData: res.data.frequencies,
                        isShowLoader: false,
                    }))
                } else {
                    setAddNewTransactionState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }))
                }
            })
    };

    const validateChanges = (name, compState) => {
        const {amount, txnOption, startDate, endDate, selectedFrequency, selectedBankAccountId} = compState;
        let errorObj = {...addNewTransactionState.errors};
        let todayDate = moment().format('YYYY-MM-DD');
        if (name === "txnOption" || name === APP_CONST.FORM_SUBMISSION) {
            errorObj.txnOptionError = isEmpty(txnOption) ? "Please select transaction type" : "";
        }
        if (txnOption === 'SIP') {
            if (name === "amount" || name === APP_CONST.FORM_SUBMISSION) {
                errorObj.amountError = isEmpty(amount) ? "Please enter amount" : "";
            }
            //
            // if (name === "startDate" || name === APP_CONST.FORM_SUBMISSION) {
            //   errorObj.startDateError = isEmpty(startDate) ? "Please select start date" : "";
            // }
            //
            // if (name === APP_CONST.FORM_SUBMISSION && !isEmpty(startDate)) {
            //   let startDates=moment(startDate).format('YYYY-MM-DD')
            //   errorObj.startDateError =todayDate>startDates ? "SIP start date should be equal or higher than the current date" : "";
            // }
            //
            // if ((name === "endDate" || name === APP_CONST.FORM_SUBMISSION) && !addNewTransactionState.isMaxPeriodSelected) {
            //   errorObj.endDateError = isEmpty(endDate) ? "Please select end date" : "";
            // }
            //
            // if (name === APP_CONST.FORM_SUBMISSION && !isEmpty(endDate) && !isEmpty(startDate) && !addNewTransactionState.isMaxPeriodSelected) {
            //   let minEndDate= moment(startDate).add(12, 'months').format('YYYY-MM-DD');
            //   let endDates=moment(endDate).format('YYYY-MM-DD')
            //   errorObj.endDateError =minEndDate>endDates ? "Minimum 12 installments required" : "";
            // }

            if (name === "selectedFrequency" || name === APP_CONST.FORM_SUBMISSION) {
                errorObj.selectedFrequencyError = isEmpty(selectedFrequency) ? " Please select frequency" : "";
            }

            if (name === "selectedBankAccountId" || name === APP_CONST.FORM_SUBMISSION) {
                errorObj.selectedBankAccountIdError = isEmpty(selectedBankAccountId) ? " Please select bank details" : "";
            }
        }
        if (txnOption === 'SWP') {
            if (name === "selectedFrequency" || name === APP_CONST.FORM_SUBMISSION) {
                errorObj.selectedFrequencyError = isEmpty(selectedFrequency) ? " Please select frequency" : "";
            }

            if (name === "selectedBankAccountId" || name === APP_CONST.FORM_SUBMISSION) {
                errorObj.selectedBankAccountIdError = isEmpty(selectedBankAccountId) ? " Please select bank details" : "";
            }
        }
        if (txnOption === 'LUMPSUM') {
            if (name === "amount" || name === APP_CONST.FORM_SUBMISSION) {
                errorObj.amountError = isEmpty(amount) ? "Please enter amount" : "";
            }
        }
        if (txnOption === 'REDEMPTION') {
            if (name === "selectedBankAccountId" || name === APP_CONST.FORM_SUBMISSION) {
                errorObj.selectedBankAccountIdError = isEmpty(selectedBankAccountId) ? " Please select bank details" : "";
            }
        }
        if(txnOption==='STP'){
            if (name === "selectedFrequency" || name === APP_CONST.FORM_SUBMISSION) {
                errorObj.selectedFrequencyError = isEmpty(selectedFrequency) ? " Please select frequency" : "";
            }
        }

        return errorObj;
    };

    const getSwpDetails = () => {
        setAddNewTransactionState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getSipFrequency('SWP')
            .then((res) => {
                if (res?.success) {
                    setAddNewTransactionState(prevState => ({
                        ...prevState,
                        frequencyData: res.data.frequencies,
                        isShowLoader: false,
                    }))
                } else {
                    setAddNewTransactionState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }))
                }
            })
        getBankAccountDetails();
    }

    const handleNext = () => {
        const {txnOption} = addNewTransactionState;
        const validateObj = validateChanges("txnOption", addNewTransactionState);

        if (!isEmpty(txnOption)) {
            setAddNewTransactionState(prevStep => ({
                ...prevStep,
                isTxnOptionSelected: true
            }));
            if (txnOption === 'SIP') {
                getSipDetails();
            }
            if (txnOption === 'STP') {
                getSTPFrequencyDetails();
            }
            if (txnOption === 'REDEMPTION') {
                getBankAccountDetails();
            }
            if (txnOption === 'SWP') {
                getSwpDetails();
            }
            return;
        }
        setAddNewTransactionState(prevStep => ({
            ...prevStep,
            errors: {
                ...prevStep.errors,
                ...validateObj
            }
        }));
    };

    const handleInitiate = () => {
        const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, addNewTransactionState);
        const {
            startDate,
            endDate,
            selectedFrequency,
            txnOption,
            amount,
            isMaxPeriodSelected,
            selectedBankAccountId
        } = addNewTransactionState;

        if (!isEmpty(validateObj?.amountError)) {
            setAddNewTransactionState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return;
        }
        if (!isEmpty(validateObj?.startDateError)) {
            setAddNewTransactionState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return;
        }
        if (!isEmpty(validateObj?.endDateError) && !addNewTransactionState.isMaxPeriodSelected) {
            setAddNewTransactionState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return;
        }
        if (!isEmpty(validateObj?.selectedFrequencyError)) {
            setAddNewTransactionState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return;
        }
        if (!isEmpty(validateObj?.selectedBankAccountIdError)) {
            setAddNewTransactionState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return;
        }
        setAddNewTransactionState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        let payload;

        switch (txnOption) {
            case 'LUMPSUM':
                payload = {
                    clientRequestId: parseInt(getId()),
                    requestType: txnOption,
                    amount: parseInt(amount),
                };
                break;

            case 'SIP':
                payload = {
                    clientRequestId: parseInt(getId()),
                    requestType: txnOption,
                    amount: parseInt(amount),
                    bankAccountId: isEmpty(selectedBankAccountId) ? null : selectedBankAccountId,
                    startDate: moment(startDate).format('YYYY-MM-DD'),
                    endDate: isEmpty(endDate) ? null : moment(endDate).format('YYYY-MM-DD'),
                    frequency: isEmpty(selectedFrequency) ? null : selectedFrequency,
                    isForMaxPeriod: isMaxPeriodSelected,
                };
                break;

            case 'REDEMPTION':
                payload = {
                    clientRequestId: parseInt(getId()),
                    requestType: txnOption,
                    bankAccountId: isEmpty(selectedBankAccountId) ? null : selectedBankAccountId,
                };
                break;

            case txnRequestTypes.SWITCH:
                payload = {
                    clientRequestId: parseInt(getId()),
                    requestType: txnOption,
                };
                break;

            case 'STP':
                payload = {
                    clientRequestId: parseInt(getId()),
                    requestType: txnOption,
                    frequency: isEmpty(selectedFrequency) ? null : selectedFrequency
                };
                break;

            case 'SWP':
                payload = {
                    clientRequestId: parseInt(getId()),
                    requestType: txnOption,
                    bankAccountId: isEmpty(selectedBankAccountId) ? null : selectedBankAccountId,
                    frequency: isEmpty(selectedFrequency) ? null : selectedFrequency,
                };
                break;
        }

        initiateTransaction(payload, true).then(res => {
            if (res?.success) {
                setAddNewTransactionState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false,
                }));
                props.history.push("/client-requests/" + getId() + "/financial-transaction/" + res.data?.id)
            } else {
                setAddNewTransactionState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false,
                    modalErrorMessage: res.__error
                }));
            }
        })
    };

    return (
        <AddNewTransactionView
            {...props}
            {...addNewTransactionState}
            handleChange={handleChange}
            handleInitiate={handleInitiate}
            handleNext={handleNext}
            setMaxPeriod={setMaxPeriod}
        />
    );
};
const mapStateToProps = (state) => ({
    profile: state.userState.profile,
    isMobileView: state.appState.deviceInfo.isMobileView,
    leadsFilter: state.leadState.leadsFilter,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(AddNewTransaction);
