import {connect} from "react-redux";
import {useEffect, useState} from "react";

import store from "../../../../../../store";
import {APP_CONST} from "../../../../../../AppComponents/AppConstants";
import {isEmpty} from "../../../../../../utils/validations";
import {getExistingPortfolio, getTransactionDetails, submitFundRequest} from "../../../../FTAction";
import {getClientRequestById} from "../../../../../../clientRequest/ClientRequestsActions";
import {showToastMessage} from "../../../../../../AppComponents/AppActions";
import {getCommaSeparatedDecimalValue} from "../../../../FTHelper";

import PurchaseRedemptionTransactionView from "./PurchaseRedemptionTransactionView";

const PurchaseRedemptionTransaction = (props) => {
    const [redemptionState, setRedemptionState] = useState({
        existingPortfolio: [],
        selectedScheme: [],
        redemptionDetails: [],
        totalRedeemAmount: 0,
        UIState: '',
        products: [],
        selectedId: '',
        isDoneClicked: false,
        isOpenDiscardModal: false,
        isShowLoader: false,
        isShowInlineLoader: false,
        totalSelectedAmount: '',
        isAddToListButtonDisabled: false,
        responseErrors: ''
    });

    useEffect(() => {
        getExistingPortfolioDetails();
    }, []);

    useEffect(() => {
        isSchemeFieldDisabled();
    }, [redemptionState.selectedScheme]);

    const getClientRequestId = () => {
        return parseInt(props.match?.params?.clientRequest);
    };

    const getTransactionRequestId = () => {
        return props.match?.params?.transactionId
    };

    const redirectScreen = (action, data) => {
        let transactionRequestId = getTransactionRequestId();
        let clientRequestId = getClientRequestId();

        if (action === 'SELECT-FOLIO') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/financial-transaction/' + transactionRequestId + '/select-portfolio', {portfolio: data});
        }
        if (action === 'DISCARD') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/financial-transaction/' + transactionRequestId);
        }
    };

    const setUiState = () => {
        setRedemptionState((prevState) => ({
            ...prevState,
            UIState: APP_CONST.CONTENT_AVAILABLE
        }))
    };

    const getExistingPortfolioDetails = () => {

        const clientRequestId = getClientRequestId();
        const txnRequestId = getTransactionRequestId();

        setRedemptionState((prevState) => ({
            ...prevState,
            isShowLoader: true,
        }));

        getClientRequestById(clientRequestId).then(requestData => {
            if (requestData?.success) {
                let accountId = requestData?.data?.accountId;

                getTransactionDetails(txnRequestId).then(transactionResData => {
                    if (transactionResData?.success) {
                        let transactionDetails = transactionResData?.data;

                        getExistingPortfolio(accountId).then(res => {
                            if (res?.success) {

                                setRedemptionState(prevState => ({
                                    ...prevState,
                                    existingPortfolio: res?.data?.fundInvestments,
                                    redemptionDetails: isEmpty(transactionDetails?.funds) ? [] : transactionDetails?.funds,
                                    totalRedeemAmount: res?.data?.totalValuation,
                                    isShowLoader: false,
                                    isAddToListButtonDisabled: true,
                                    UIState: APP_CONST.CONTENT_AVAILABLE,
                                }));
                                if (!isEmpty(transactionDetails?.funds)) {
                                    getSelectedSchemeDetails(transactionDetails?.funds, res?.data?.fundInvestments);
                                }
                            } else {
                                setRedemptionState(prevState => ({
                                    ...prevState,
                                    responseErrors: typeof res?.__error === 'string' ? res?.__error : '',
                                    isAddToListButtonDisabled: true,
                                    isShowLoader: false,
                                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                                }));
                            }
                        })
                    }
                })

            } else {
                setRedemptionState(prevState => ({
                    ...prevState,
                    responseErrors: typeof requestData?.__error === 'string' ? requestData?.__error : '',
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                }));
            }
        })
    };

    const handleCheckBox = (id) => {
        const {selectedId, selectedScheme} = redemptionState;

        if (selectedId === id) {
            setRedemptionState(prevState => ({
                ...prevState,
                selectedId: '',
                isAddToListButtonDisabled: isAddToListDisabled(selectedScheme)
            }));
        } else {
            setRedemptionState((prevState) => ({
                ...prevState,
                selectedId: id,
                isAddToListButtonDisabled: isAddToListDisabled(selectedScheme)
            }));
        }
    };

    const setSelectedSchemeData = (scheme) => {
        const updatedSelectedScheme = scheme.map(portfolio => {
            return {
                ...portfolio,
                scheme: portfolio?.productDetails?.description || '',
                fundInvestmentId: portfolio?.id || '',
                type: 'AMOUNT',
                redeemUnits: null,
                redeemAmount: null,
                productId: portfolio?.productDetails?.id,
                folioId: portfolio?.id,
                valuation: portfolio?.valuation,
                asOfDate: portfolio?.asOfDate,
                nav: portfolio?.nav,
                errors:[]
            }
        });

        setRedemptionState((prevState) => ({
            ...prevState,
            selectedScheme: updatedSelectedScheme
        }))
    };

    const validateFields = (selectedScheme, amount, unit, type) => {
        return selectedScheme?.map((scheme) => {
                return {
                    ...scheme,
                    errors: getErrors(selectedScheme, amount, unit, type)
                }
            }
        );
    };

    const submitTransactionDetails = () => {
        const {selectedScheme} = redemptionState;

        if (isEmpty(selectedScheme)) {
            redirectScreen('DISCARD')
        }

        let transactionRequestId = getTransactionRequestId();
        let updateSchemeWithErrors = validateFields(selectedScheme, selectedScheme[0]?.redeemAmount, selectedScheme[0]?.redeemUnits, selectedScheme[0]?.type);

        setRedemptionState((prevState) => ({
            ...prevState,
            selectedScheme: isEmpty(updateSchemeWithErrors) ? [] : updateSchemeWithErrors,
            isDoneClicked: true
        }));

        let transactionErrors = validateTransaction(updateSchemeWithErrors);
        if (transactionErrors.length > 0) {
            store.dispatch(showToastMessage('warning', transactionErrors?.length === 1 ? 'Error exists, changes are not saved.' : 'Errors exist, changes are not saved.'));
            return;
        }

        setRedemptionState((prevState) => ({
            ...prevState,
            isShowLoader: true,
        }));

        let payload = {
            transactionRequestId: parseInt(transactionRequestId),
            txnRequestType: "REDEMPTION",
            products: getProducts(updateSchemeWithErrors),
        };

        submitFundRequest(payload, true)
            .then(res => {
                if (res.success) {
                    setRedemptionState((prevState) => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                    redirectScreen('DISCARD')
                } else {

                    setRedemptionState((prevState) => ({
                        ...prevState,
                        responseErrors: typeof res?.__error === 'string' ? res?.__error : '',
                        selectedScheme: typeof res?.__error === 'object' ? handleSchemesWithErrors(res?.__error, updateSchemeWithErrors) : updateSchemeWithErrors,
                        isShowLoader: false,
                    }))
                }
            })
    };

    const getSelectedSchemeDetails = (schemeDetails, existingPortfolio) => {

        let selectedScheme = existingPortfolio.find(portfolio => (schemeDetails[0]?.folioNumber === portfolio.folioNo) && (schemeDetails[0]?.productName === portfolio?.productDetails?.description));

        let updatedSelectedScheme = schemeDetails.map((scheme) => {
            return {
                ...scheme,
                scheme: scheme?.productName || '',
                type: scheme?.type,
                fundInvestmentId: selectedScheme?.id || '',
                redeemUnits: Number(scheme?.submissionDetails?.units),
                redeemAmount: Number(scheme?.submissionDetails?.amount),
                productId: selectedScheme?.productDetails?.id,
                nav: selectedScheme?.nav,
                asOfDate: selectedScheme?.asOfDate,
                folioNo: scheme?.folioNumber,
                folioId: selectedScheme?.id,
                units: Number(selectedScheme?.units),
                valuation: Number(selectedScheme?.valuation),
            }
        });

        setRedemptionState((prevState) => ({
            ...prevState,
            selectedScheme: updatedSelectedScheme,
            isAddToListButtonDisabled: true
        }))
    };

    const getProducts = (updateSchemeWithErrors) => {
        let products = [];
        if (isEmpty(updateSchemeWithErrors)) {
            return products;
        }

        updateSchemeWithErrors?.map((selectedScheme) => {

            products.push({
                productId: selectedScheme?.productId,
                type: selectedScheme?.type,
                value: selectedScheme?.type === 'ALL' ? 0 : selectedScheme?.type === 'UNIT' ? Number(selectedScheme?.redeemUnits) : Number(selectedScheme?.redeemAmount),
                submissionDetails: {
                    fundId: selectedScheme?.productId,
                    folioNumber: isEmpty(selectedScheme?.folioNo) ? '' : selectedScheme?.folioNo,
                    availableUnits: Number(selectedScheme?.units),
                    nav: Number(selectedScheme?.nav),
                    navAsOfDate: selectedScheme?.asOfDate,
                    valuation: Number(selectedScheme?.valuation)
                }
            })
        });

        return products;
    };

    const validateTransaction = (updateSchemeWithErrors) => {
        let allErrors = [];
        if (isEmpty(updateSchemeWithErrors)) {
            return allErrors;
        }
        updateSchemeWithErrors?.map((selectedFund) => {
            let errors = isEmpty(selectedFund?.errors) ? [] : selectedFund?.errors;
            allErrors = [...allErrors, ...errors]
        });
        return allErrors;
    };

    const handleSchemesWithErrors = (errors, schemes) => {
        if (isEmpty(errors) || isEmpty(schemes)) {
            return schemes;
        }
        let allSchemes = schemes.map(fund => {

            let productId = fund?.productId;
            let responseError = errors.find((obj) => {
                return obj?.productId === productId
            });
            return {
                ...fund,
                errors: !isEmpty(responseError) ? [] : responseError?.errors
            };
        });
        return allSchemes;
    };

    const isSchemeFieldDisabled = () => {
        const {existingPortfolio, selectedScheme} = redemptionState;
        const updatedPortfolio = existingPortfolio?.map(portfolio => {
            const isMatch = selectedScheme?.some(scheme => scheme.id === portfolio.id);
            return {
                ...portfolio,
                isDisabled: isMatch,
            };
        });

        setRedemptionState(prevState => ({
            ...prevState,
            existingPortfolio: updatedPortfolio,
        }));
    };

    const isAddToListDisabled = (selectedScheme) => {
        const {selectedId} = redemptionState;

        return !isEmpty(selectedScheme) && !isEmpty(selectedId);
    };

    const openDiscardModal = () => {
        setRedemptionState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: true
        }))
    };

    const closeAddDiscardModal = () => {
        setRedemptionState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: false
        }))
    };

    const handleChange = (value, productId, name) => {
        const {selectedScheme, isDoneClicked} = redemptionState;
        let newValue;

        if(value === '.') {
            newValue = '0.';
        } else if(isEmpty(value)) {
            newValue = value;
        } else {
            newValue = Number(value.replace(/,/g, ""));
        }

        let redeemUnits;
        let redeemAmount;
        let type;

        const updatedSelectedScheme = selectedScheme?.map((scheme) => {
            if (productId === scheme?.id) {
                redeemUnits = scheme?.redeemUnits;
                redeemAmount = scheme?.redeemAmount;
                type = scheme?.type;

                if (name === 'type') {
                    type = value;

                    let updateSchemeWithErrors = validateFields(selectedScheme, redeemAmount, redeemUnits, type);

                    setRedemptionState((prevState) => ({
                        ...prevState,
                        selectedScheme: isEmpty(updateSchemeWithErrors) ? [] : updateSchemeWithErrors,
                    }));

                    if (value === 'AMOUNT') {
                        return {
                            ...scheme,
                            redeemAmount: '',
                            redeemUnits: '',
                            type: value,
                        }
                    } else if (value === 'UNIT') {
                        return {
                            ...scheme,
                            redeemAmount: '',
                            redeemUnits: '',
                            type: value,
                        }
                    } else {
                        return {
                            ...scheme,
                            redeemUnits: Number(scheme.units),
                            redeemAmount: Number(scheme.valuation),
                            type: value,
                        }
                    }
                }
                if (name === 'redeemAmount') {
                    redeemAmount = newValue;
                    redeemUnits = isEmpty(newValue) ? '' : Number((newValue / scheme.nav));

                    let updateSchemeWithErrors = validateFields(selectedScheme, redeemAmount, redeemUnits, type);

                    setRedemptionState((prevState) => ({
                        ...prevState,
                        selectedScheme: isEmpty(updateSchemeWithErrors) ? [] : updateSchemeWithErrors,
                    }));

                    return {
                        ...scheme,
                        redeemUnits: redeemUnits,
                        redeemAmount: isEmpty(newValue) ? '' : newValue,
                    }
                } else if (name === 'redeemUnits') {
                    redeemUnits = newValue;
                    redeemAmount = isEmpty(newValue) ? '' : Number((newValue * scheme.nav));

                    let updateSchemeWithErrors = validateFields(selectedScheme, redeemAmount, redeemUnits, type);

                    setRedemptionState((prevState) => ({
                        ...prevState,
                        selectedScheme: isEmpty(updateSchemeWithErrors) ? [] : updateSchemeWithErrors,
                    }));

                    return {
                        ...scheme,
                        redeemUnits: isEmpty(newValue) ? '' : newValue,
                        redeemAmount: redeemAmount,
                    }
                }
                return {
                    ...scheme,
                    redeemUnits: Number(scheme.units),
                    redeemAmount: Number(scheme.valuation),
                    [name]: value,
                };
            }

            return scheme;
        });

        const updatedScheme = isDoneClicked
            ? validateFields(updatedSelectedScheme, redeemAmount, redeemUnits, type)
            : updatedSelectedScheme;

        setRedemptionState((prevState) => ({
            ...prevState,
            selectedScheme: updatedScheme,
            isShowLoader: false,
        }));

    };

    const replaceExistingMessage = (array, newValues) => {
        if (isEmpty(array)) {
            return [];
        }
        let updatedArray = [...array];
        for (let i = 0; i < updatedArray.length; i++) {
            if (updatedArray[i].includes(('Amount should be less than'))) {
                updatedArray[i] = newValues?.maximumAmountMessage;
            }
            if (updatedArray[i].includes(("units are allowed"))) {
                updatedArray[i] = newValues?.maximumUnitMessage;
            }
            if (updatedArray[i].includes(("Amount should be greater than zero"))) {
                updatedArray[i] = newValues?.minimumAmountMessage;
            }
            if (updatedArray[i].includes(("unit required"))) {
                updatedArray[i] = newValues?.minimumUnitMessage;
            }
        }
        return updatedArray;
    };

    const getErrors = (selectedScheme, amount, unit, type) => {
        let errors = [];

        let uniqueErrors = errors;

        if (isEmpty(type)) {
            return ["Please select type"];
        } else if (isEmpty(amount) && type === 'AMOUNT' && type !== 'ALL') {
            return ["Please enter amount"];
        } else if (isEmpty(unit) && type === 'UNIT' && type !== 'ALL') {
            return ["Please enter units"];
        }
        else {

            let maxUnit = selectedScheme[0]?.units;
            let maxAmount = selectedScheme[0]?.valuation;
            let minAmount = 0;
            let minUnit = 1;

            let maximumAmountMessage = 'Amount should be less than ' + getCommaSeparatedDecimalValue((maxAmount).toFixed(2), true);
            let minimumAmountMessage = 'Amount should be greater than zero';
            let maximumUnitMessage = `Maximum ${getCommaSeparatedDecimalValue((maxUnit).toFixed(4), true)} units are allowed`;
            let minimumUnitMessage = `Minimum ${minUnit} unit required`;

            let valuesArray = {
                maximumAmountMessage: maximumAmountMessage,
                maximumUnitMessage: maximumUnitMessage,
                minimumUnitMessage: minimumUnitMessage,
                minimumAmountMessage: minimumAmountMessage,
            };

            errors = replaceExistingMessage(errors, valuesArray);

            if (!isEmpty(selectedScheme) && type !== 'ALL' ) {
                if (unit > maxUnit && type === 'UNIT') {
                    errors.push(maximumUnitMessage)
                }
                if (amount > maxAmount && type === 'AMOUNT') {
                    errors.push(maximumAmountMessage)
                }
                if (amount <= minAmount && type === 'AMOUNT') {
                    errors.push(minimumAmountMessage)
                }
                if (unit < minUnit && type === 'UNIT') {
                    errors.push(minimumUnitMessage)
                }
                else {
                    errors = errors.filter(value => (value !== maximumUnitMessage) || (value !== maximumUnitMessage) || (value !== minimumUnitMessage) || (value !== minimumAmountMessage));
                }

                uniqueErrors = [...new Set(errors)];
            }
            return uniqueErrors
        }
    };

    const handleDelete = (id) => {
        const {selectedScheme} = redemptionState;
        const updatedSelectedScheme = selectedScheme.filter(scheme => id !== scheme.id);

        setRedemptionState(prevState => ({
            ...prevState,
            selectedScheme: updatedSelectedScheme,
            isDoneClicked: false,
            isAddToListButtonDisabled: isAddToListDisabled(updatedSelectedScheme),
        }));
        handleCheckBox(id);
    };

    return (
        <PurchaseRedemptionTransactionView
            {...props}
            {...redemptionState}
            setUiState={setUiState}
            submitTransactionDetails={submitTransactionDetails}
            openDiscardModal={openDiscardModal}
            closeAddDiscardModal={closeAddDiscardModal}
            handleChange={handleChange}
            handleDelete={handleDelete}
            redirectScreen={redirectScreen}
            setSelectedSchemeData={setSelectedSchemeData}
        />
    )
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isSMView: state.appState.deviceInfo.isSMView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(PurchaseRedemptionTransaction);
