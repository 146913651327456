import React from "react";

import {
    existingPortFolioClientEditCellConfig,
    existingPortFolioClientHeaderData,
    existingPortFolioEditCellConfig, existingPortFolioEditCellConfigXSView,
    existingPortFolioHeaderData
} from "../../../../FTModal";

import {convertAmountInLakhAndCrore, convertUTCToLocalTime, numberWithCommas} from "../../../../../../utils/helper";
import {isEmpty} from "../../../../../../utils/validations";

import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLCheckbox from "../../../../../../core/components/DLCheckbox/DLCheckbox";
import DLTable from "../../../../../../core/components/DLTable/DLTable";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import appTheme from "../../../../../../assets/appTheme";
import {getCommaSeparatedDecimalValue} from "../../../../FTHelper";

const ExistingPortfolioView = (props) => {
    const {
        isMobileView,
        isMDView,
        isSMView,
        checkIsChecked,
        isCheckboxDisabled,
        handleAddToListButton,
        isAddToListButtonDisabled,
        handleCheckBox,
        existingPortfolio,
        UIState,
        subTextLabel,
        isFromClientView
    } = props;

    const getFormattedExistingPortfolioTableData = () => {
        let tableData = [];
        if (isEmpty(existingPortfolio)) {
            return tableData;
        }
        let filteredExistingPortfolio = existingPortfolio.filter(item => (!isEmpty(item.units) && item.units !== 0))
        filteredExistingPortfolio?.map((selectedPortfolio) => {
            let transactionRecords = [];
            let transactionDetails = {};
            if (!isMobileView) {
                if(!isFromClientView){
                    transactionRecords.push({
                        isCustomUI: true,
                        customUI: renderCheckbox(selectedPortfolio)
                    });
                }
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderScheme(selectedPortfolio)
                });
                transactionRecords.push(selectedPortfolio?.folioNo);
                transactionRecords.push(isEmpty(selectedPortfolio?.units) ? '-' : getCommaSeparatedDecimalValue(Number(selectedPortfolio?.units).toFixed(4)));
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderNavAsOfDate(selectedPortfolio)
                });
                transactionRecords.push(isEmpty(selectedPortfolio?.valuation) ? '-' : numberWithCommas(selectedPortfolio?.valuation.toFixed(2)));
            } else {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderExistingPortfolioMobileUI(selectedPortfolio)
                })
            }
            transactionDetails.id = selectedPortfolio?.clientRequestId;
            transactionDetails.data = transactionRecords;
            tableData.push(transactionDetails)
        });
        return tableData;
    };

    const renderCheckbox = (selectedPortfolio) => {
        return (
            <DLCheckbox
                id={'mark-as-reviewed-dayCheck'}
                fontSize={"sm"}
                disableHelperMessageHeight={true}
                onChangeValue={() => {
                    handleCheckBox(selectedPortfolio?.id)
                }}
                marginBottom={'none'}
                checkboxcolor={'black'}
                isChecked={checkIsChecked(selectedPortfolio?.id)}
                isDisabled={isCheckboxDisabled}
            />
        )
    };

    const renderScheme = (selectedFolio) => {
        return (
            <div className={''}>
                <DLText
                    id={'nav'}
                    text={selectedFolio?.productDetails?.description}
                    fontSize={'xs'}
                    marginBottom={'none'}
                />
            </div>
        )
    };

    const renderNavAsOfDate = (selectedFolio) => {
        return (
            <div>
                <DLText
                    id={'nav'}
                    text={isEmpty(selectedFolio?.nav) ? '-' : getCommaSeparatedDecimalValue(Number(selectedFolio?.nav).toFixed(4))}
                    fontSize={'xs'}
                    marginBottom={'none'}
                />
                <div>
                    <DLText
                        id={'nav'}
                        text={isEmpty(selectedFolio?.asOfDate)? '-' : '('+ convertUTCToLocalTime(selectedFolio?.asOfDate, 'DD-MM-YYYY') + ')'}
                        fontSize={'xs'}
                        marginBottom={'none'}
                    />
                </div>
            </div>
        )
    };

    const renderExistingPortfolioMobileUI = (selectedFolio) => {

        return (
            <div className={"page-container"}>
                <div className={"row"}>
                    <div className={"col-1 pt-1 d-flex align-items-top justify-content-center"}>
                        {renderCheckbox(selectedFolio)}
                    </div>
                    <div className={'col-11 pt-1'}>
                        <DLText
                            id={'scheme'}
                            text={selectedFolio?.productDetails?.description}
                            fontSize={'xs'}
                            marginBottom={'none'}
                            customWrapperStyle={{
                                textAlign: 'left',
                            }}
                        />
                    </div>
                </div>


                <div className={"row d-flex pt-2"}>
                    <div className={'col-1'}>
                    </div>
                    <div className={'col-11 pe-0'}>
                        <DLText
                            id="folio-no"
                            text="Folio No."
                            fontColor="grayDark"
                            fontSize="xxs"
                            marginBottom="none"
                            isInline={true}
                        />
                        <DLText
                            id={'folioNo'}
                            text={selectedFolio?.folioNo}
                            fontSize={'xs'}
                            marginBottom={'none'}
                            isInline={true}
                        />
                    </div>
                </div>

                <div className={"row d-flex pt-2"}>
                    <div className={'col-1'}>
                    </div>
                    <div className={'col-4 pe-0'}>
                        <DLText
                            id="available-units"
                            text="Available Units"
                            fontColor="grayDark"
                            fontSize="xxs"
                            marginBottom="none"
                        />
                        <DLText
                            id={'availableUnits'}
                            text={isEmpty(selectedFolio?.units) ? '-' : getCommaSeparatedDecimalValue(Number(selectedFolio?.units).toFixed(4))}
                            fontSize={'xs'}
                            marginBottom={'none'}
                        />
                    </div>
                    <div className={'col-3 ps-0'}>
                        <DLText
                            id="nav"
                            text="NAV"
                            fontColor="grayDark"
                            fontSize="xxs"
                            marginBottom="none"
                        />
                        <DLText
                            id={'nav'}
                            text={isEmpty(selectedFolio?.nav) ? '-' : getCommaSeparatedDecimalValue(Number(selectedFolio?.nav).toFixed(4))}
                            fontSize={'xs'}
                            isInline={true}
                            marginBottom={'none'}
                        />
                    </div>
                    <div className={'col-4'}>
                        <DLText
                            id="valuation"
                            text="Valuation"
                            fontColor="grayDark"
                            fontSize="xxs"
                            marginBottom="none"
                        />
                        <DLText
                            id={'valuation'}
                            text={numberWithCommas(selectedFolio?.valuation.toFixed(2))}
                            fontSize={'xs'}
                            marginBottom={'none'}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderSchemeTable = () => {
        const tableData = getFormattedExistingPortfolioTableData();

        if (isEmpty(UIState)) {
            return (
                <DLLoader type={"screen"} isVisible={true}/>
            );
        }

        if (isEmpty(tableData)) {
            return (
                <div className={'d-flex align-items-center justify-content-center'}
                     style={{minHeight: '30.7dvh', maxHeight: '30.7dvh'}}>
                    No data found.
                </div>
            )
        }
        if (isMobileView) {
            return (
                <div>
                    <DLTable
                        id={'selected-list'}
                        headerData={[]}
                        tableData={tableData}
                        tableMaxHeight={'auto'}
                        tableMinHeight={'auto'}
                        isRowClickable={false}
                        isShowPagination={false}
                        isShowTotalCount={true}
                        cellConfig={existingPortFolioEditCellConfigXSView}
                    />
                </div>
            )
        }
        return (
            <div>
                <DLTable
                    id={'selected-list'}
                    headerData={isFromClientView ? existingPortFolioClientHeaderData: existingPortFolioHeaderData}
                    tableData={tableData}
                    tableMaxHeight={'28dvh'}
                    tableMinHeight={'28dvh'}
                    isRowClickable={false}
                    isShowPagination={false}
                    isShowTotalCount={true}
                    isDynamicHeight={isFromClientView}
                    cellConfig={isFromClientView ? existingPortFolioClientEditCellConfig : existingPortFolioEditCellConfig}
                />
            </div>
        )
    };

    const renderExistingPortfolioHeading = () =>{
        const {totalInvestments} = props;
        return(
            <div className={'d-flex justify-content-between align-items-center p-3' + (isFromClientView ? ' border-bottom' : '')}>
                <div>
                    <div className='d-flex align-items-center'>
                        <DLText
                            id={'existing-portfolio'}
                            type={'normal'}
                            text={'Existing Portfolio'}
                            fontSize={'lg'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                            isInline={true}
                        />
                        {isFromClientView && <div className="d-flex align-items-center badge-red ms-1">
                            <span style={{color: appTheme.appColor.white}}>Experimental</span>
                        </div>}

                    </div>
                    {!isFromClientView && <div>
                        <DLText
                            id={'select-funds-to-redeem'}
                            type={'normal'}
                            text={subTextLabel}
                            fontSize={'sm'}
                            fontWeight={'normal'}
                            fontColor={'black'}
                            marginBottom={"none"}
                            isInline={true}
                        />
                    </div>}

                </div>
                {!isEmpty(existingPortfolio) && (
                    <div className={"ms-3"}>
                        <DLText
                            id={'total-investments-title'}
                            type={'normal'}
                            text={'Total Investment: '}
                            fontSize={'md'}
                            fontWeight={'normal'}
                            fontColor={'black'}
                            marginBottom={"none"}
                            isInline={true}
                        />
                        <DLText
                            id={'total-investments-amount'}
                            type={'normal'}
                            text={isEmpty(totalInvestments) ? '-' : numberWithCommas(convertAmountInLakhAndCrore(totalInvestments))}
                            fontSize={'md'}
                            fontWeight={'bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                            isInline={true}
                        />
                    </div>
                )}
            </div>
        )
    };

    const renderExistingPortfolio = () => {
        return (
            <div className="bg-white border-rounded h-100">
                {renderExistingPortfolioHeading(existingPortfolio)}
                <div className={isFromClientView ? 'px-3 pt-3' : 'px-3'} style={{height: isFromClientView ? 'max-content' : '31dvh'}}>
                    {renderSchemeTable()}
                </div>
                {!isEmpty(existingPortfolio) && (
                    <div className={'px-3 pb-3 d-flex justify-content-end'}>
                        <DLButton
                            id={'add-to-list'}
                            label={'Add to List'}
                            buttonSize={'sm'}
                            fontSize={'sm'}
                            sideMargin={'none'}
                            onClick={() => handleAddToListButton()}
                            isDisabled={isAddToListButtonDisabled}
                            hidden={isFromClientView}
                        />
                    </div>
                )}
            </div>
        )
    };

    const renderMobileViewExistingPortfolio = () => {
        const {totalInvestments} = props;

        return (
            <div className="bg-white py-2">
                <div>
                    <div className={'border-bottom pb-2 px-3'}>
                        <div className='d-flex align-items-center'>
                            <DLText
                                id={'existing-portfolio'}
                                type={'normal'}
                                text={'Existing Portfolio'}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                fontColor={'black'}
                                marginBottom={"none"}
                                isInline={true}
                            />
                            {isFromClientView && <div className="d-flex align-items-center badge-red ms-1">
                                <span style={{color: appTheme.appColor.white}}>Experimental</span>
                            </div>}

                        </div>
                        {!isFromClientView && <div className={''}>
                            <DLText
                                id={'frequency'}
                                type={'normal'}
                                text={subTextLabel}
                                fontSize={'sm'}
                                fontWeight={'normal'}
                                fontColor={'black'}
                                marginBottom={"none"}
                            />
                        </div>}
                    </div>
                    {!isEmpty(existingPortfolio) && (
                        <div className={"border-bottom px-3 py-2"}>
                            <DLText
                                id={'duration'}
                                type={'normal'}
                                text={'Total Investment: '}
                                fontSize={'md'}
                                fontWeight={'normal'}
                                fontColor={'black'}
                                marginBottom={"none"}
                                isInline={true}
                            />
                            <DLText
                                id={'frequency'}
                                type={'normal'}
                                text={isEmpty(totalInvestments) ? '-' : numberWithCommas(convertAmountInLakhAndCrore(totalInvestments))}
                                fontSize={'md'}
                                fontWeight={'bold'}
                                fontColor={'black'}
                                marginBottom={"none"}
                                isInline={true}
                            />
                        </div>
                    )}
                </div>
                <div>
                    {renderSchemeTable()}
                </div>
                <div
                    className={'border-bottom px-3 pb-2 d-flex justify-content-end ' + ((isMDView || isSMView) ? 'pt-2' : 'pt-1')}>
                    <DLButton
                        id={'add-to-list'}
                        label={'Add to List'}
                        buttonSize={'sm'}
                        fontSize={'sm'}
                        sideMargin={'none'}
                        onClick={() => handleAddToListButton()}
                        isDisabled={isAddToListButtonDisabled}
                        hidden={isFromClientView}
                    />
                </div>
            </div>
        )
    };


    return (
        <div>
            {isMobileView ? renderMobileViewExistingPortfolio() : renderExistingPortfolio()}
        </div>
    );
};

export default ExistingPortfolioView;
