import {connect} from "react-redux";
import React, {useEffect, useState} from "react";

import store from "../../../../../../store";
import {APP_CONST} from "../../../../../../AppComponents/AppConstants";
import {isEmpty} from "../../../../../../utils/validations";
import {
    getTransactionDetails,
    serachPurchaseProducts,
    submitFundRequest
} from "../../../../FTAction";
import {showToastMessage} from "../../../../../../AppComponents/AppActions";
import {numberWithCommas} from "../../../../../../utils/helper";
import SwitchTransactionView from "./SwitchTransactionView";
import {txnRequestTypes} from "../../../../../../clientRequest/ClientRequestConstants";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";

const SwitchTransaction = (props) => {
    const [switchTXNState, setSwitchTXNState] = useState({
        existingPortfolio: [],
        selectedScheme: [],
        redemptionDetails: [],
        totalRedeemAmount: 100,
        UIState: APP_CONST.CONTENT_AVAILABLE,
        products: [],
        selectedId: '',
        isDoneClicked: false,
        isOpenDiscardModal: false,
        isShowLoader: false,
        isShowInlineLoader: false,
        totalSelectedAmount: '',
        isAddToListButtonDisabled: false,
        responseErrors: ''
    });

    const [productState, setProductState] = useState({
        searchKeyword: '',
        products: [],
        isShowInlineLoader: false,
        searchFundError: '',
        addedFund: '',
        productModalError: '',
        targetSchemeName: '',
        targetSchemeId: '',
        switchAllowedCode: '',
        reinvestTag: ''
    });

    useEffect(() => {
        let mode =  props?.location?.state?.mode;
        if (mode === 'EDIT') {
            getTransactionData()
        }
    }, []);

    useEffect(() => {
        isSchemeFieldDisabled();
    }, [switchTXNState.selectedScheme]);

    const getClientRequestId = () => {
        return parseInt(props.match?.params?.clientRequest);
    };

    const getTransactionRequestId = () => {
        return props.match?.params?.transactionId
    };

    const setTransactionData = (selectedPortfolio, headerData, resFrequency) => {
        let allFunds = [];
        let response = selectedPortfolio?.funds;

        if (response?.length === 0 || isEmpty(response)) {
            setSwitchTXNState((prevState) => ({
                ...prevState,
                selectedScheme: [],
                UIState: APP_CONST.CONTENT_UNAVAILABLE
            }));
            return;
        }

        response?.map((selectedFund) => {
            let existingPortfolioDetails = selectedFund?.submissionDetails;
            allFunds.push({
                redeemUnits: null,
                redeemAmount: selectedFund?.value || null,
                folioId: selectedFund?.folioNumber,
                productId: selectedFund?.sourceProductId || '',
                fundInvestmentId: existingPortfolioDetails?.fundInvestmentId || '',
                scheme: selectedFund?.SourceProductName || '',
                sourceScheme: selectedFund?.SourceProductName || '',
                sourceSchemeId: selectedFund?.sourceProductId || '',
                targetScheme: selectedFund?.targetProductName || '',
                targetSchemeId: selectedFund?.targetProductId || '',
                isTargetSchemeSelected: true,
                errors: [],
                folioNo: selectedFund?.folioNumber,
                nav: existingPortfolioDetails?.nav,
                units: existingPortfolioDetails?.availableUnits,
                navAsOfDate: existingPortfolioDetails?.navAsOfDate,
                valuation: existingPortfolioDetails?.valuation,
                AMCCode: selectedFund?.AMCCode,
                UIState: APP_CONST.CONTENT_AVAILABLE
            })
        });

        setProductState((prevState) => ({
            ...prevState,
            searchKeyword: '',
            products: [],
            targetSchemeName: response[0]?.targetProductName,
            targetSchemeId: response[0]?.targetProductId,
            switchAllowedCode: '',
            reinvestTag: ''
        }));

        setSwitchTXNState((prevState) => ({
            ...prevState,
            selectedScheme: allFunds,
            UIState: APP_CONST.CONTENT_AVAILABLE
        }))
    };

    const getTransactionData = () => {
        const txnRequestId = getTransactionRequestId();
        const clientRequestId = getClientRequestId();
        getTransactionDetails(txnRequestId).then(transactionsRes => {
            if (transactionsRes?.success) {
                let requestData = transactionsRes.data;
                setTransactionData(requestData);
            }
        })
    };

    const redirectScreen = (action, data) => {
        let transactionRequestId = getTransactionRequestId();
        let clientRequestId = getClientRequestId();

        if (action === 'DISCARD') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/financial-transaction/' + transactionRequestId);
        }
    };

    const checkIsChecked = (id) => {
        const {selectedId} = switchTXNState;
        return selectedId === id;
    };

    const isCheckboxDisabled = () => {
        const {selectedScheme} = switchTXNState;
        return !isEmpty(selectedScheme);
    };

    const handleCheckBox = (id) => {
        const {selectedId, selectedScheme} = switchTXNState;

        if (selectedId === id) {
            setSwitchTXNState(prevState => ({
                ...prevState,
                selectedId: '',
                isAddToListButtonDisabled: isAddToListDisabled(selectedScheme)
            }));
        } else {
            setSwitchTXNState((prevState) => ({
                ...prevState,
                selectedId: id,
                isAddToListButtonDisabled: isAddToListDisabled(selectedScheme)
            }));
        }
    };

    const removeValidatedField = (selectedScheme, fieldName) => {
        return selectedScheme?.map((scheme) => {
                let filteredErrors;
                let tempErrors = scheme.errors;

                if(tempErrors?.length > 0) {
                    if(typeof tempErrors[0] === 'string') {
                        filteredErrors = []
                    } else {
                        filteredErrors = tempErrors?.filter((error) => error?.type !== fieldName);
                    }
                } else {
                    filteredErrors = []
                }

                return {
                    ...scheme,
                    errors: filteredErrors,
                }
            }
        );
    }

    const validateFields = (selectedScheme) => {
        return selectedScheme?.map((scheme) => {

                let schemeErrors = [];

                //targetScheme Validation
                let targetErrorMessage = "";
                if (isEmpty(productState?.targetSchemeId)) {
                    targetErrorMessage = 'Please select target scheme'
                }

                if (!isEmpty(productState?.targetSchemeId)) {
                    if (productState?.targetSchemeId === scheme.productId) {
                        targetErrorMessage = 'Select different scheme';
                    } else if (productState?.switchAllowedCode === 'N') {
                        targetErrorMessage = 'Switch is not allowed for this scheme';
                    }
                }

                if (targetErrorMessage.length > 0) {
                    let errorObject = {
                        "type": "targetScheme",
                        "errorMessage": targetErrorMessage,
                    }
                    schemeErrors.push(errorObject);
                }

                //redeemAmount Validation
                let amountErrorMessage = "";
                if (isEmpty(scheme.redeemAmount)) {
                    amountErrorMessage = 'Please enter amount';
                } else if (scheme.redeemAmount == 0) {
                    amountErrorMessage = 'Amount should not be 0';
                } else if (scheme.redeemAmount > scheme.valuation) {
                    amountErrorMessage = 'Amount should be less than ' + numberWithCommas(scheme.valuation);
                }

                if (amountErrorMessage.length > 0) {
                    let errorObject = {
                        "type": "redeemAmount",
                        "errorMessage": amountErrorMessage,
                    }
                    schemeErrors.push(errorObject);
                }

                return {
                    ...scheme,
                    errors: schemeErrors,
                };
            }
        );
    };

    const handleFundsWithErrors = (errors, selectedScheme) => {
        if (isEmpty(errors) || isEmpty(selectedScheme)) {
            return selectedScheme;
        }

        let allSelectedScheme = selectedScheme.map(fund => {
            let productId = productState?.targetSchemeId;
            let backEndError = errors.find((obj) => {
                return obj?.targetFundId === productId
            });
            return {
                ...fund,
                errors: isEmpty(backEndError) ? [] : backEndError?.errors
            };
        });
        return allSelectedScheme;
    };

    const submitTransactionDetails = () => {
        const {selectedScheme} = switchTXNState;
        let transactionRequestId = getTransactionRequestId();
        let updateSchemeWithErrors = validateFields(selectedScheme);

        setSwitchTXNState((prevState) => ({
            ...prevState,
            selectedScheme: isEmpty(updateSchemeWithErrors) ? [] : updateSchemeWithErrors,
            isDoneClicked: true
        }));

        const totalErrors = updateSchemeWithErrors
            .map(item => item.errors.length) // Extract the amount values
            .reduce((sum, totalCount) => sum + totalCount, 0);

        if (totalErrors > 0) {
            store.dispatch(showToastMessage('warning', totalErrors === 1 ? 'Error exists, changes are not saved.' : 'Errors exist, changes are not saved.'));
            return;
        }

        setSwitchTXNState((prevState) => ({
            ...prevState,
            isShowLoader: true,
        }));

        let payload = {
            transactionRequestId: parseInt(transactionRequestId),
            txnRequestType: txnRequestTypes.SWITCH,
            products: getProducts(updateSchemeWithErrors),
        };

        submitFundRequest(payload, true)
            .then(res => {
                if (res.success) {
                    setSwitchTXNState((prevState) => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                    redirectScreen('DISCARD')
                } else {

                    setSwitchTXNState((prevState) => ({
                        ...prevState,
                        responseErrors: typeof res?.__error === 'string' ? res?.__error : '',
                        selectedScheme: typeof res?.__error === 'object'? handleFundsWithErrors(res?.__error, updateSchemeWithErrors) : updateSchemeWithErrors,
                        isShowLoader: false,
                    }))
                }
            })
    };

    const getProducts = (updateSchemeWithErrors) => {
        let products = [];

        if (isEmpty(updateSchemeWithErrors)) {
            return products;
        }

        updateSchemeWithErrors?.map((selectedScheme) => {
            products.push({
                productId: selectedScheme?.productId,
                folioNumber: isEmpty(selectedScheme?.folioNo) ? '' : selectedScheme?.folioNo,
                targetFundId: productState?.targetSchemeId,
                type: "AMOUNT",
                value: Number(selectedScheme?.redeemAmount),
                reinvestFlag: productState?.reinvestTag !== "N",
                submissionDetails: {
                    fundId: selectedScheme?.productId,
                    fundInvestmentId: selectedScheme?.productId,
                    folioNumber: isEmpty(selectedScheme?.folioNo) ? '' : selectedScheme?.folioNo,
                    availableUnits: selectedScheme?.units,
                    nav: selectedScheme?.nav,
                    navAsOfDate: selectedScheme?.date,
                    valuation: selectedScheme?.valuation
                }
            })
        });
        return products;
    };

    const isSchemeFieldDisabled = () => {
        const {existingPortfolio, selectedScheme} = switchTXNState;
        const updatedPortfolio = existingPortfolio?.map(portfolio => {
            const isMatch = selectedScheme?.some(scheme => scheme.id === portfolio.id);
            return {
                ...portfolio,
                isDisabled: isMatch,
            };
        });

        setSwitchTXNState(prevState => ({
            ...prevState,
            existingPortfolio: updatedPortfolio,
        }));
    };

    const isAddToListDisabled = (selectedScheme) => {
        const {selectedId} = switchTXNState;

        return !isEmpty(selectedScheme) && !isEmpty(selectedId);
    };

    const openDiscardModal = () => {
        setSwitchTXNState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: true
        }))
    };

    const closeAddDiscardModal = () => {
        setSwitchTXNState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: false
        }))
    };

    const handleAddToListButton = (existingPortfolio) => {
        const updatedSelectedScheme = existingPortfolio.map(portfolio => {
            return {
                ...portfolio,
                scheme: portfolio?.productDetails?.description || '',
                redeemUnits: null,
                redeemAmount: null,
                productId: portfolio?.productDetails?.id,
                fundInvestmentId: portfolio?.id,
                folioId: portfolio?.id,
                valuation: portfolio?.valuation,
                nav: portfolio?.nav
            };
        });

        const isAddToListButtonDisabled = isAddToListDisabled(updatedSelectedScheme);
        setSwitchTXNState(prevState => ({
            ...prevState,
            selectedScheme: updatedSelectedScheme,
            isAddToListButtonDisabled: isAddToListButtonDisabled,
        }));
    };

    const handleChange = (value, productId, name) => {
        const {selectedScheme} = switchTXNState;
        let newValue;

        if (value === '.') {
            newValue = '0.';
        } else {
            newValue = Number(value.replace(/,/g, ""));
        }

        setSwitchTXNState((prevState) => ({
            ...prevState,
            isDoneClicked: false,
        }));

        const updatedSelectedScheme = selectedScheme?.map((scheme) => {
            if (productId === scheme?.id) {
                let redeemAmount;
                if (name === 'redeemAmount') {
                    redeemAmount = newValue;
                    return {
                        ...scheme,
                        redeemAmount: isEmpty(newValue) ? '' : newValue,
                    };
                }

                return {
                    ...scheme,
                    [name]: value,
                };
            }

            return scheme;
        });

        const updateSchemeWithErrors = removeValidatedField(updatedSelectedScheme, "redeemAmount");

        setSwitchTXNState((prevState) => ({
            ...prevState,
            selectedScheme: isEmpty(updateSchemeWithErrors) ? [] : updateSchemeWithErrors,
            isShowLoader: false,
        }));
    };

    const handleDelete = (id) => {
        const {selectedScheme} = switchTXNState;
        const updatedSelectedScheme = selectedScheme.filter(scheme => id !== scheme.id);

        setSwitchTXNState(prevState => ({
            ...prevState,
            selectedScheme: updatedSelectedScheme,
            isAddToListButtonDisabled: isAddToListDisabled(updatedSelectedScheme),
        }));
        setProductState((prevState) => ({
            ...prevState,
            searchKeyword: '',
            products: [],
            targetSchemeName: '',
            targetSchemeId: '',
            switchAllowedCode: '',
            reinvestTag: ''
        }));
        handleCheckBox(id);
    };

    let searchProductAPI = (keyword, code) => {
        let payload = {
            keyword: keyword,
            limitCount: 30,
            AMCCode: code
        };


        serachPurchaseProducts(payload).then(res => {
            setProductState((prevState) => ({
                ...prevState,
                products: res.data?.products,
                isShowInlineLoader: false,
                targetSchemeName: '',
                targetSchemeId: '',
                switchAllowedCode: '',
                reinvestTag: ''
            }))
        });
    };

    const searchProduct = (keyword, code) => {

        if (isEmpty(keyword)) {
            setProductState((prevState) => ({
                ...prevState,
                searchKeyword: '',
                products: [],
                targetSchemeName: '',
                targetSchemeId: '',
                switchAllowedCode: '',
                reinvestTag: ''
            }));
            return
        }

        setProductState((prevState) => ({
            ...prevState,
            searchKeyword: keyword,
            isShowInlineLoader: true,
            targetSchemeName: '',
            targetSchemeId: '',
            switchAllowedCode: '',
            reinvestTag: ''
        }));

        searchProductAPI(keyword, code)

    };

    const changeTargetScheme = (nameValue) => {
        const {selectedScheme} = switchTXNState;

        const selectedProduct = productState.products.find((product) => product.id === nameValue);

        if (!selectedProduct) {

            setProductState((prevState) => ({
                ...prevState,
                searchKeyword: '',
                products: [],
                targetSchemeName: '',
                targetSchemeId: '',
                switchAllowedCode: '',
                reinvestTag: ''
            }));

            const updatedSchemeWithErrors = removeValidatedField(selectedScheme, "targetScheme");

            setSwitchTXNState((prevState) => ({
                ...prevState,
                selectedScheme: updatedSchemeWithErrors,
                totalRedeemAmount: 100,
                products: [],
                isDoneClicked: false,
                isOpenDiscardModal: false,
                isShowLoader: false,
                isShowInlineLoader: false,
                totalSelectedAmount: '',
                isAddToListButtonDisabled: false,
                responseErrors: '',
            }));
            return;
        }

        setProductState((prevState) => ({
            ...prevState,
            targetSchemeName: selectedProduct.description,
            targetSchemeId: nameValue,
            switchAllowedCode: selectedProduct.switchAllowedCode,
            reinvestTag: selectedProduct.reinvestTag,
        }));

        const updatedSchemeWithErrors = removeValidatedField(selectedScheme, "targetScheme");

        setSwitchTXNState((prevState) => ({
            ...prevState,
            selectedScheme: updatedSchemeWithErrors,
            isDoneClicked: false,
        }));
    };

    const setUiState = () => {
        setSwitchTXNState((prevState) => ({
            ...prevState,
            UIState: APP_CONST.CONTENT_AVAILABLE
        }))
    };

    const{UIState} = switchTXNState;

    if(isEmpty(UIState)){
        return (
            <DLLoader type={"screen"} isVisible={true}/>
        );
    }


    return (
        <SwitchTransactionView
            {...props}
            {...switchTXNState}
            {...productState}
            checkIsChecked={checkIsChecked}
            submitTransactionDetails={submitTransactionDetails}
            openDiscardModal={openDiscardModal}
            closeAddDiscardModal={closeAddDiscardModal}
            handleChange={handleChange}
            handleDelete={handleDelete}
            handleAddToListButton={handleAddToListButton}
            isCheckboxDisabled={isCheckboxDisabled}
            handleCheckBox={handleCheckBox}
            redirectScreen={redirectScreen}
            searchProduct={searchProduct}
            setProductState={setProductState}
            changeTargetScheme={changeTargetScheme}
            setUiState={setUiState}
        />
    )
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isSMView: state.appState.deviceInfo.isSMView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(SwitchTransaction);
