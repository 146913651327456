import {connect} from "react-redux";
import React, {useEffect, useState} from "react";

import {APP_CONST} from "../../../../../../AppComponents/AppConstants";

import {getExistingPortfolio} from "../../../../FTAction";
import {getClientRequestById} from "../../../../../../clientRequest/ClientRequestsActions";
import {isEmpty} from "../../../../../../utils/validations";

import ExistingPortfolioView from "./ExistingPortfolioView";

const ExistingPortfolio = (props) => {

    const{setUiState, setSelectedSchemeData, selectedScheme, isFromClientView} = props;
    const [existingPortFolioState, setExistingPortFolioState] = useState({
        existingPortfolio: [],
        selectedScheme: [],
        totalInvestments: '',
        UIState: '',
        selectedId: '',
        isShowLoader: false,
        isShowInlineLoader: false,
        totalSelectedAmount: '',
        isAddToListButtonDisabled: true,
        isCheckboxDisabled: false,
        isFromClientView: isFromClientView,
    });

    useEffect(() => {
        if(isFromClientView){
            getClientFolioDetails(props.accountId)
        }else{
            getExistingPortfolioDetails();
        }
    }, []);

    const getClientFolioDetails = (accountId) => {

        getExistingPortfolio(accountId).then(res => {
            if (res?.success) {
                setExistingPortFolioState(prevState => ({
                    ...prevState,
                    existingPortfolio: res?.data?.fundInvestments,
                    totalInvestments: isEmpty(res?.data?.totalValuation)? '' : (res?.data?.totalValuation),
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                }));
                if(!isFromClientView){
                    setUiState();
                }
            } else {
                setExistingPortFolioState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                }));
                if(!isFromClientView){
                    setUiState();
                }
            }
        })
    }

    useEffect(() => {
        if(isEmpty(selectedScheme)){
            setExistingPortFolioState((prevState) => ({
                ...prevState,
                isAddToListButtonDisabled: true,
                isCheckboxDisabled: false,
                selectedId: ''
            }));
        }else{
            setExistingPortFolioState((prevState) => ({
                ...prevState,
                isAddToListButtonDisabled: true,
                isCheckboxDisabled: true,
                selectedId: selectedScheme[0].fundInvestmentId
            }));
        }
    }, [selectedScheme]);

    const getExistingPortfolioDetails = () => {

        const clientRequestId = getClientRequestId();

        setExistingPortFolioState((prevState) => ({
            ...prevState,
            isShowLoader: true,
        }));

        getClientRequestById(clientRequestId).then(requestData => {
            if (requestData?.success) {
                let accountId = requestData?.data?.accountId;
                getClientFolioDetails(accountId)
            } else {
                setExistingPortFolioState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                }));
            }
        })

    };

    const getClientRequestId = () => {
        return parseInt(props.match?.params?.clientRequest);
    };

    const handleCheckBox = (id) => {
        const {selectedId} = existingPortFolioState;

        if (selectedId === id) {
            setExistingPortFolioState(prevState => ({
                ...prevState,
                selectedId: '',
                isCheckboxDisabled: false,
                isAddToListButtonDisabled: true
            }));
        } else {
            setExistingPortFolioState((prevState) => ({
                ...prevState,
                selectedId: id,
                isCheckboxDisabled: false,
                isAddToListButtonDisabled: false
            }));
        }
    };

    const handleAddToListButton = () => {
        const {existingPortfolio, selectedId} = existingPortFolioState;

        if (!selectedId) {
            return;
        }

        const updatedSelectedScheme = existingPortfolio
            .filter(portfolio => selectedId === portfolio?.id);

        setExistingPortFolioState((prevState) => ({
            ...prevState,
            isAddToListButtonDisabled: true,
            isCheckboxDisabled: true
        }));
        setSelectedSchemeData(updatedSelectedScheme);
    };

    const checkIsChecked = (id) => {
        const {selectedId} = existingPortFolioState;
        return selectedId === id;
    };

    return (
        <ExistingPortfolioView
            {...props}
            {...existingPortFolioState}
            handleAddToListButton={handleAddToListButton}
            handleCheckBox={handleCheckBox}
            checkIsChecked={checkIsChecked}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(ExistingPortfolio);
