import React from "react";

import {isEmpty} from "../../../../../../core/components/DLComponentHelper";
import {convertUTCToLocalTime, numberWithCommas} from "../../../../../../utils/helper";

import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";


const TxnTableSTPView = (props) => {

    const {
        isMobileView,
        investmentDetails,
        txnStage,
        txnData,
        handleAddOrEditTransactionDetails,
        isManagementUser,
        txnDetails,
        getFrequency,
        frequencies
    } = props;

    const renderEmptyUi = () => {
        return (
            <div>
                <DLText id={''}
                        text={'No schemes have been added for STP.'}
                        fontWeight={'normal'}
                        fontSize={"sm"}
                />
                {!isManagementUser && <div className='d-flex justify-content-center'>
                    <DLButton id={'add-button'}
                              onClick={() => handleAddOrEditTransactionDetails("ADD")}
                              hidden={txnStage !== "DRAFT"}
                              label={'Add'}/>
                </div>}
            </div>
        )
    };

    const renderHeadings = () => {
        let frequency = getFrequency(txnDetails?.frequency, frequencies);
        return (
            <div className={"py-2 border-bottom row px-0 m-0"}>
                <div className='data-card-label col-3'>
                    <DLText id={'scheme'}
                            text={"Source Scheme"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-2'>
                    <DLText id={'folio-no'}
                            text={"Folio No."}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-3'>
                    <DLText id={'scheme'}
                            text={"Target Scheme"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-1 text-start'>
                    <DLText id={'stp-date'}
                            text={frequency === "Weekly"? "STP Day" : "STP Date"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-2'>
                    <DLText id={'start-date-end-date'}
                            text={"Start Date to End Date"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-1 text-end'>
                    <DLText id={'transfer-amount'}
                            text={"Transfer Amount"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
            </div>
        )
    };

    const renderDateOrDay = (item) =>{
        let frequency = getFrequency(txnDetails?.frequency, frequencies);
        let selectedFrequencyDetails = item?.frequencyDetails;
        if(isEmpty(selectedFrequencyDetails)){
            return "-";
        }
        if(frequency === "Weekly"){
            return selectedFrequencyDetails?.weekDay;
        }
        if(frequency === "Monthly"){
            return selectedFrequencyDetails?.dayOfMonth;
        }
    };

    const renderData = () => {
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"py-2 row px-0 m-0"}
                                 key={index}>
                                <div className='data-card-label col-3'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item.sourceProductName) ? '-' : item.sourceProductName}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='data-card-label col-2'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item.folioNumber) ? '-' : item.folioNumber}
                                                fontSize={"xs"}
                                        />
                                    </div>

                                </div>
                                <div className='data-card-label col-3'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item.targetProductName) ? '-' : item.targetProductName}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='data-card-label col-1'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={renderDateOrDay(item)}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='data-card-label col-2'>
                                    <div className='d-flex align-items-center h-100'>
                                        <div>
                                            <DLText id={''}
                                                    text={isEmpty(item.startDate) ? '-' : convertUTCToLocalTime(item.startDate, 'DD-MM-YYYY') + ' to '}
                                                    fontWeight={"normal"}
                                                    fontSize={"xs"}
                                            />
                                            {item.isForMaxPeriod?
                                                <DLText id={''}
                                                        text={'Maximum Period'}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                /> :
                                                <DLText id={''}
                                                        text={isEmpty(item.endDate) ? '-' : convertUTCToLocalTime(item.endDate, 'DD-MM-YYYY')}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                />}
                                        </div>
                                    </div>
                                </div>
                                <div className='data-card-label col-1'>
                                    <div className='d-flex align-items-center justify-content-end h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item.value) ? '-' : numberWithCommas(item.value)}
                                                fontWeight={"bold"}
                                                fontColor={"black"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderTotalCount = () => {
        return (
            <div className={"py-2 border-top row px-0 m-0"}>
                <div className='data-card-label col-10'/>
                <div className='data-card-label col-2 d-flex justify-content-end'>
                    <div className={'text-end'} style={{maxWidth: "150px"}}>
                        <DLText id={'total-redemption'}
                                text={isEmpty(txnData.totalTransferAmount) ? '-' : numberWithCommas(txnData.totalTransferAmount)}
                                fontWeight={"bold"}
                                marginBottom={"none"}
                                fontSize={"sm"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileData = () => {
        let frequency = getFrequency(txnDetails?.frequency, frequencies);
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"p-3 m-0"}
                                 key={index}>
                                <div className='data-card-label pb-3'>
                                    <div>
                                        <DLText id={''}
                                                text={'Source Scheme'}
                                                fontColor={'grayDark'}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                                isInline={false}
                                                marginBottom={"none"}
                                        />
                                        <DLText id={''}
                                                text={item?.sourceProductName}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                                isInline={false}
                                        />
                                    </div>
                                    <div className='d-flex'>
                                        <DLText id={''}
                                                text={'Folio No.'}
                                                fontColor={'grayDark'}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                                isInline={true}
                                                marginBottom={"none"}
                                        />
                                        <div className='ms-1'>
                                            <DLText id={''}
                                                    text={isEmpty(item?.folioNumber) ? "-" : item?.folioNumber}
                                                    fontWeight={"normal"}
                                                    fontSize={"xs"}
                                                    isInline={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='data-card-label pb-3'>
                                    <div>
                                        <DLText id={''}
                                                text={'Target Scheme'}
                                                fontColor={'grayDark'}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                                marginBottom={"none"}
                                        />
                                        <DLText id={''}
                                                text={item?.targetProductName}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='data-card-label col-4'>
                                        <div>
                                            <DLText id={''}
                                                    text={'Duration'}
                                                    fontColor={'grayDark'}
                                                    fontWeight={"normal"}
                                                    fontSize={"xs"}
                                                    marginBottom={"none"}
                                            />
                                            <div className=''>
                                                <DLText id={''}
                                                        text={isEmpty(item?.startDate) ? '-' : convertUTCToLocalTime(item?.startDate, 'DD-MM-YYYY') + ' to '}
                                                        fontWeight={"normal"}
                                                        marginBottom={"none"}
                                                        fontSize={"xs"}
                                                />
                                                {item.isForMaxPeriod?
                                                    <DLText id={''}
                                                            text={'Maximum Period'}
                                                            fontWeight={"normal"}
                                                            fontSize={"xs"}
                                                    /> :
                                                    <DLText id={''}
                                                            text={isEmpty(item?.endDate) ? '-' : convertUTCToLocalTime(item?.endDate, 'DD-MM-YYYY')}
                                                            fontWeight={"normal"}
                                                            fontSize={"xs"}
                                                    />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='data-card-label col-4'>
                                        <div>
                                            <DLText id={''}
                                                    text={frequency === "Weekly"? 'STP Day' : 'STP Date'}
                                                    fontColor={'grayDark'}
                                                    fontWeight={"normal"}
                                                    fontSize={"xs"}
                                                    marginBottom={"none"}
                                            />
                                            <DLText id={''}
                                                    text={renderDateOrDay(item)}
                                                    fontWeight={"normal"}
                                                    fontSize={"xs"}
                                            />
                                        </div>
                                    </div>
                                    <div className='data-card-label col-4'>
                                        <div className={'text-start'} style={{maxWidth: "150px"}}>
                                            <div>
                                                <DLText id={''}
                                                        text={'Transfer Amount'}
                                                        fontColor={'grayDark'}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                        marginBottom={"none"}
                                                />
                                                <DLText id={''}
                                                        text={isEmpty(item?.value) ? '-' : numberWithCommas(item?.value)}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderMobileInvestmentDetails = () => {
        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded h-100">
                    {renderFrequencyDetails()}
                    <div className='d-flex justify-content-center align-items-center black-border-bottom h-100'
                         style={{minHeight: '300px'}}
                    >
                        <div className='text-center my-5'>
                            {renderEmptyUi()}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded">
                <div className='d-flex justify-content-between align-items-center p-3 border-top-black border-bottom-black'>
                    {renderFrequencyDetails()}
                    <i className="fa-solid fa-pencil"
                       hidden={txnStage !== "DRAFT" || isManagementUser}
                       onClick={() => handleAddOrEditTransactionDetails("EDIT")}/>
                </div>
                <div className='p-0 w-100 h-100'>
                    <div className="h-100">
                        {renderMobileData()}
                        {renderMobileTotalCount()}
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileTotalCount = () => {
        return (
            <div className={"py-3 black-border-bottom row px-0 m-0 bg-white black-border-top"}>
                <div className='data-card-label col-8'/>
                <div className='data-card-label col-3 d-flex align-items-center justify-content-start'>
                    <div className={'text-start'}>
                        <DLText id={''}
                                fontWeight={"bold"}
                                marginBottom={"none"}
                                text={isEmpty(txnData.totalTransferAmount) ? '-' : numberWithCommas(txnData.totalTransferAmount)}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderFrequencyDetails = () => {
        return (
            <div className='d-flex'>
                <div className='pe-4'>
                    <DLText
                        id={'frequency-title'}
                        type={'normal'}
                        text={`Frequency: `}
                        fontSize={'md'}
                        fontWeight={'normal'}
                        fontColor={'black'}
                        marginBottom={"none"}
                        isInline={true}
                    />
                    <DLText
                        id={'frequency-value'}
                        type={'normal'}
                        text={getFrequency(txnDetails?.frequency, frequencies)}
                        fontSize={'md'}
                        fontWeight={'bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                        isInline={true}
                    />
                </div>
            </div>
        )
    };

    const renderInvestmentDetails = () => {

        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded h-100 p-3">
                    <div className='pb-3'>
                        {renderFrequencyDetails()}
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='panel rounded border text-center flex-grow-1'>
                            {renderEmptyUi()}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3 ">
                <div className='d-flex justify-content-between align-items-center column-gap-4'>
                    <div>
                        {renderFrequencyDetails()}
                    </div>
                    <DLButton
                        id={'edit-transaction'}
                        label={'Edit'}
                        hidden={txnStage !== "DRAFT" || isManagementUser}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => handleAddOrEditTransactionDetails("EDIT")}/>
                </div>
                <div className='pt-3'>
                    <div className='p-0 w-100 border-rounded panel h-100'>
                        <div className="h-100 c">
                            {renderHeadings()}
                            {renderData()}
                            {renderTotalCount()}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className={'h-100'}>
            {isMobileView ? renderMobileInvestmentDetails() : renderInvestmentDetails()}
        </div>
    );
};

export default TxnTableSTPView;