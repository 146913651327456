import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import moment from "moment";

import store from "../../../../../../store";
import {APP_CONST} from "../../../../../../AppComponents/AppConstants";

import {isEmpty} from "../../../../../../utils/validations";
import {getTransactionDetails, selectProduct, serachPurchaseProducts, submitFundRequest} from "../../../../FTAction";
import {showToastMessage} from "../../../../../../AppComponents/AppActions";

import PurchaseSTPTransactionView from "./PurchaseSTPTransactionView";
import {getStpFrequency} from "../../../../../../clientRequest/ClientRequestsActions";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";

const PurchaseSTPTransaction = (props) => {
    const [STPState, setSTPState] = useState({
        existingPortfolio: [],
        selectedScheme: [],
        totalInvestments: 100,
        UIState: '',
        products: [],
        selectedId: '',
        isDoneClicked: false,
        isOpenDiscardModal: false,
        isShowLoader: false,
        isShowInlineLoader: false,
        totalSelectedAmount: '',
        isAddToListButtonDisabled: true,
        responseErrors: []
    });

    useEffect(() => {
        let mode = props?.location?.state?.mode;

        getTransactionData(mode)
    }, []);

    const getTransactionData = () => {
        const txnRequestId = getTransactionRequestId();
        getTransactionDetails(txnRequestId).then(transactionsRes => {
            if (transactionsRes?.success) {
                let requestData = transactionsRes.data;
                getStpFrequency().then(resFrequency => {
                    if (resFrequency?.success) {
                        setTransactionData(requestData, transactionsRes, resFrequency);
                }})
            }
        })
    };

    const setTransactionData = (selectedPortfolio, headerData, resFrequency) => {

        let allFunds = [];
        let response = selectedPortfolio?.funds;
        let frequencyLabel = isEmpty(resFrequency) ? '' : getFrequency(headerData?.data?.txnDetails?.frequency, resFrequency?.data?.frequencies)

        if (response?.length === 0 || isEmpty(response)) {
            setSTPState((prevState) => ({
                ...prevState,
                selectedScheme: [],
                frequency: frequencyLabel,
                UIState: APP_CONST.CONTENT_UNAVAILABLE
            }));
            return;
        }

        response?.map((selectedFund) => {
            let dateDropDownValues = setDropdown(selectedFund, frequencyLabel);
            let existingPortfolioDetails = selectedFund?.submissionDetails;
            allFunds.push({
                details: {
                    productLimits : selectedFund?.details.limits,
                    stpAllowed : selectedFund?.stpAllowedCode !== "N"
                },
                fundInvestmentId: existingPortfolioDetails.fundInvestmentId || '',
                sourceScheme: selectedFund?.sourceProductName || '',
                sourceSchemeId: selectedFund?.sourceProductId || '',
                sourceSchemeAmount: existingPortfolioDetails?.valuation,
                targetScheme: selectedFund?.targetProductName || '',
                targetSchemeId: selectedFund?.targetProductId || '',
                isTargetSchemeSelected: true,
                endDate: isEmpty(selectedFund?.endDate) ? '' : selectedFund?.endDate,
                startDate: isEmpty(selectedFund?.startDate) ? '' : selectedFund?.startDate,
                isMaxPeriodSelected: selectedFund?.isForMaxPeriod || false,
                stpDates: dateDropDownValues,
                selectedStpDate: frequencyLabel === "Weekly"? selectedFund?.frequencyDetails?.weekDay : selectedFund?.frequencyDetails?.dayOfMonth,
                transferAmount: selectedFund?.value,
                errors: [],
                folioNo: selectedFund?.folioNumber,
                nav: existingPortfolioDetails?.nav,
                units: existingPortfolioDetails?.availableUnits,
                navAsOfDate: existingPortfolioDetails?.navAsOfDate,
                valuation: existingPortfolioDetails?.valuation,
                AMCCode: selectedFund?.AMCCode,
                UIState: APP_CONST.CONTENT_AVAILABLE
            })

        });

        setSTPState((prevState) => ({
            ...prevState,
            selectedScheme: allFunds,
            frequency: frequencyLabel,
            UIState: APP_CONST.CONTENT_AVAILABLE

        }))
    };

    const getFrequency = (type, allFrequency) => {

        const status = allFrequency.find(status => status.code === type);
        return status?.description;
    };

    const getClientRequestId = () => {
        return parseInt(props.match?.params?.clientRequest);
    };

    const getTransactionRequestId = () => {
        return parseInt( props.match?.params?.transactionId);
    };

    const validateTransaction = (updateFundsWithErrors) => {
        let allErrors = [];
        updateFundsWithErrors?.map((selectedFund) => {
            let errors = isEmpty(selectedFund?.errors) ? [] : selectedFund?.errors;
            allErrors = [...allErrors, ...errors]
        });
        return allErrors;
    };

    const redirectScreen = (action) => {
        let transactionRequestId = getTransactionRequestId();
        let clientRequestId = getClientRequestId();

        if (action === 'DISCARD') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/financial-transaction/' + transactionRequestId);
        }
    };

    const handleFundsWithErrors = (errors, funds) => {
        if (isEmpty(errors) || isEmpty(funds)) {
            return funds;
        }
        let allFunds = funds.map(scheme => {
            let targetSchemeId = scheme?.targetSchemeId;
            let backEndError = errors.find((obj) => {
                return obj?.targetFundId === targetSchemeId
            });
            return {
                ...scheme,
                errors: isEmpty(backEndError) ? [] : backEndError?.errors
            };
        });
        return allFunds;
    };

    const openDiscardModal = () => {
        setSTPState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: true
        }))
    };

    const closeAddDiscardModal = () => {
        setSTPState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: false
        }))
    };

    const searchProduct = (keyword, AMCCode) => {

        if (isEmpty(keyword)) {
            setSTPState((prevState) => ({
                ...prevState,
                searchKeyword: '',
                products: []
            }));
            return
        }

        setSTPState((prevState) => ({
            ...prevState,
            searchKeyword: keyword,
            isShowInlineLoader: true,
        }));

        searchProductAPI(keyword, AMCCode)

    };

    let searchProductAPI = (keyword, AMCCode) => {
        let payload = {keyword: keyword, limitCount: 30, AMCCode: AMCCode};
        serachPurchaseProducts(payload).then(res => {
            setSTPState((prevState) => ({
                ...prevState,
                searchFundError: '',
                products: res.data?.products,
                productModalError: '',
                isShowInlineLoader: false,
            }))
        });
    };

    const handleDelete = () => {

        setSTPState(prevState => ({
            ...prevState,
            selectedScheme: [],
            isDoneClicked: false,
            responseErrors: []
        }));
    };

    const setUiState = () => {
        setSTPState((prevState) => ({
            ...prevState,
            UIState: APP_CONST.CONTENT_AVAILABLE
        }))
    };

    const setSelectedSchemeData = (portfolio) => {
        const updatedSelectedScheme = portfolio.map(scheme => {
            return {
                details: '',
                fundInvestmentId: scheme?.id || '',
                sourceScheme: scheme?.productDetails?.description || '',
                sourceSchemeId: scheme?.productDetails?.id || '',
                sourceSchemeAmount: scheme?.valuation,
                targetScheme: '',
                targetSchemeId: '',
                isTargetSchemeSelected: false,
                startDate: '',
                endDate: '',
                isMaxPeriodSelected: false,
                stpDates: [],
                selectedStpDate: '',
                transferAmount: '',
                errors: [],
                folioNo: scheme?.folioNo,
                nav: scheme?.nav,
                units: scheme?.units,
                navAsOfDate: scheme?.date,
                valuation: scheme?.valuation,
                AMCCode: scheme?.productDetails?.AMCCode
            }
        });

        setSTPState((prevState) => ({
            ...prevState,
            selectedScheme: updatedSelectedScheme
        }))
    };

    const addProduct = (id, index) => {
        const {products, selectedScheme, frequency} = STPState;

        const selectedProduct = products.find(item => id === item.id);
        let transactionRequestId = getTransactionRequestId();
        let productId = selectedProduct?.id;
        let productDetails = {};

        if(isEmpty(id)){
            productDetails = {
                details: {
                    productLimits : [],
                    stpAllowed : ''
                },
                targetScheme: '',
                targetSchemeId: '',
                isMaxPeriodSelected: false,
                isTargetSchemeSelected: false,
                stpDates: [],
                startDate: '',
                endDate: '',
                transferAmount: '',
                selectedStpDate: '',
            };
            setSelectedProductDetails(productDetails, selectedScheme, index);
            return;
        }

        selectProduct(transactionRequestId, productId).then(res => {
            if (res?.success) {
                let dateDropDownValues = setDropdown(res?.data, frequency);

                productDetails = {
                    details: {
                        productLimits : res.data?.productLimits,
                        stpAllowed : res.data?.stpAllowedCode !== "N"
                    },
                    targetScheme: selectedProduct?.description,
                    targetSchemeId: productId,
                    isTargetSchemeSelected: true,
                    stpDates: dateDropDownValues,
                    selectedStpDate: '',
                };
                setSelectedProductDetails(productDetails, selectedScheme, index)
            } else {
                productDetails = {
                    targetScheme: selectedProduct?.description,
                    isTargetSchemeSelected: true,
                    targetSchemeId: productId,
                };
                setSelectedProductDetails(productDetails, selectedScheme, index, res?.__error)
            }});

    };

    const setSelectedProductDetails = (productDetails, selectedScheme, index, respError) =>{
        if(isEmpty(productDetails)){
            return [];
        }
        const updatedSelectedSchemeDetails = selectedScheme.map((scheme, id)=>{
            if(id === index){
                let updatedDetails = {
                    ...scheme,
                    ...productDetails,
                };
                return {
                    ...scheme,
                    ...productDetails,
                    errors: getErrors(scheme?.transferAmount, updatedDetails)
                };
            }
            return scheme;
        });

        setSTPState((prevState) => ({
            ...prevState,
            selectedScheme: updatedSelectedSchemeDetails,
            responseErrors : isEmpty(respError) ? '' : respError
        }));
    };

    const setDropdown = (data, frequency) => {
        let obj = [];
        if(frequency === "Monthly"){
            obj = data?.allowedDates
        }else{
            obj = data?.allowedDays
        }
        if (isEmpty(obj)) {
            return [];
        }
        let dates = obj.map(item => ({
            label: item.toString(),
            value: item.toString()
        }));
        return dates;
    };

    const handleChangeDate = (type, selectedDate, index) => {

        const {selectedScheme} = STPState;
        let updatedSchemeDetails = selectedScheme.map((scheme, id) => {
            if (id === index) {
                return {
                    ...scheme,
                    [type]: isEmpty(selectedDate) ? '' : selectedDate,
                    errors: getErrors(scheme.transferAmount, scheme, type, selectedDate),
                };
            }
            return scheme;
        });
        setSTPState((prevState) => ({
            ...prevState,
            selectedScheme: updatedSchemeDetails
        }))
    };

    const changeSTPDate = (date, index) => {
        const {selectedScheme} = STPState;
        let updatedSchemeDetails = selectedScheme.map((scheme, id) => {
            if (id === index) {
                let updatedScheme = {
                    ...scheme,
                    selectedStpDate: date
                };
                return {
                    ...scheme,
                    errors: getErrors(updatedScheme?.transferAmount, updatedScheme),
                    selectedStpDate: date
                };
            }
            return scheme;
        });

        setSTPState((prevState) => ({
            ...prevState,
            selectedScheme: updatedSchemeDetails
        }))
    };

    const changeTransferAmount = (amount, index) => {
        const {selectedScheme} = STPState;
        let updatedSchemeDetails = selectedScheme.map((scheme, id) => {
            if (id === index) {
                return {
                    ...scheme,
                    transferAmount: amount,
                    errors: getErrors(amount, scheme),
                };
            }
            return scheme;
        });

        setSTPState((prevState) => ({
            ...prevState,
            selectedScheme: updatedSchemeDetails
        }))
    };

    const handleMaximumPeriodCheckBox = (index) => {
        const {selectedScheme} = STPState;
        let allFunds = selectedScheme.map((scheme, id) => {
            if (id === index) {
                let updatedScheme = {
                    ...scheme,
                    endDate: '',
                    isMaxPeriodSelected: !scheme?.isMaxPeriodSelected
                };
                return {
                    ...scheme,
                    ...updatedScheme,
                    errors: getErrors(scheme.transferAmount, updatedScheme),
                };
            }
            return scheme;
        });
        setSTPState((prevState) => ({
            ...prevState,
            selectedScheme: allFunds
        }))
    };

    const checkIsStpAllowed = ((limitsArray) => {
        if (isEmpty(limitsArray)) {
            return false;
        }
        let isAPLimit = [];
        isAPLimit = limitsArray.find((item) => {
            return item?.TRXNType === "AP";
        });
        if (isEmpty(isAPLimit)) {
            return false;
        }
        return true;
    });

    const isSelectedDateAllowed = (allowedDates, selectedDate) => {
        if (isEmpty(allowedDates)) {
            return true;
        }
        let flag = allowedDates.find((obj) => {
            return parseInt(obj.value) === parseInt(selectedDate)
        });

        return !isEmpty(flag);
    };

    const setDateError = (selectedFund, dateType, selectedDate, stpdate, isMax) => {
        const {frequency} = STPState;
        let isWeekly = frequency === "Weekly";
        let dateError = [];
        let selected = moment(selectedDate);
        let dayOfSelectedDate = moment(selectedDate).format('dddd');
        let isMaxPeriodSelected=isEmpty(isMax) ? selectedFund.isMaxPeriodSelected : isMax;
        let dateAfter7Days = moment().add(8, 'days').format('YYYY-MM-DD');

        let selectedDates = moment(selectedDate).format('YYYY-MM-DD');
        let selectedEndDate = moment(selectedFund.endDate).format('YYYY-MM-DD');
        let selectedStartDate = moment(selectedFund.startDate).format('YYYY-MM-DD');
        let dayOfStartDate = moment(selectedFund.startDate).format('dddd');


        let dayOfMonth = selected.format('DD');

        let stpStartDateMatchWithSTPDay = 'STP start date should match with STP day';

        let selectedStpDay = selectedFund.selectedStpDate;

        if (dateType === 'startDate') {
            let selectStartDateError = 'Please select start date';
            if (isEmpty(selectedDate)) {
                dateError.push(selectStartDateError)
            }
            else {
                dateError = dateError.filter(value => value !== selectStartDateError);
            }

            if (isEmpty(selectedFund.endDate) && !selectedFund.isMaxPeriodSelected) {
                dateError.push('Please select end date')
            }

            let sevenDayError = 'STP Start date should be 7 days higher than the current date';
            if (dateAfter7Days > selectedDates) {
                dateError.push(sevenDayError)
            }
            else{
                dateError = dateError.filter(value => value !== sevenDayError);
            }


            let stpStartDateMatchWithSTP = 'STP start date should match with STP date';
            if (!isWeekly && !isEmpty(stpdate) && !isEmpty(selectedDate) && !(parseInt(dayOfMonth) === parseInt(stpdate))) {
                dateError.push(stpStartDateMatchWithSTP)
            }
            else if(!isEmpty(selectedFund.stpDate) && !isEmpty(selectedDate)){
                dateError = dateError.filter(value => value !== stpStartDateMatchWithSTP);
            }

            let selectedEND = moment(selectedFund.endDate);
            let ENDDayOfMonth = selectedEND.format('DD');
            let stpENDDateMatchWithSTP = 'STP end date should match with STP date';
            if (!isWeekly && !isEmpty(stpdate) && !isEmpty(selectedFund.endDate) && !(parseInt(ENDDayOfMonth) === parseInt(stpdate)) && !selectedFund.isMaxPeriodSelected) {
                dateError.push(stpENDDateMatchWithSTP)
            }
            else if(!isEmpty(selectedFund.endDate)){
                dateError = dateError.filter(value => value !== stpENDDateMatchWithSTP);
            }


            let installmentError = 'Minimum 6 installments required';
            let minEndDate = moment(selectedDate).add(6, 'months').format('YYYY-MM-DD');

            let installmentErrorWeekly = 'Minimum 6 installments required';
            let minEndDateWeekly = moment(selectedDate).add(6, 'weeks').format('YYYY-MM-DD');

            if (!isEmpty(selectedDate) && !selectedFund.isMaxPeriodSelected) {
                if(isWeekly){
                    if (minEndDateWeekly > selectedEndDate) {
                        dateError.push(installmentErrorWeekly)
                    }
                    else{
                        dateError = dateError.filter(value => value !== installmentErrorWeekly);
                    }
                }else{
                    if (minEndDate > selectedEndDate) {
                        dateError.push(installmentError)
                    }
                    else{
                        dateError = dateError.filter(value => value !== installmentError);
                    }
                }
            }

            if (isWeekly && !isEmpty(selectedDate)  && !isEmpty(selectedFund.selectedStpDate) && !(selectedStpDay === dayOfSelectedDate)) {

                dateError.push(stpStartDateMatchWithSTPDay)
            }

        }

        if (dateType === 'endDate') {
            let selectDateError = 'Please select end date';
            if (isEmpty(selectedDate) && !selectedFund.isMaxPeriodSelected) {
                dateError.push(selectDateError)
            }
            else {
                dateError = dateError.filter(value => value !== selectDateError);
            }

            if (isEmpty(selectedFund.startDate)) {
                dateError.push('Please select start date')
            }

            let installmentError = 'Minimum 6 installments required';
            let minEndDate = moment(selectedFund.startDate).add(6, 'months').format('YYYY-MM-DD');

            let installmentErrorWeekly = 'Minimum 6 installments required';
            let minEndDateWeekly = moment(selectedFund.startDate).add(6, 'weeks').format('YYYY-MM-DD');

            if (!isEmpty(selectedFund.startDate) && !selectedFund.isMaxPeriodSelected) {
                if(isWeekly){
                    if (minEndDateWeekly > selectedDates) {
                        dateError.push(installmentErrorWeekly)
                    }
                    else{
                        dateError = dateError.filter(value => value !== installmentErrorWeekly);
                    }
                }else{
                    if (minEndDate > selectedDates) {
                        dateError.push(installmentError)
                    }
                    else{
                        dateError = dateError.filter(value => value !== installmentError);
                    }
                }
            }

            let sevenDayError = 'STP Start date should be 7 days higher than the current date';
            if (!isEmpty(selectedFund.startDate) && dateAfter7Days > selectedStartDate) {
                dateError.push(sevenDayError)
            }
            else{
                dateError = dateError.filter(value => value !== sevenDayError);
            }

            let stpStartDateMatchWithSTP = 'STP end date should match with STP date';
            if (!isWeekly && !isEmpty(stpdate) && !isEmpty(selectedDate) && !(parseInt(dayOfMonth) === parseInt(stpdate)) && !selectedFund.isMaxPeriodSelected) {
                dateError.push(stpStartDateMatchWithSTP)
            }
            else if(!isEmpty(selectedFund.stpDate) && !isEmpty(selectedDate)){
                dateError = dateError.filter(value => value !== stpStartDateMatchWithSTP);
            }


            let selected = moment(selectedFund.startDate);
            let startDayOfMonth = selected.format('DD');
            let stpENDDateMatchWithSTP = 'STP start date should match with STP date';
            if (!isWeekly && !isEmpty(stpdate) && !isEmpty(selectedFund.startDate) && !(parseInt(startDayOfMonth) === parseInt(stpdate))) {
                dateError.push(stpENDDateMatchWithSTP)
            }
            else if(!isEmpty(selectedFund.startDate)){
                dateError = dateError.filter(value => value !== stpENDDateMatchWithSTP);
            }

        }

        if (dateType !== 'startDate' && dateType !== 'endDate') {
            if (isEmpty(selectedFund.endDate) && !isMaxPeriodSelected) {
                dateError.push('Please select end date')
            }
            if (isEmpty(selectedFund.startDate)) {
                dateError.push('Please select start date')
            }
            if(!isEmpty(selectedFund.startDate)){
                let selectedDatett = moment(selectedFund.startDate).format('YYYY-MM-DD');
                let selected = moment(selectedFund.startDate);
                let dayOfMonth = selected.format('DD');
                let sevenDayError = 'STP Start date should be 7 days higher than the current date';

                if (dateAfter7Days > selectedDatett) {
                    dateError.push(sevenDayError)
                }
                else{
                    dateError = dateError.filter(value => value !== sevenDayError);
                }

                let stpStartDateMatchWithSTP = 'STP start date should match with STP date';
                if (!isWeekly && !isEmpty(stpdate) && !isEmpty(selectedFund.startDate) && !(parseInt(dayOfMonth) === parseInt(stpdate))) {
                    dateError.push(stpStartDateMatchWithSTP)
                }
                else if(!isEmpty(selectedFund.startDate)){
                    dateError = dateError.filter(value => value !== stpStartDateMatchWithSTP);
                }

            }
            if(!isEmpty(selectedFund.endDate)){
                let selectedDatett = moment(selectedFund.endDate).format('YYYY-MM-DD');
                let selected = moment(selectedFund.endDate);
                let dayOfMonth = selected.format('DD');
                let weekDayOfEndDate = selected.format('dddd');


                let installmentError = 'Minimum 6 installments required';
                if (!isEmpty(selectedFund.endDate) && !isEmpty(selectedFund.startDate)  && !isMaxPeriodSelected) {
                    let minEndDate = isWeekly?
                        moment(selectedFund.startDate).add(6, 'weeks').format('YYYY-MM-DD')
                        :
                        moment(selectedFund.startDate).add(6, 'months').format('YYYY-MM-DD');
                    if (minEndDate > selectedDatett) {
                        dateError.push(installmentError)
                    }
                    else{
                        dateError = dateError.filter(value => value !== installmentError);
                    }
                }

                let stpStartDateMatchWithSTP = 'STP end date should match with STP date';
                if (!isWeekly && !isEmpty(stpdate) && !isEmpty(selectedFund.endDate) && !(parseInt(dayOfMonth) === parseInt(stpdate)) && !isMaxPeriodSelected) {
                    dateError.push(stpStartDateMatchWithSTP)
                }
                else if(!isEmpty(selectedFund.endDate)){
                    dateError = dateError.filter(value => value !== stpStartDateMatchWithSTP);
                }
            }
        }

        if (dateType !== 'startDate') {
            if (isWeekly && !isEmpty(selectedFund.startDate) && !isEmpty(selectedFund.selectedStpDate) && !(selectedStpDay === dayOfStartDate)) {
                dateError.push(stpStartDateMatchWithSTPDay)
            }
        }

        return dateError;
    };

    const getErrors = (amount, selectedFund, dateType, selectedDate) => {
        const {frequency} = STPState;
        let errors = [];
        let allLimits = selectedFund.details.productLimits;
        let selectedLimit = {};

        let stpAllowed = selectedFund.details.stpAllowed;
        let datesAllowedDates = isEmpty(selectedFund?.stpDates) ? selectedFund?.stpDates : selectedFund?.stpDates;
        let isStpAllowed = checkIsStpAllowed(allLimits);
        let isMax = selectedFund?.isMaxPeriodSelected;

        let dateErrors= setDateError(selectedFund, dateType, selectedDate, selectedFund?.selectedStpDate, isMax); //selectedFund, dateType, selectedDate, stpdate, isMax

        let uniqueErrors = errors;
        let isWeekly = frequency === "Weekly";

        let emptyDate = isWeekly ? 'Please select day' : 'Please select date';
        let emptyAmount = "Please enter amount";
        let emptyScheme = 'Please select scheme';
        selectedLimit = getProductMinAndMaxValue(allLimits);

        if(isEmpty(selectedFund?.targetScheme)){
            let emptyErrorArray = [];
            emptyErrorArray = [emptyScheme];

            if (isEmpty(selectedFund?.selectedStpDate)) {
                emptyErrorArray.push(emptyDate);
            }
            emptyErrorArray.push(...dateErrors);

            if(isEmpty(amount)){
                emptyErrorArray.push(emptyAmount);
            }

            return emptyErrorArray;
        }else {

            if (!isEmpty(selectedFund?.targetScheme) && (!stpAllowed || isEmpty(datesAllowedDates) || !isStpAllowed || isEmpty(allLimits))) {
                let emptyErrorArray = ["STP is not allowed for this scheme"];
                return emptyErrorArray;
            }

            //get limit details from array of all limits using txnType
            let selectedMultiplier = (selectedLimit?.productMultipleValue < 100) ? 100 : selectedLimit?.productMultipleValue;

            let minValue = selectedLimit?.productMinValue;
            let maxValue = selectedLimit?.productMaxValue;
            let multiplier = selectedMultiplier;
            let dateNotAllowed = 'Selected Date is not allowed';

            let minimumAmountMessage = 'Minimum amount required is ' + minValue;
            let maximumAmountMessage = 'Maximum amount allowed is ' + maxValue;
            let amountGreaterThanSourceScheme = "You do not have sufficient balance in scheme";

            if (!isEmpty(selectedLimit)) {

                if (isEmpty(selectedFund?.selectedStpDate)) {
                    errors.push(emptyDate)
                } else {
                    errors = errors.filter(value => value !== emptyDate);
                }

                if (!isWeekly && !isEmpty(selectedFund?.selectedStpDate) && !isSelectedDateAllowed(selectedFund?.stpDates, selectedFund?.selectedStpDate)) {
                    errors.push(dateNotAllowed)
                } else {
                    errors = errors.filter(value => value !== dateNotAllowed);
                }

                if (isEmpty(amount)) {
                    errors.push(emptyAmount)
                } else {
                    errors = errors.filter(value => value !== emptyAmount);
                }

                if (!isEmpty(amount) && selectedFund?.sourceSchemeAmount < amount) {
                    errors.push(amountGreaterThanSourceScheme)
                } else {
                    errors = errors.filter(value => value !== amountGreaterThanSourceScheme);
                }

                if (!isEmpty(amount) && amount > selectedLimit?.productMaxValue) {
                    errors.push(maximumAmountMessage)
                } else {
                    errors = errors.filter(value => value !== maximumAmountMessage);
                }

                if (!isEmpty(amount) && amount < selectedLimit?.productMinValue) {
                    errors.push(minimumAmountMessage)
                } else {
                    errors = errors.filter(value => value !== minimumAmountMessage);
                }

                uniqueErrors = [...new Set(errors)];
            }

            let filteredErrors = filterErrorMessages(uniqueErrors);

            if (!isEmpty(filteredErrors)) {
                filteredErrors.push(...dateErrors);
                return filteredErrors;
            }
            uniqueErrors.push(...dateErrors);
            return uniqueErrors
        }
    };

    const filterErrorMessages = (errors) => {
        let message = "STP is not allowed for this scheme";
        let updatedArray = [];
        if (isEmpty(errors)) {
            return [];
        }
        let array = [...errors];
        updatedArray = array.filter(value => value === message);
        return updatedArray;
    };

    const getProductMinAndMaxValue = (productLimits) => {

        if (isEmpty(productLimits)) {
            return null;
        }
        let purchaseTransactionObj = getPurchaseTxnLimitObj(productLimits)
        if (!purchaseTransactionObj || purchaseTransactionObj.maxAmount <= 0) {
            return null;
        }

        let limitsObj = {
            productMinValue: isEmpty(purchaseTransactionObj.minAmount) ? '' : purchaseTransactionObj.minAmount,
            productMaxValue: isEmpty(purchaseTransactionObj.maxAmount) ? '' : purchaseTransactionObj.maxAmount,
            productMultipleValue: isEmpty(purchaseTransactionObj.multiples) ? '' : (purchaseTransactionObj.multiples < 100) ? 100 : purchaseTransactionObj.multiples,
        };
        return limitsObj;
    };

    const getPurchaseTxnLimitObj = (limitsArray) => {
        if (isEmpty(limitsArray)) {
            return;
        }
        let txn = [];

        txn = limitsArray.find((item) => {
            return item?.TRXNType === "AP";
        });
        return txn;
    };

    const handleDoneCLick = () => {
        const {selectedScheme} = STPState;
        let allFunds = selectedScheme.map(scheme => {
            return {
                ...scheme,
                errors: getErrors(scheme?.transferAmount, scheme)
            };
        });

        return allFunds;
    };

    const submitTransactionDetails = () => {
        const {selectedScheme, frequency} = STPState;
        let transactionRequestId = getTransactionRequestId();
        let updateFundsWithErrors = handleDoneCLick();

        setSTPState((prevState) => ({
            ...prevState,
            selectedScheme: isEmpty(updateFundsWithErrors) ? [] : updateFundsWithErrors,
            isDoneClicked: true
        }));

        let products = [];

        updateFundsWithErrors?.map((selectedFund) => {
            let tempObj = {
                productId: isEmpty(selectedFund?.sourceSchemeId)? '' : selectedFund?.sourceSchemeId,
                type: "AMOUNT",
                value: isEmpty(selectedFund?.transferAmount)? '' : parseFloat(selectedFund?.transferAmount),
                targetFundId: isEmpty(selectedFund?.targetSchemeId)? '' : selectedFund?.targetSchemeId,
                reinvestFlag: true,
                startDate: isEmpty(selectedFund?.startDate)? '' : moment(selectedFund?.startDate).format("YYYY-MM-DD"),
                endDate: isEmpty(selectedFund?.endDate)? '' : moment(selectedFund?.endDate).format("YYYY-MM-DD"),
                folioNumber: isEmpty(selectedFund?.folioNo)? '' : selectedFund?.folioNo,
                isForMaxPeriod: isEmpty(selectedFund?.isMaxPeriodSelected)? '' : selectedFund?.isMaxPeriodSelected,
                submissionDetails:{
                    productId: isEmpty(selectedFund?.sourceSchemeId)? '' : selectedFund?.sourceSchemeId,
                    fundInvestmentId: isEmpty(selectedFund?.fundInvestmentId)? '' : selectedFund?.fundInvestmentId,
                    folioNumber: isEmpty(selectedFund?.folioNo)? '' : selectedFund?.folioNo,
                    availableUnits: isEmpty(selectedFund?.units)? '' : parseInt(selectedFund?.units),
                    nav: isEmpty(selectedFund?.nav)? '' : parseInt(selectedFund?.nav),
                    navAsOfDate: isEmpty(selectedFund?.navAsOfDate)? '' : selectedFund?.navAsOfDate,
                    valuation: isEmpty(selectedFund?.valuation)? '' : parseInt(selectedFund?.valuation)
                }
            };
            tempObj = {
                ...tempObj,
                frequencyDetails: {
                    dayOfMonth: isEmpty(selectedFund?.selectedStpDate)? '' : selectedFund?.selectedStpDate
                },
            };
            if(frequency === "Weekly"){
                tempObj = {
                    ...tempObj,
                    frequencyDetails: {
                        weekDay: isEmpty(selectedFund?.selectedStpDate)? '' : selectedFund?.selectedStpDate
                    }
                };
            }
            products.push(tempObj)
        });
        let transactionErrors = validateTransaction(updateFundsWithErrors);

        if (transactionErrors.length > 0) {
            store.dispatch(showToastMessage('warning', transactionErrors?.length === 1 ? 'Error exists, changes are not saved.' : 'Errors exist, changes are not saved.'));
            return;
        }

        setSTPState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            transactionRequestId: transactionRequestId,
            products: products,
            txnRequestType: "STP"
        };

        submitFundRequest(payload, true)
            .then(res => {
                if (res.success) {
                    setSTPState((prevState) => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                    redirectScreen('DISCARD')
                } else {
                    setSTPState((prevState) => ({
                        ...prevState,
                        responseErrors: typeof res?.__error === 'string'? res?.__error : '',
                        selectedScheme: typeof res?.__error === 'object'? handleFundsWithErrors(res?.__error, updateFundsWithErrors) : updateFundsWithErrors,
                        isShowLoader: false
                    }))

                }
            })

    };

    const{UIState} = STPState;

    if(isEmpty(UIState)){
        return (
            <DLLoader type={"screen"} isVisible={true}/>
        );
    }

    return (
        <PurchaseSTPTransactionView
            {...props}
            {...STPState}
            submitTransactionDetails={submitTransactionDetails}
            openDiscardModal={openDiscardModal}
            closeAddDiscardModal={closeAddDiscardModal}
            handleDelete={handleDelete}
            redirectScreen={redirectScreen}
            searchProduct={searchProduct}
            handleChangeDate={handleChangeDate}
            setUiState={setUiState}
            addProduct={addProduct}
            setSelectedSchemeData={setSelectedSchemeData}
            changeTransferAmount={changeTransferAmount}
            changeSTPDate={changeSTPDate}
            handleMaximumPeriodCheckBox={handleMaximumPeriodCheckBox}
            filterErrorMessages={filterErrorMessages}
        />
    )
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMDView: state.appState.deviceInfo.isMDView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(PurchaseSTPTransaction);
