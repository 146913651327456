import React, {Suspense, useEffect, useState} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Drawer from '@mui/material/Drawer';

import store from '../../src/store';

import {setCurrentPathname, showSystemErrorBlock} from "./AppActions";
import {getMyProfile, leaveApplicationRoom} from "../user/userActions";
import {isEmpty} from "../utils/validations";
import {setPrevLocation} from "./AppActions";
import {userConst} from "../user/userConsts";
import {getAuthorizationToken, removeAuthorizationToken} from "../utils/httpHelper";
import {resetLoggedOutState, setUserAuthStatus, setUserProfile} from "../user/userReducer";

import SiteUnderMaintenance from "./SiteUnderMaintenance";
import PageNotAccessible from "../core/components/pageNotAccessible/PageNotAccessible";
import LoggedOutUser from "../core/components/loggedOutUser/LoggedOutUser";
import ResAppBar from "./appResappBar/AppResAppBar";
import DLLoader from "../core/components/DLLoader/DLLoader";
import DLToastMessage from "../core/components/DLToastMessage/DLToastMessage";
import PreLoginRoute from "../core/webRouteWrapper/PreLoginRoute";
import PrePostLoginRoute from "../core/webRouteWrapper/PrePostLoginRoute";
import PostLoginRoute from "../core/webRouteWrapper/PostLoginRoute";


import Login from "../user/logIn/components/Login";
import Onbording from "../../src/onboarding/Onboarding";
import Leads from "../../src/leads/Leads";
import Clients from "../clients/Clients";
import RaiseRequest from "../clientRequest/components/RaiseRequest/RaiseRequest";
import ClientRequest from "../clientRequest/ClientRequests";
import HomePage from "../../src/home/HomePage";
import LeadDetails from "../leads/components/leadsDetails/LeadsDetails";
import AvailableDocumentList from "../onboarding/components/uploadDocuments/uploadDocuments";
import onboardingDetails from '../onboarding/components/onboardingDetails/OnboardingDetails'
import OnboardingWizard from '../onboarding/components/onboardingWizard/OnboardingWizard'
import OnboardingsListing from "../onboarding/listing/OnboardingsListing";
import LeadsCreate from "../leads/components/leadsCreate/LeadsCreate";
import NFTList from "../transactions/NFT/components/NFTList/NFTList";
import NFTCreate from "../transactions/NFT/components/NFTCreate/NFTCreate";
import NFTDetails from "../transactions/NFT/components/NFTDetails/NFTDetails";
import PortfolioListing from "../portfolio/components/portfolioListing/portfolioListing";
import PortfolioCreate from "../portfolio/components/portfolioCreate/PortfolioCreate";
import FTRequestList from "../transactions/FT/components/FTRequestList/FTRequestList";
import ClientsDetails from "../clients/components/clientsDetails/clientsDetails";
import ClientRequestDetails from "../clientRequest/components/clientRequestDetails/ClientRequestDetails";
import NFTDashboard from "../transactions/NFT/components/NFTDashboard/NFTDashboard";
import FTDetails from "../transactions/FT/components/FTDetails/FTDetails";
import ModelPortfolio from "../transactions/FT/components/FTDetails/components/modelPortfolio/ModelPortfolio";
import PurchaseTransaction from "../transactions/FT/components/FTDetails/components/purchaseTransaction/PurchaseTransaction"
import PurchaseSIPTransaction from "../transactions/FT/components/FTDetails/components/purchaseSIPTransaction/PurchaseSIPTransaction"
import ClientsTransaction from "../clientApproval/ClientsTransaction";
import FTReportList from "../transactions/FT/components/FTReportList/FTReportList";
import PreLoginRedirection from "../user/preLoginRedirection/PreLoginRedirection";
import PurchaseRedemptionTransaction
    from "../transactions/FT/components/FTDetails/components/purchaseRedemptionTransaction/PurchaseRedemptionTransaction";
import PurchaseSTPTransaction
    from "../transactions/FT/components/FTDetails/components/purchaseSTPTransaction/PurchaseSTPTransaction";
import PurchaseSwpTransaction
    from "../transactions/FT/components/FTDetails/components/PurchaseSwpTransaction/PurchaseSwpTransaction";
import SwitchTransaction from "../transactions/FT/components/FTDetails/components/switchTransaction/SwitchTransaction";


const AppNavigator = (props) => {

    const {isMobileView, userAuthStatus, setUserProfile, setUserAuthStatus} = props;

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isProfileSet, setIsProfileSet] = useState(true);
    const [isSiteUnderMaintenance, setIsSiteUnderMaintenance] = useState(false);
    const [isSettingOptionShow, setIsSettingOptionShow] = useState(false);

    const handleSettingIconClick = (value) => {
        setIsSettingOptionShow(value);
    };


    useEffect(() => {
        let authResponse = getAuthorizationToken();
        if (authResponse.status) {
            setUserAuthStatus(userConst.AUTH_STATUS_AUTHORIZED);
        } else {
            setUserAuthStatus(userConst.AUTH_STATUS_UNAUTHORIZED);
        }
    }, []);//todo - remove this useeffect after actual signup of user

    useEffect(() => {
        // handling login and logout states
        setCurrentPathname(props.location.pathname);
        if (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) {
            getMyProfile()
                .then((res) => {
                    if (res.success) {
                        setUserProfile(res?.data);
                        setIsProfileSet(true);
                    } else {
                        if (res.statusCode === 401 || res.statusCode === 403) {
                            setIsProfileSet(true)
                        } else {
                            setIsProfileSet(false);
                            store.dispatch(showSystemErrorBlock(true));
                            setIsSiteUnderMaintenance(true)
                        }
                    }
                });
        }

        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED) {
            setUserProfile(null);
            removeAuthorizationToken();
        }
    }, [userAuthStatus]);


    useEffect(() => {// handling session-expire and access-denied states
        const {account} = props;

        if (props.userStatus === userConst.AUTH_STATUS_LOGGED_OUT) {
            props.history.replace('/session-expired');
            store.dispatch(resetLoggedOutState());
        }
        if (props.userStatus === userConst.ACCESS_DENIED) {
            if (isEmpty(account?.id)) {
                props.history.push('/access-denied', {
                    fullUrl: window.location.href,
                    prevPath: props.location.pathname
                });
            } else {
                props.history.push('/access-denied', {
                    fullUrl: window.location.href,
                    prevPath: props.location.pathname
                });
            }
            store.dispatch(resetLoggedOutState());
        }
    }, [props.userStatus]);

    useEffect(() => {
        const {appRoomJoined} = props;
        // handling session-expire and access-denied states
        const regex = /^\/onboardings\/\d+$/;
        let isOnboardingFlow =  regex.test(props.location.pathname);
        if(!isOnboardingFlow && appRoomJoined){
            props.leaveApplicationRoom()
        }
    }, [props.location.pathname]);

    const renderDrawer = () => {
        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED) {
            return null;
        }
        let contentUi = '';
        let mobileDrawerUi = (
            <Drawer
                anchor={'left'}
                open={isDrawerOpen}
                sx={{
                    width: '300px',
                    '& .MuiDrawer-paper': {
                        width: '300px',
                    },
                }}
                onClose={() => setIsDrawerOpen(false)}>
                <div style={{
                    background: '#E7ECF2',
                    height: '100vh'
                }}>
                    {contentUi}
                </div>
            </Drawer>
        );

        let desktopDrawerUi = (
            <div style={{
                width: 240,
                height: '100%',
                position: "fixed",
                background: '#E7ECF2'
            }}
                 className={"drawer-height"}>
                {contentUi}
            </div>
        );

        return (
            <div>
                {isMobileView ? mobileDrawerUi : desktopDrawerUi}
            </div>
        );


    };

    const renderAppBar = () => {
        return (<ResAppBar
            openDrawer={() => setIsDrawerOpen(true)}
            handleSettingIconClick={handleSettingIconClick}
            isSettingOptionShow={isSettingOptionShow}
        />);
    };

    const renderContent = () => {
        return (
            <div className="w-100 h-100"
                 style={{
                     overflowY: 'auto'
                 }}
                 onClick={() => {
                     setIsSettingOptionShow(false)
                 }}>
                <Suspense fallback={(<DLLoader type={"screen"} isVisible={true}/>)}>
                    <Switch>
                        <PreLoginRoute exact path={"/financial-transaction/:id"} component={ClientsTransaction}/>
                        <PreLoginRoute exact path="/user/login" component={Login}/>
                        <PostLoginRoute exact path={"/"} component={HomePage}/>
                        <PostLoginRoute exact path={"/leads"} component={Leads}/> //temp
                        <PostLoginRoute exact path={"/clients"} component={Clients}/>
                        <PostLoginRoute exact path="/clients/non-financial-transactions-dashboard" component={NFTDashboard}/>
                        <PostLoginRoute exact path={"/clients/:accountId"} component={ClientsDetails}/>
                        <PostLoginRoute exact path={"/raise-request"} component={RaiseRequest}/>
                        <PostLoginRoute exact path={"/client-requests"} component={ClientRequest}/>
                        <PostLoginRoute exact path={"/non-financial-transactions"} component={NFTList}/>
                        <PostLoginRoute exact path={"/leads/:leadId"} component={LeadDetails}/>
                        <PostLoginRoute exact path={"/createLeads/new"} component={LeadsCreate}/>
                        <PostLoginRoute exact path={"/create-non-financial-transaction"} component={NFTCreate}/>
                        <PostLoginRoute exact path={"/onboardings/new/:onboardingApplicationId"} component={OnboardingWizard}/>
                        <PostLoginRoute exact path={"/onboardings/new"} component={Onbording}/>
                        <PostLoginRoute exact path={"/onboardings"} component={OnboardingsListing}/>
                        <PostLoginRoute exact path={"/portfolio-management"} component={PortfolioListing}/>
                        <PostLoginRoute exact path={"/createPortfolio-Management"} component={PortfolioCreate}/>
                        <PostLoginRoute exact path={"/onboardings/:onboardingApplicationId"} component={onboardingDetails}/>
                        <PostLoginRoute exact path={"/available-docs"} component={AvailableDocumentList}/>
                        <PostLoginRoute exact path="/non-financial-transaction/:id" component={NFTDetails}/>
                        <PostLoginRoute exact path="/client-requests/:clientRequest/financial-transaction/:transactionId" component={FTDetails}/>
                        <PostLoginRoute exact path="/client-requests/:clientRequest/financial-transaction/:transactionId/select-portfolio" component={ModelPortfolio}/>
                        <PostLoginRoute exact path="/client-requests/:clientRequest/financial-transaction/:transactionId/select-portfolio/purchase-transaction" component={PurchaseTransaction}/>
                        <PostLoginRoute exact path="/client-requests/:clientRequest/financial-transaction/:transactionId/select-portfolio/purchase-sip-transaction" component={PurchaseSIPTransaction}/>
                        <PostLoginRoute exact path="/client-requests/:clientRequest/financial-transaction/:transactionId/select-portfolio/purchase-stp-transaction" component={PurchaseSTPTransaction}/>
                        <PostLoginRoute exact path="/client-requests/:clientRequest/financial-transaction/:transactionId/select-portfolio/purchase-swp-transaction" component={PurchaseSwpTransaction}/>
                        <PostLoginRoute exact path="/client-requests/:clientRequest/financial-transaction/:transactionId/select-portfolio/purchase-redemption-transaction" component={PurchaseRedemptionTransaction}/>
                        <PostLoginRoute exact path="/client-requests/:clientRequest/financial-transaction/:transactionId/switch-transaction" component={SwitchTransaction}/>
                        <PostLoginRoute exact path={"/client-requests/:id"} component={ClientRequestDetails}/>
                        <PostLoginRoute exact path={"/financial-transactions"} component={FTRequestList}/>
                        <PostLoginRoute exact path={"/reports/financial-transactions"} component={FTReportList}/>
                        <PrePostLoginRoute exact path="/session-expired" component={LoggedOutUser}/>
                        <PostLoginRoute exact path="/postlogin/financial-transaction/:uuid" component={PreLoginRedirection}/>

                        <Route exact path={"/access-denied"} component={PageNotAccessible}/>
                        <Route exact path="*" component={''}/>
                    </Switch>
                </Suspense>
                <DLToastMessage/>
            </div>
        );
    };


    const getPageMargin = () =>{
        const {isXSView} = props;
        if(isXSView){
            return "54px"
        }
        return "50px"
    };

    if(isSiteUnderMaintenance){
        return <SiteUnderMaintenance {...props}/>
    }

    const {profile, isProfileCallFired} = props;
    let authResponse = getAuthorizationToken();

    if (authResponse.status && !isEmpty(authResponse.token)) {
        if (!isProfileCallFired && isEmpty(profile)) {
            return
        }

        {
            if (!isProfileSet) {
                return (
                    <>
                        <DLToastMessage/>
                    </>
                )
            }
        }
    }

    return (
        <div style={{
            height: '100dvh'
        }}>
            {renderAppBar()}
            <div className="h-100"
                 style={{
                     paddingTop: (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) ? getPageMargin() : '0px'
                 }}>
                {isDrawerOpen && renderDrawer()}
                {renderContent()}
                <DLToastMessage/>
            </div>
        </div>
    );
};

AppNavigator.propTypes = {};

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    profile: state.userState.profile,
    isProfileCallFired: state.userState.isProfileCallFired,
    isDrawerOpen: state.appState.isDrawerOpen,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    appRoomJoined: state.appState.socket.appRoomJoined,
    userStatus: state.userState.userStatus,
});

export default connect(mapStateToProps,
    {setUserProfile, setPrevLocation, setUserAuthStatus, leaveApplicationRoom})(withRouter(AppNavigator));
