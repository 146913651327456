import React from "react";
import {getRoundedvalue, convertUTCToLocalTime, numberWithCommas} from "../../../../../../utils/helper";
import {isEmpty} from "../../../../../../core/components/DLComponentHelper";

import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";

const TxnTableSWPView = (props) => {

    const {
        isMobileView,
        isLGView,
        txnDetails,
        investmentDetails,
        txnStage,
        txnData,
        handleAddOrEditTransactionDetails,
        frequencies,
        isManagementUser,
        getFrequency
    } = props;

    const handleAmount = (amount) => {
        if (isEmpty(amount)) {
            return "-";
        }
        return numberWithCommas(amount)
    }

    const renderEmptyUi = () => {
        return (
            <div>
                <DLText id={''}
                        text={'No funds have been added for withdrawal.'}
                        fontWeight={'normal'}
                        fontSize={"sm"}
                />
                {!isManagementUser && <div className='text-center'>
                    <DLButton id={'add-button'}
                              onClick={() => handleAddOrEditTransactionDetails("ADD")}
                              hidden={txnStage !== "DRAFT"}
                              label={'Add'}/>
                </div>}
            </div>
        )
    }


    const renderInvestmentAmount = () => {
        return (
            <div className='d-flex'>
                <div className='pe-4'>
                    <DLText
                        id={'investment-amount-title'}
                        type={'normal'}
                        text={'Frequency: '}
                        fontSize={'md'}
                        fontWeight={'normal'}
                        fontColor={'black'}
                        marginBottom={"none"}
                        isInline={true}
                    />
                    <DLText id={'investment-amount-value'}
                            type={'normal'}
                            fontSize={'md'}
                            fontWeight={'bold'}
                            text={(isEmpty(txnData?.txnDetails?.frequency)) ? '-' : getFrequency(txnData?.txnDetails?.frequency, frequencies)}
                            fontColor={'black'}
                            marginBottom={"none"}
                            isInline={true}
                    />
                </div>
            </div>
        )
    }

    const renderMobileInvestmentDetails = () => {
        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded h-100">
                    <div className='p-3 black-border-top black-border-bottom'>
                        {renderInvestmentAmount()}
                    </div>
                    <div className='d-flex justify-content-center align-items-center black-border-bottom h-100'
                         style={{minHeight: '300px'}}
                    >
                        <div className='text-center my-5'>
                            {renderEmptyUi()}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded">
                <div>
                    <div
                        className='d-flex justify-content-between align-items-center p-3 border-top-black border-bottom-black'>
                        {renderInvestmentAmount()}
                        <i className="fa-solid fa-pencil"
                           hidden={txnStage !== "DRAFT" || isManagementUser}
                           onClick={() => handleAddOrEditTransactionDetails("EDIT")}/>
                    </div>
                </div>
                <div>
                    <div className='p-0 w-100 h-100'>
                        <div className="h-100">
                            {renderMobileData()}
                            {renderMobileTotalCount()}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderInvestmentDetails = () => {

        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded p-3 h-100 pb-5">
                    {renderInvestmentAmount()}
                    <div
                        className='d-flex justify-content-center align-items-center h-100 panel rounded border mt-2 mb-4'>
                        <div className='text-center'>
                            {renderEmptyUi()}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3 ">
                <div className='d-flex justify-content-between align-items-center column-gap-4'>
                    {renderInvestmentAmount()}
                    <DLButton
                        id={'edit-transaction'}
                        label={'Edit'}
                        hidden={txnStage !== "DRAFT" || isManagementUser}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => handleAddOrEditTransactionDetails("EDIT")}/>
                </div>
                <div className='pt-3'>
                    <div className='p-0 w-100 border-rounded panel h-100'>
                        <div className="p-2 h-100 c">
                            {renderHeadings()}
                            {renderData()}
                            {renderTotalCount()}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderHeadings = () => {
        return (
            <div className={"py-2 border-bottom row px-0 m-0"}>
                <div className='data-card-label col-4'>
                    <DLText id={'schemes'}
                            text={"Scheme"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-2'>
                    <DLText id={'folio-no'}
                            text={"Folio No"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-3 text-start'>
                    <DLText id={'StartDate_to_EndDate'}
                            text={"Start Date to End Date"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-1 text-start'>
                    <DLText id={'SWP_EndDate'}
                            text={"SWP Date"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-2 text-end'>
                    <DLText id={'amount'}
                            text={"Amount"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
            </div>
        )
    };

    const renderTotalCount = () => {
        return (
            <div className={"py-2 border-top row px-0 m-0"}>
                <div className='data-card-label col-9'/>
                <div className='data-card-label col-3 d-flex justify-content-end'>
                    <div className={'text-end'} style={{maxWidth: "150px"}}>
                        <DLText id={'total-Withdrawal-Amount'}
                                text={isEmpty(txnData?.totalWithdrawalAmount) ? "-" : handleAmount(txnData?.totalWithdrawalAmount)}
                                fontWeight={"semi-bold"}
                                marginBottom={"none"}
                                fontSize={"sm"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderData = () => {
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"py-2 row px-0 m-0"}
                                 key={index}>
                                <div className='data-card-label col-4'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={item?.productName}
                                                fontWeight={"semi-bold"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='data-card-label col-2'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item?.folioNumber) ? "Create new folio" : item?.folioNumber}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='data-card-label col-3'>
                                    <div className=''>
                                        <DLText id={''}
                                                text={isEmpty(item.startDate) ? '-' : convertUTCToLocalTime(item.startDate, 'DD-MM-YYYY') + ' to '}
                                                fontWeight={"normal"}
                                                isInline={true}
                                                fontSize={"xs"}
                                        />
                                        {item.isForMaxPeriod?
                                            <DLText id={''}
                                                    text={'Maximum Period'}
                                                    fontWeight={"normal"}
                                                    fontSize={"xs"}
                                                    isInline={true}
                                            /> :
                                            <DLText id={''}
                                                    text={isEmpty(item.endDate) ? '-' : convertUTCToLocalTime(item.endDate, 'DD-MM-YYYY')}
                                                    fontWeight={"normal"}
                                                    fontSize={"xs"}
                                                    isInline={true}
                                            />}
                                    </div>
                                </div>

                                <div className='data-card-label col-1'>
                                    <div className='h-100'>
                                        <DLText id={''}
                                                text={item?.frequencyDetails?.dayOfMonth}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                                isInline={!isLGView}
                                        />

                                    </div>
                                </div>
                                <div className='data-card-label col-2'>
                                    <div className='d-flex align-items-center justify-content-end h-100'>
                                        <DLText id={''}
                                                text={handleAmount(item?.value)}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderMobileData = () => {
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"p-3 m-0"}
                                 key={index}>
                                <div className='data-card-label pb-2'>
                                    <DLText id={''}
                                            text={item?.productName}
                                            fontColor={'black'}
                                            fontWeight={"semi-bold"}
                                            fontSize={"sm"}
                                    />
                                    <DLText id={''}
                                            text={'From ' + convertUTCToLocalTime(item?.startDate, 'DD-MM-YYYY') + ' to ' + (item?.isForMaxPeriod ? ' Maximum Period' : convertUTCToLocalTime(item?.endDate, 'DD-MM-YYYY'))}
                                            fontWeight={"normal"}
                                            fontSize={"xs"}
                                    />
                                </div>
                                <div className='row'>
                                    <div className='data-card-label col-6'>
                                        <div className='d-flex align-items-center h-100'>
                                            <div>
                                                <DLText id={''}
                                                        text={'Folio No'}
                                                        fontColor={'grayDark'}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                        isInline={false}
                                                        marginBottom={"none"}
                                                />
                                                <DLText id={''}
                                                        text={isEmpty(item?.folioNumber) ? "Create new folio" : item?.folioNumber}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                        isInline={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='data-card-label col-3'>
                                        <div className='d-flex align-items-center justify-content-start h-100'>
                                            <div>
                                                <DLText id={''}
                                                        text={'SWP Date'}
                                                        fontColor={'grayDark'}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                        marginBottom={"none"}
                                                        isInline={false}
                                                />
                                                <DLText id={''}
                                                        text={item.frequencyDetails.dayOfMonth}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='data-card-label col-3'>
                                        <div className={'text-start'} style={{maxWidth: "150px"}}>
                                            <div className='d-flex align-items-center justify-content-start h-100'>
                                                <div>
                                                    <DLText id={''}
                                                            text={'Amount'}
                                                            fontColor={'grayDark'}
                                                            fontWeight={"normal"}
                                                            fontSize={"xs"}
                                                            marginBottom={"none"}
                                                            isInline={false}
                                                    />
                                                    <DLText id={''}
                                                            text={handleAmount(item?.value)}
                                                            fontWeight={"normal"}
                                                            fontSize={"xs"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderMobileTotalCount = () => {
        return (
            <div className={"py-3 black-border-bottom row px-0 m-0 bg-white black-border-top"}>
                <div className='data-card-label col-9'/>
                <div className='data-card-label col-3 d-flex align-items-center justify-content-start'>
                    <div className={'text-start'}>
                        <DLText id={''}
                                fontWeight={"bold"}
                                marginBottom={"none"}
                                text={isEmpty(txnData?.totalWithdrawalAmount) ? "-" : handleAmount(txnData?.totalWithdrawalAmount)}

                        />
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className={'h-100'}>
            {isMobileView ? renderMobileInvestmentDetails() : renderInvestmentDetails()}
        </div>
    );
};


export default TxnTableSWPView;
