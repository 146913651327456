import React from 'react';
import ReactECharts from 'echarts-for-react' ;

import {isEmpty} from "../../../../../utils/validations";
import {numberWithCommas} from "../../../../../utils/helper";
import {connect} from "react-redux";
import appTheme from "../../../../../assets/appTheme";

interface AppProps {
}

function VerticalBarChart(props) {
    const {
        series, xAxislabels, isXSView, id, yAxisPosition, showDownloadIcon,
        splitNumber, percentage, gridLeft, gridRight, gridTop, gridBottom, isSelected, isSMView, isMobileView
    } = props;

    const getBaseLog = (x, y) => {
        return Math.log(y) / Math.log(x);
    };

    const getRoundedStepSize = (value) => {
        if (isEmpty(value)) {
            return
        }
        let log = getBaseLog(10, value);
        let stepSize = value;
        log = Math.floor(log);

        if (log === 5) {
            stepSize = ((stepSize / 100000).toFixed(0)) * 100000
        } else if (log === 4) {
            stepSize = ((stepSize / 10000).toFixed(0)) * 10000
        } else if (log === 3) {
            stepSize = ((stepSize / 1000).toFixed(0)) * 1000
        } else if (log === 2) {
            stepSize = ((stepSize / 100).toFixed(0)) * 100
        } else if (log === 2) {
            stepSize = ((stepSize / 100).toFixed(0)) * 100
        } else if (log === 1) {
            stepSize = ((stepSize / 10).toFixed(0)) * 10
        }

        return stepSize;

    };

    const getMaxRoundedNumber = (value) => {
        if (isEmpty(value)) {
            return
        }
        let log = getBaseLog(10, value);
        let maxValue = value;
        log = Math.floor(log);


        if (log === 6) {
            maxValue = (Math.ceil(value / 100000) * 100000) + 100000;
        } else if (log === 5) {
            maxValue = (Math.ceil(value / 10000) * 10000) + 10000;
        } else if (log === 4) {
            maxValue = (Math.ceil(value / 1000) * 1000) + 1000;
        } else if (log === 3) {
            maxValue = (Math.ceil(value / 100) * 100) + 100
        } else if (log === 2) {
            maxValue = (Math.ceil(value / 10) * 10) + 10
        } else if (log === 1) {
            maxValue = (Math.ceil(value / 1) * 1) + 1
        }

        return maxValue
    };

    const numericNumbers = series?.data.map(Number);
    let maxValue = Math.max(...numericNumbers);
    let minValue = Math.min(...numericNumbers);
    let stepSize;
    let steps = 5;

    if (minValue >= 0 && maxValue > 0) {
        minValue = 0;
        if (minValue >= 0 && maxValue > 0 && maxValue < 10) {
            maxValue = 10;
        } else {
            maxValue = getMaxRoundedNumber(Math.trunc(maxValue));
        }

        stepSize = maxValue / steps;
        stepSize = Math.ceil(stepSize);
        maxValue = stepSize * steps;
        stepSize = maxValue / steps;
    }

    if (minValue < 0 && maxValue <= 0) {

        let newMaxValue = minValue * (-1)
        let newMinValue = Math.round(maxValue * (-1))

        if (newMinValue >= 0 && newMaxValue > 0 && newMaxValue < 10) {
            newMaxValue = 10;
        } else {
            newMaxValue = Math.ceil(getMaxRoundedNumber(newMaxValue));
        }

        stepSize = newMaxValue / steps;
        stepSize = getRoundedStepSize(stepSize);
        let difference = newMaxValue - (stepSize * steps);
        newMaxValue = stepSize * (steps + Math.ceil(difference / stepSize));
        stepSize = newMaxValue / steps;
        minValue = newMaxValue * (-1);
        maxValue = 0

    }

    if(!stepSize || stepSize<1){
        stepSize =1;
    }

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: function (params) {

                let label = params[0].name || '';

                if (label) {
                    label += ': ';
                }
                if (percentage) {
                    label += params[0].value + '%';
                } else {
                    label += params[0].value;

                }
                return numberWithCommas(label);
            }
        },
        legend: {
            show: false
        },
        toolbox: {
            show: true,
            orient: 'vertical',
            right: 0,
            top: 0,
            feature: {
                saveAsImage: {show: showDownloadIcon}
            }
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {show: false},
                data: xAxislabels,
                name: 'Created Date',
                nameLocation: 'middle',
                nameGap: 60,
                nameTextStyle: {
                    color: appTheme.appColor.black,
                    fontFamily:appTheme.default.fontFamily
                },
                axisLabel: {
                    interval: isXSView && isSelected==="THIS_MONTH" ? 6 : isSMView ? 1 : 0,
                    rotate: 45,
                    fontSize: isXSView ? 10 : 12,
                }
            }
        ],
        yAxis: [
            {
                min: minValue,
                max: maxValue,
                type: 'value',
                name: 'Number of Requests',
                nameLocation: 'middle',
                nameGap: 35,
                nameTextStyle: {
                    color: appTheme.appColor.black,
                    fontFamily:appTheme.default.fontFamily
                },
                splitNumber: splitNumber,
                boundaryGap: false,
                interval: stepSize,
                position: isEmpty(yAxisPosition) ? 'left' : yAxisPosition,
            }
        ],
        grid: {
            top: isEmpty(gridTop) ? (showDownloadIcon ? 40 : 20) : gridTop,
            left: isEmpty(gridLeft) ? 60 : gridLeft,
            right: isEmpty(gridRight) ? 50 : gridRight,
            bottom: isEmpty(gridBottom) ? (isXSView || isSMView) ? 90 : 70 : gridBottom
        },
        series: !(isXSView || isSMView) ? series : [
            {
                type: 'bar',
                data: series.data,
                barWidth: 7,
                itemStyle: {
                    color: '#0073FF'
                }
            }
        ],
        roam: false,
        zoom: null,
    };

    const getHeight = () => {
        const {height} = props;

        if (height) {
            return height
        }

        return '250px'
    };

    return (
        <div id={id} className='' style={{width: '100%', height: getHeight()}}>
            <ReactECharts option={option}/>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isSMView: state.appState.deviceInfo.isSMView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(VerticalBarChart);