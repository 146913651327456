import React from 'react';
import appTheme from "../../../assets/appTheme";
import DLText from "../../../core/components/DLText/DLText";
import ClientCategory from "./components/clients/ClientCategory";
import ClientAccountType from "./components/clients/ClientAccountType";
import OnboardingAgeing from "./components/onboardings/OnboardingAgeing";
import MyTotalLeads from "./components/leads/MyTotalLeads";
import LeadsAgeing from "./components/leads/LeadsAgeing";
import OnboardingCategory from "./components/onboardings/OnboardingCategory";
import OnboardingAccountType from "./components/onboardings/OnboardingAccountType";
import OnboardingStatus from "./components/onboardings/OnboardingStatus";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext/TabContext";
import TabList from "@mui/lab/TabList";
import Transaction from "./components/transactions/Transaction";

const CRMDashboardView = (props) => {
    const {isXSView, isSMView, isMDView, selectedProfileTab} = props;
    const renderClientData = () => {
        return (
            <div className={(isXSView || isSMView) ? 'w-100 h-100 pb-3 mb-5' : ' m-0 row p-0 '}>
                <div className={`col-md-6 col-lg-4  p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3' : ' ')}>
                    <ClientCategory
                        {...props}
                    />
                </div>
                <div className={`col-md-6 col-lg-4  p-0  ${(isXSView ? 'mb-5 pb-2' : isSMView ? 'px-2 mb-5 pb-2' : 'px-2')}`}>
                    <ClientAccountType
                        {...props}
                    />
                </div>
            </div>
        )
    };

    const renderClientWidgets = () => {
        return (
            <div className={(isXSView || isSMView) ? 'mb-2 mt-3' : ' border-rounded panel p-0 mt-3'}>
                <div className="px-0 h-100">
                    <div className={isXSView ? '' : "p-2 ps-3 border-bottom rounded-top"}
                         style={{backgroundColor: (isXSView || isSMView) ? '' : appTheme.appColor.lightBlue}}>
                        <DLText id={'Leads'}
                                isInline={true}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                text={'Clients'}/>

                    </div>
                    <div className={isMDView ? 'py-3 px-2' : (isXSView || isSMView) ? 'pt-3 px-2' : 'py-3 px-2'}>
                        {renderClientData()}
                    </div>
                </div>
            </div>
        )
    };

    const renderOnboardingData = () => {
        return (
            <div className={(isXSView || isSMView) || isMDView ? 'h-100' : 'h-100 pb-3'}>
                <div className={(isXSView || isSMView) ? 'w-100 ' : ' m-0 row p-0 '}>
                    <div
                        className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3' : ' ')}>
                        <OnboardingStatus
                            {...props}
                        />
                    </div>
                    <div
                        className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3 pb-0' : ' ')}>
                        <OnboardingAgeing
                            {...props}
                        />
                    </div>

                </div>
                <div className={isXSView ? 'w-100' : ' m-0 row p-0 ' + ((!isSMView) ? ' pt-3' : ' ')}>
                    <div
                        className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3' : ' ')}>
                        <OnboardingCategory
                            {...props}
                        />
                    </div>
                    <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'mb-5' : isSMView ? 'px-2 mb-5' : 'px-2'}`}>
                        <OnboardingAccountType
                            {...props}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderOnboardingWidgets = () => {
        return (
            <div
                className={(isXSView || isSMView) ? 'border-bottom mb-2 mt-3' : 'border-rounded panel p-0 section-tabs-gap mb-0 mt-3'}>
                <div className="px-0 h-100">
                    <div className={(isXSView || isSMView) ? '' : "p-2 ps-3 border-bottom rounded-top"}
                         style={{backgroundColor: (isXSView || isSMView) ? '' : appTheme.appColor.lightBlue}}>
                        <DLText id={'Onboarding-Widgets'}
                                isInline={true}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                text={'Onboardings'}/>

                    </div>
                    <div className={isMDView ? 'py-3 px-2' : (isXSView || isSMView) ? 'pt-3 px-2' : 'p3 px-2'}>
                        {renderOnboardingData()}
                    </div>
                </div>
            </div>
        )
    };

    const renderLeadData = () => {
        return (
            <div className={(isXSView || isSMView) ? 'w-100 h-100 pb-3 mb-5' : ' m-0 row p-0 '}>
                <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3' : ' ')}>
                    <MyTotalLeads
                        {...props}
                    />
                </div>
                <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? '' : isSMView ? 'px-2' : 'px-2'}`}>
                    <LeadsAgeing
                        {...props}
                    />
                </div>
                <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'mb-3' : isSMView ? 'px-2 mb-3' : 'px-2'}`}>
                    {/*<UnassignedLeads*/}
                    {/*    {...props}*/}
                    {/*/>*/}
                </div>
            </div>
        )
    };

    const renderLeadWidgets = () => {
        return (
            <div className={(isXSView || isSMView) ? 'mb-2 mt-3' : 'border-rounded panel p-0 section-tabs-gap mt-3'}>
                <div className="px-0 h-100">
                    <div className={(isXSView || isSMView) ? '' : "p-2 ps-3 border-bottom rounded-top "}
                         style={{backgroundColor: (isXSView || isSMView) ? '' : appTheme.appColor.lightBlue}}>
                        <DLText id={'Leads'}
                                isInline={true}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                text={'Leads'}/>

                    </div>
                    <div className={isMDView ? 'py-3 px-2' : (isXSView || isSMView) ? 'pt-3 px-2' : 'py-3 px-2'}>
                        {renderLeadData()}
                    </div>
                </div>
            </div>
        )
    };
    const renderTransaction = () => {
        return (
            <div className={'mt-3 p-0 pb-4'}>
                <Transaction {...props}/>
            </div>
        )
    };

    const renderSystematicPlansTabs = () => {
        const {handleTabChange, allTabs} = props;

        return allTabs?.map((item, index) => {
            return (
                <Tab key={'tab_' + index}
                     label={item.label}
                     onClick={() => {
                         handleTabChange(item)
                     }}
                     style={{
                         textTransform: 'none',
                         color: appTheme.appColor.black,
                         fontSize: appTheme.fontSizes.md,
                         fontFamily: appTheme.default.fontFamily,
                         fontWeight: (item?.value === selectedProfileTab?.value) ? 500 : 400
                     }}
                     value={item.value}/>
            );
        })
    };

    const renderTabs = () => {
        return (
            <div
                className={((isXSView || isSMView) ? 'bg-white ' : ' border-bottom bg-light ') + 'page-container'}>
                <Box sx={{width: '100%'}}>
                    <TabContext value={selectedProfileTab?.value}>
                        <Box sx={{borderBottom: 0, borderColor: appTheme.appColor.darkBlue}}>
                            <TabList onChange={() => {
                            }} aria-label="lab API tabs-example"
                                     variant="scrollable"
                                     scrollButtons={false}>
                                {renderSystematicPlansTabs()}
                            </TabList>
                        </Box>
                    </TabContext>
                </Box>
            </div>
        )
    };

    const getClassname = () => {
        if (selectedProfileTab?.value === "Onboardings") {
            return 'pb-4'
        } else if (selectedProfileTab?.value === "Financial Transactions") {
            return isMDView ? 'pb-4' : '';
        } else {
            return "";
        }
    };

    return (
        <div className={'bg-light ' + getClassname()}>
            <div className={(isXSView || isSMView) ? 'bg-white' : ''}>
                {renderTabs()}
            </div>
            <div
                className={'page-container bg-light'}>
                {selectedProfileTab?.value === "Leads" ? renderLeadWidgets() : ''}
                {selectedProfileTab?.value === "Onboardings" ? renderOnboardingWidgets() : ''}
                {selectedProfileTab?.value === "Clients" ? renderClientWidgets() : ''}
            </div>
            <div className={isMDView ? 'bg-light ' : (isXSView || isSMView) ? 'bg-white' : 'page-container'}>
                {selectedProfileTab?.value === "Financial Transactions" ? renderTransaction() : ''}
            </div>
        </div>
    )
};

export default CRMDashboardView;