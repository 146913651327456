import React from "react";

import {isEmpty} from "../../../../../../core/components/DLComponentHelper";
import {getCommaSeparatedDecimalValue} from "../../../../FTHelper";

import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";

const TxnTableRedemptionView = (props) => {

    const {
        isMobileView,
        investmentDetails,
        txnStage,
        txnData,
        handleAddOrEditTransactionDetails,
        isManagementUser
    } = props;

    const renderEmptyUi = () => {
        return (
            <div>
                <DLText id={''}
                        text={'No funds have been added for redemption.'}
                        fontWeight={'normal'}
                        fontSize={"sm"}
                />
                {!isManagementUser && <div className='d-flex justify-content-center'>
                    <DLButton id={'add-button'}
                              onClick={() => handleAddOrEditTransactionDetails("ADD")}
                              hidden={txnStage !== "DRAFT"}
                              label={'Add'}/>
                </div>}
            </div>
        )
    };

    const renderHeadings = () => {
        return (
            <div className={"py-2 border-bottom row px-0 m-0"}>
                <div className='data-card-label col-4'>
                    <DLText id={'scheme'}
                            text={"Scheme"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-2'>
                    <DLText id={'folio-no'}
                            text={"Folio No."}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-2 text-start'>
                    <DLText id={'type'}
                            text={"Type"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-2 text-end'>
                    <DLText id={'redeem-units'}
                            text={"Redeem Units"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-2 text-end'>
                    <DLText id={'redeem-amount'}
                            text={"Redeem Amount"}
                            marginBottom={"none"}
                            fontWeight={"semi-bold"}
                            fontSize={"sm"}
                    />
                </div>
            </div>
        )
    };

    const getTypeText = (type) => {
        if (type === 'UNIT') {
            return 'Units'
        } else if (type === 'AMOUNT') {
            return 'Amount'
        } else {
            return 'All'
        }
    };

    const renderData = () => {
        let redeemAmount;
        let redeemUnits;
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    {
                        redeemAmount = getCommaSeparatedDecimalValue((item.submissionDetails.amount).toFixed(2), true);
                        redeemUnits = getCommaSeparatedDecimalValue((item.submissionDetails.units).toFixed(4), true);
                    }
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"py-2 row px-0 m-0"}
                                 key={index}>
                                <div className='data-card-label col-4'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item.productName) ? '-' : item.productName}
                                                fontWeight={"semi-bold"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='data-card-label col-2'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item.folioNumber) ? '-' : item.folioNumber}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                        />
                                    </div>

                                </div>
                                <div className='data-card-label col-2'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={getTypeText(item.type)}
                                                fontWeight={"bold"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='data-card-label col-2'>
                                    <div className='d-flex align-items-center justify-content-end h-100'>
                                        <DLText id={''}
                                                text={redeemUnits}
                                                fontWeight={item.type === 'UNIT' ? "bold" : "normal"}
                                                fontColor={item.type === 'UNIT' ? "black" : "grayDark"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='data-card-label col-2'>
                                    <div className='d-flex align-items-center justify-content-end h-100'>
                                        <DLText id={''}
                                                text={redeemAmount}
                                                fontWeight={item.type === 'AMOUNT' ? "bold" : "normal"}
                                                fontColor={item.type === 'AMOUNT' ? "black" : "grayDark"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderTotalCount = () => {
        let totalRedeemAmount = getCommaSeparatedDecimalValue((txnData.totalRedeemAmount).toFixed(2), true);
        return (
            <div className={"py-2 border-top row px-0 m-0"}>
                <div className='data-card-label col-10'/>
                <div className='data-card-label col-2 d-flex justify-content-end'>
                    <div className={'text-end'} style={{maxWidth: "150px"}}>
                        <DLText id={'total-redemption'}
                                text={totalRedeemAmount}
                                fontWeight={"bold"}
                                marginBottom={"none"}
                                fontSize={"sm"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileData = () => {
        let redeemAmount;
        let redeemUnits;
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    {
                        redeemAmount = getCommaSeparatedDecimalValue((item.submissionDetails.amount).toFixed(2), true);
                        redeemUnits = getCommaSeparatedDecimalValue((item.submissionDetails.units).toFixed(4), true);
                    }
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "black-border-bottom py-4" : "border-bottom py-4"}>
                            <div className='d-flex justify-content-between px-3'>
                                <div className='w-100 px-0 mx-0'>
                                    <DLText id={'scheme-title'}
                                            text={item?.productName}
                                            fontWeight={'bold'}
                                            fontSize={"xs"}
                                            marginBottom={'none'}
                                    />
                                </div>
                            </div>
                            <div className='pt-2 pb-3 px-3'>
                                <DLText
                                    id={"folioNo"}
                                    text={"Folio no. "}
                                    fontColor={"grayDark"}
                                    fontSize={"xs"}
                                    marginBottom={"none"}
                                    isInline={true}
                                />
                                <DLText
                                    id={'folio-no'}
                                    text={item?.folioNumber}
                                    fontSize={"xs"}
                                    marginBottom={"none"}
                                    isInline={true}
                                />
                            </div>
                            <div className={'px-3 row m-0'}>
                                <div className="px-0 col-4" id={'transferUnits-field-' + index} >
                                    <DLText
                                        id={"type-label"}
                                        text={"Type"}
                                        fontColor={"grayDark"}
                                        fontSize={"xs"}
                                        marginBottom={"none"}
                                    />
                                    <div className={'pt-2'}>
                                        <DLText
                                            id={"type"}
                                            text={getTypeText(item?.type)}
                                            fontSize={'xs'}
                                            fontWeight={'bold'}
                                        />
                                    </div>
                                </div>
                                <div className="px-0  col-4" id={'redeemUnits-field-' + index}>
                                    <div className="">
                                        <DLText
                                            id={"units"}
                                            text={"Redeem Units"}
                                            fontColor={"grayDark"}
                                            fontSize={"xs"}
                                            marginBottom={"none"}
                                        />
                                        <div className={'pt-2'}>
                                            <DLText
                                                id={"amount"}
                                                text={redeemUnits}
                                                fontSize={'xs'}
                                                fontWeight={item?.type === 'UNIT' ? 'bold' : 'normal'}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="px-0  col-4" id={'redeemAmount-field-' + index}>
                                    <div className={""}>
                                        <DLText
                                            id={"units"}
                                            text={"Redeem Amount"}
                                            fontColor={"grayDark"}
                                            fontSize={"xs"}
                                            marginBottom={"none"}
                                        />
                                        <div className={'pt-2'}>
                                            <DLText
                                                id="units"
                                                text={redeemAmount}
                                                fontSize={'xs'}
                                                fontWeight={item?.type === 'AMOUNT' ? 'bold' : 'normal'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                })}
            </div>
        )
    };

    const renderMobileInvestmentDetails = () => {
        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded h-100">
                    <div className='d-flex justify-content-center align-items-center black-border-bottom h-100'
                         style={{minHeight: '300px'}}
                    >
                        <div className='text-center my-5'>
                            {renderEmptyUi()}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded">
                <div className='d-flex justify-content-between align-items-center px-3 py-2 border-top-black border-bottom-black'>
                    <div className='d-flex justify-content-end align-items-center me-2 gap-1'>
                        <DLText id={''}
                                text={'Redemption Amount: '}
                                fontSize={"md"}
                                fontWeight={"bold"}
                                marginBottom={"none"}
                        />
                        <DLText id={''}
                                text={getCommaSeparatedDecimalValue((txnData?.totalRedeemAmount).toFixed(2), true)}
                                fontSize={"md"}
                                fontWeight={"bold"}
                                marginBottom={"none"}
                        />
                    </div>
                    <DLButton
                        id={'edit-transaction'}
                        label={'Edit'}
                        hidden={txnStage !== "DRAFT" || isManagementUser}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        reduceWidth={true}

                        sideMargin={"xs"}
                        onClick={() => handleAddOrEditTransactionDetails("EDIT")}/>
                </div>
                <div className='p-0 w-100 h-100'>
                    <div className="h-100">
                        {renderMobileData()}
                    </div>
                </div>
            </div>
        )
    };


    const renderInvestmentDetails = () => {

        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded h-100 p-3">
                    <div className='d-flex justify-content-center align-items-center h-100 panel rounded border'>
                        <div className='text-center'>
                            {renderEmptyUi()}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3 ">
                <div className='d-flex justify-content-between align-items-center column-gap-4'>
                    <DLText
                        id={'your-selection-label'}
                        type={'normal'}
                        text={'Your Selection '}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                        isInline={true}
                    />
                    <DLButton
                        id={'edit-transaction'}
                        label={'Edit'}
                        hidden={txnStage !== "DRAFT" || isManagementUser}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => handleAddOrEditTransactionDetails("EDIT")}/>
                </div>
                <div className='pt-3'>
                    <div className='p-0 w-100 border-rounded panel h-100'>
                        <div className="p-2 h-100">
                            {renderHeadings()}
                            {renderData()}
                            {renderTotalCount()}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className={'h-100'}>
            {isMobileView ? renderMobileInvestmentDetails() : renderInvestmentDetails()}
        </div>
    );
};

export default TxnTableRedemptionView;