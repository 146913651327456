export const headingFTData = ['Account', 'Ticket Number', 'Type of Request', 'Status', 'Created Date'];

export const headingFTDataForManagement = ['Account', 'Ticket Number', 'Type of Transaction', 'Status', 'Created Date', 'CRM & Location'];

export const headingFTReportData = ['Account Holders', 'IIN', 'Account Type', 'Transaction Type', 'Transaction Date', 'Schemes', 'Amount', 'Transaction Status', 'Sub Transaction Type', 'Target Product', 'Registrar ID', 'Folio No.', 'Payment Mode' , 'RTA Status', 'UMRN No.', 'Category', 'Action'];

export const headingFTReportDataForManagement = ['Account Holders', 'IIN', 'Account Type', 'Transaction Type', 'Transaction Date', 'Schemes', 'Amount', 'Transaction Status', 'Sub Transaction Type', 'Target Product', 'Registrar ID', 'Folio No.', 'Payment Mode' , 'RTA Status', 'UMRN No.', 'Category', 'CRM', 'CRM Location', 'Action'];

export const purchaseTransactionHeadingData = ['Schemes', 'Folio No.', 'Allocation', 'Amount', 'Action'];

export const purchaseTransactionCellConfig = [
    {
        textAlign: 'left',
        width: '35%',
        minWidth: '35%'
    },
    {
        textAlign: 'left',
        width: '15%',
        minWidth: '15%'
    },
    {
        textAlign: 'right',
        width: '100px',
        minWidth: '100px',
        paddingRight: 100
    },
    {
        textAlign: 'left',
        width: '15%',
        minWidth: '15%'
    },
    {
        textAlign: 'center',
        width: '15%',
        minWidth: '15%'
    }
];

export const FTReportCellConfig = [
    {
        minWidth: "300px",
        maxWidth: '300px',
        width: '300px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "170px",
        maxWidth: '170px',
        textAlign: "left",
    },
    {
        minWidth: "170px",
        maxWidth: '170px',
        textAlign: "left",
    },
    {
        minWidth: "425px",
        maxWidth: '425px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "120px",
        maxWidth: '120px',
        textAlign: "right",
    },
    {
        minWidth: "240px",
        maxWidth: '240px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "425px",
        maxWidth: '425px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "250px",
        maxWidth: '250px',
        textAlign: "left",
    },
    {
        minWidth: "170px",
        maxWidth: '170px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "100px",
        maxWidth: '100px',
        textAlign: "center",
    },
];

export const FTReportCellConfigForManagement = [
    {
        minWidth: "300px",
        maxWidth: '300px',
        width: '300px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "170px",
        maxWidth: '170px',
        textAlign: "left",
    },
    {
        minWidth: "170px",
        maxWidth: '170px',
        textAlign: "left",
    },
    {
        minWidth: "425px",
        maxWidth: '425px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "120px",
        maxWidth: '120px',
        textAlign: "right",
    },
    {
        minWidth: "240px",
        maxWidth: '240px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "425px",
        maxWidth: '425px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "250px",
        maxWidth: '250px',
        textAlign: "left",
    },
    {
        minWidth: "170px",
        maxWidth: '170px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "100px",
        maxWidth: '100px',
        textAlign: "center",
    },
];

export const purchaseSIPTransactionHeadingData = ['Schemes', 'Folio No.','SIP Start Date & End Date','SIP Date', 'Allocation', 'Amount', 'Action'];

export const purchaseSIPTransactionCellConfig = [
    {
        textAlign: 'left',
        width: '30%',
        minWidth: '30%',
        maxWidth:'30%'
    },
    {
        textAlign: 'left',
        width: '150px',
        minWidth: '150px'
    },
    {
        textAlign: 'left',
        width: '15%',
        minWidth: '15%'
    },
    {
        textAlign: 'left',
        width: '72px',
        minWidth: '72px'
    },
    {
        textAlign: 'right',
        width: '100px',
        minWidth: '100px',
    },
    {
        textAlign: 'left',
        width: '15%',
        minWidth: '15%'
    },
    {
        textAlign: 'center',
        width: '15%',
        minWidth: '15%'
    }
];

export const existingRedemptionHeaderData = ['', 'Scheme', 'Folio No.', 'Available Units', 'NAV (as of date)', 'Valuation'];

export const redemptionSelectedSchemeHeaderData = ['Scheme', 'Folio No.', 'Type', 'Redeem Units', 'Redeem Amount', 'Action'];

export const switchSelectedSchemeHeaderData = ['Source Scheme', 'Folio No.', 'Target Scheme', 'Amount', 'Action'];

export const existingRedemptionEditCellConfig = [
    {
        textAlign: 'center',
        width: '5%',
        minWidth: '5%',
        height: 32,
    },
    {
        textAlign: 'left',
        width: '40%',
        minWidth: '40%',
        padding: 0,
        height: 32,
    },
    {
        textAlign: 'left',
        width: '10%',
        minWidth: '10%',
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
    },
    {
        textAlign: 'right',
        width: '15%',
        minWidth: '15%',
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
    },
    {
        textAlign: 'left',
        width: '20%',
        minWidth: '20%',
        paddingLeft: '6%',
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
    },
    {
        textAlign: 'right',
        width: '10%',
        minWidth: '10%',
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
    },
];

export const switchSelectedSchemeCellConfig = [
    {
        textAlign: 'left',
        width: '20%',
        minWidth: '20%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'left',
        width: '12%',
        minWidth: '12%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'left',
        width: '30%',
        minWidth: '30%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'left',
        width: '12%',
        minWidth: '12%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'center',
        width: '7%',
        minWidth: '7%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
];

export const existingRedemptionEditCellConfigXSView = [
    {
        padding: '0 8px 16px'
    }
];

export const redemptionSelectedSchemeCellConfigXSView = [
    {
        padding: '16px'
    }
];

export const redemptionSelectedSchemeCellConfig = [
    {
        textAlign: 'left',
        width: '390px',
        maxWidth: '390px',
        verticalAlign: 'top',
        height: 32,
    },
    {
        textAlign: 'left',
        width: '200px',
        maxWidth: '200px',
        verticalAlign: 'top',
        height: 32,
    },
    {
        textAlign: 'left',
        width: '150px',
        maxWidth: '150px',
        verticalAlign: 'top',
        height: 32,
    },
    {
        textAlign: 'left',
        width: '200px',
        maxWidth: '200px',
        verticalAlign: 'top',
        height: 32,
    },
    {
        textAlign: 'left',
        width: '200px',
        maxWidth: '200px',
        verticalAlign: 'top',
        height: 32,
    },
    {
        textAlign: 'center',
        width: '100px',
        minWidth: '100px',
        verticalAlign: 'top',
        height: 32,
    },
];


export const stpSelectedSchemeCellConfig = [
    {
        textAlign: 'left',
        width: '20%',
        minWidth: '20%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'left',
        width: '20%',
        minWidth: '20%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'left',
        width: '25%',
        minWidth: '25%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'left',
        minWidth: '15%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'left',
        width: '15%',
        minWidth: '15%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'left',
        width: '10%',
        minWidth: '10%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    }
];

export const existingPortFolioEditCellConfig = [
    {
        textAlign: 'center',
        width: '5%',
        minWidth: '5%',
        height: 32,
    },
    {
        textAlign: 'left',
        width: '40%',
        minWidth: '40%',
        padding: 0,
        height: 32,
    },
    {
        textAlign: 'right',
        width: '10%',
        minWidth: '10%',
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
    },
    {
        textAlign: 'right',
        width: '15%',
        minWidth: '15%',
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
    },
    {
        textAlign: 'right',
        width: '20%',
        minWidth: '20%',
        paddingLeft: '6%',
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
    },
    {
        textAlign: 'right',
        width: '10%',
        minWidth: '10%',
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
    },
];

export const existingPortFolioHeaderData = ['', 'Scheme', 'Folio No.', 'Available Units', 'NAV (as of date)', 'Valuation'];

export const existingPortFolioEditCellConfigXSView = [
    {
        padding: '0 8px 16px'
    }
];

export const stpSelectedSchemeHeaderDataForMonthly = ['Source Scheme', 'Target Scheme', 'STP Start Date & End Date', 'STP Date', 'Transfer Amount', 'Action'];

export const stpSelectedSchemeHeaderDataForWeekly = ['Source Scheme', 'Target Scheme', 'STP Start Date & End Date', 'STP Day', 'Transfer Amount', 'Action'];

export const switchSelectedSchemeCellConfigXSView = [
    {
        padding: '8px'
    }
];

export const swpSelectedSchemeHeaderData = ['Scheme', 'Folio No.', 'SWP Start Date & End Date', 'SWP Date', 'Amount', 'Action'];

export const swpSelectedSchemeCellConfig = [
    {
        textAlign: 'left',
        width: '30%',
        minWidth: '30%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'right',
        width: '10%',
        minWidth: '10%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'left',
        width: '20%',
        minWidth: '20%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'left',
        width: '5%',
        minWidth: '5%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'left',
        width: '10%',
        minWidth: '10%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    },
    {
        textAlign: 'center',
        width: '5%',
        minWidth: '5%',
        paddingBottom: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    }
];

export const existingPortFolioClientEditCellConfig = [
    {
        textAlign: 'left',
        width: '40%',
        minWidth: '40%',
        padding: 16,
        height: 32,
    },
    {
        textAlign: 'right',
        width: '10%',
        minWidth: '10%',
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
    },
    {
        textAlign: 'right',
        width: '15%',
        minWidth: '15%',
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
    },
    {
        textAlign: 'right',
        width: '20%',
        minWidth: '20%',
        paddingLeft: '6%',
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
    },
    {
        textAlign: 'right',
        width: '10%',
        minWidth: '10%',
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
    },
];

export const existingPortFolioClientHeaderData = ['Scheme', 'Folio No.', 'Available Units', 'NAV (as of date)', 'Valuation'];

export const swpSelectedSchemeHeaderDataForWeekly = ['Scheme', 'Folio No.', 'SWP Start Date & End Date', 'SWP Day', 'Amount', 'Action'];

export const swpSelectedSchemeMobileCellConfig = [
    {
        textAlign: 'left',
        width: '30%',
        minWidth: '30%',
        padding: 0,
        verticalAlign: 'top',
        height: '10px',
        maxHeight: '10px',
    }
];

