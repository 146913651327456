import React from "react";
import {connect} from "react-redux";
import TxnTableSwitchView from "./TxnTableSwitchView";

const TxnTableSwitch = (props) => {
    return (
        <TxnTableSwitchView {...props} />
    )
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(TxnTableSwitch);