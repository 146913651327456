import React from "react";
import appTheme from "../../assets/appTheme";
import {isEmpty} from "../../utils/validations";
import DLText from "../../core/components/DLText/DLText";
import {numberWithCommas} from "../../utils/helper";

export const renderStatusUI = (txnRequestStage, txnRequestStatus, FTStatuses) =>{
    let statusLabel = '';
    let statusType = '';
    if(isEmpty(txnRequestStage)){
        return '-'
    }
    if(isEmpty(FTStatuses)){
        return txnRequestStage
    }

    if(txnRequestStage === 'DRAFT'){
        statusLabel = 'Draft';
    } else {
        FTStatuses.map((statusObj) => {
            if (statusObj.mapping.stage === txnRequestStage && statusObj.mapping.status === txnRequestStatus) {
                statusType = statusObj.mapping.status;
                statusLabel = statusObj?.label
            }
        })
    }

    if(statusType === 'REJECTED' || statusType === 'FAILED'){
        return (<div className="d-flex align-items-center badge-red ms-0">
            <span style={{color: appTheme.appColor.white}}> {statusLabel} </span>
        </div>);
    }
    else if(statusType === "APPROVED"){
        return (<div className="d-flex align-items-center badge-green ms-0">
            <span style={{color: appTheme.appColor.white}}> {statusLabel} </span>
        </div>);
    }else{
        return (<div className="d-flex align-items-center badge-amber ms-0">
            <span style={{color: appTheme.appColor.white}}> {statusLabel} </span>
        </div>);
    }
};

export const renderTransactionStatusDotUI = (txnRequestStage, txnRequestStatus, FTStatuses) =>{
    let statusLabel = '';
    let statusType = '';
    let fontSize = '12px';
    if(isEmpty(txnRequestStage)){
        return '-'
    }
    if(isEmpty(FTStatuses)){
        return txnRequestStage
    }

    if(txnRequestStage === 'DRAFT'){
        statusLabel = 'Draft';
    } else {
        FTStatuses.map((statusObj) => {
            if (statusObj.mapping.stage === txnRequestStage && statusObj.mapping.status === txnRequestStatus) {
                statusType = statusObj.mapping.status;
                statusLabel = statusObj?.label
            }
        })
    }

    if(statusType === 'REJECTED' ||statusType === 'FAILED' ){
        return (<div className="d-flex align-items-center">
            <span className="status-dot danger"/>
            <span style={{fontSize: fontSize, color: appTheme.appColor.grayDark}}> {statusLabel} </span>
        </div>);
    }
    else if(statusType === "APPROVED"){
        return (<div className="d-flex align-items-center">
            <span className="status-dot success"/>
            <span style={{fontSize: fontSize, color: appTheme.appColor.grayDark}}> {statusLabel} </span>
        </div>);
    }else{
        return (<div className="d-flex align-items-center">
            <span className="status-dot warning"/>
            <span style={{fontSize: fontSize, color: appTheme.appColor.grayDark}}> {statusLabel} </span>
        </div>);
    }
};

export const getTicketBadge = (label, ticketNumber) =>{
    return(
        <div className='d-flex align-items-center ms-2'>
            <div className='info-panel'>
                <DLText id={''}
                        text={label}
                        fontSize={"sm"}
                        fontWeight={"normal"}
                        isInline={true}
                        fontColor={"grayDark"}
                />
                <DLText id={''}
                        text={ticketNumber}
                        fontSize={"sm"}
                        fontWeight={"semi-bold"}
                        isInline={true}
                />
            </div>
        </div>
    )
};

export const getCommaSeparatedDecimalValue = (units, isDisplay) => {
    if (units === undefined || units === null) {
        return units;
    }

    let unitInString = units.toString();

    let newUnits = unitInString.split('.');

    let formatted = "";

    if (newUnits[0] !== undefined && newUnits[0] !== null) {

        formatted = numberWithCommas(formatted + newUnits[0]);
    }
    if ((Number(newUnits[1]) < 1) && (isDisplay)) {

        return formatted;
    }
    if (unitInString.includes('.')) {
        formatted = formatted + '.';
    }

    if (newUnits[1] !== undefined && newUnits[1] !== null) {

        formatted = formatted + newUnits[1];
    }

    return formatted;
};
