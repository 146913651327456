import React from 'react';
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import appTheme from "../../../../../assets/appTheme";

import DLText from "../../../../../core/components/DLText/DLText";
import NFTCRMDashboardWidget from "./NFTCRMDashboardWidget";
import DLDropDown from "../../../../../core/components/DLDropdown/DLDropDown";
import {monthDropdownOptionsMobileView} from "../../../NFTModal";
import VerticalBarChart from "../components/VerticalBarChart";

const NFTCRMDashboardView = (props) => {
    const {isXSView, yAxis, xAxis, isSelected, checkIfChartDataAvailable, onNFTClick, isSMView, isMobileView} = props;

    const openRequestUi = () => {
        return (
            <div className={"row px-1"}>
                <div className='col-md-6 col-lg-4 mb-3 px-2'><NFTCRMDashboardWidget {...props} requestType={'STATUS'}/>
                </div>
                <div className='col-md-6 col-lg-4 mb-3 px-2'><NFTCRMDashboardWidget {...props} requestType={'AGEING'}/>
                </div>
                <div className='col-md-6 col-lg-4 mb-3 px-2'><NFTCRMDashboardWidget {...props}
                                                                                    requestType={'CATEGORY'}/>
                </div>
            </div>
        )
    };

    const renderDesktopHeader = () => {
        return (
            <div className={isSMView ? 'py-2 bg-white' : 'w-100'}>
                <div
                    className={(isXSView || isSMView) ? 'ps-3 page-header p-0 bg-white' : 'page-header d-flex justify-content-between'}>
                    Non-Financial Transactions Dashboard
                    <div style={{minWidth: '302px'}}>
                        <DLText id={'listing-screen'}
                                text={'Non-Financial Transactions Listing >>'}
                                isClickable={true}
                                onClick={() => {
                                    onNFTClick();
                                }}
                                fontSize={"md"}
                                fontWeight={"semi-bold"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileButton = () => {
        const {getChartData} = props;
        return (
            <div className={''}>
                <DLDropDown
                    id={'campaignReportView'}
                    label={''}
                    value={isSelected}
                    options={monthDropdownOptionsMobileView}
                    onSelect={(value) => {
                        getChartData(value)
                    }}
                    disableHelperMessageHeight={true}
                    marginBottom={"none"}
                    minWidth={"153px"}

                />
            </div>
        );
    };

    const renderButtons = () => {
        const {getChartData} = props;
        return (
            <ButtonGroup variant="outlined"
                         aria-label="outlined button group"
                         style={{
                             height: '32px'
                         }}>
                <Button id={'campaignReportView-byBatch'}
                        style={{
                            textTransform: 'none',
                            borderRight: '1px solid #B2B2B2',
                            backgroundColor: ((isSelected === 'THIS_MONTH') ? appTheme.appColor.darkBlue : appTheme.appColor.white),
                            color: ((isSelected === 'THIS_MONTH') ? appTheme.appColor.white : ''),
                            font: 'normal normal medium 18px/22px Barlow',
                            minWidth: '48px', borderColor: '#B2B2B2',
                            borderRadius: '8px 0px 0px 8px',
                            fontSize: '14px'
                        }}
                        onClick={() => {
                            getChartData('THIS_MONTH')
                        }}
                >This Month</Button>
                <Button id={'campaignReportView-byBatch'}
                        style={{
                            textTransform: 'none',
                            borderRight: '1px solid #B2B2B2',
                            backgroundColor: ((isSelected === 'THIS_QUARTER') ? appTheme.appColor.darkBlue : appTheme.appColor.white),
                            color: ((isSelected === 'THIS_QUARTER') ? appTheme.appColor.white : ''),
                            font: 'normal normal medium 18px/22px Barlow',
                            minWidth: '48px',
                            borderColor: '#B2B2B2',
                            fontSize: '14px'
                        }}
                        onClick={() => {
                            getChartData('THIS_QUARTER')
                        }}
                >
                    This Quarter</Button>
                <Button id={'campaignReportView-byDay'}
                        style={{
                            textTransform: 'none',
                            backgroundColor: ((isSelected === 'THIS_YEAR') ? appTheme.appColor.darkBlue : appTheme.appColor.white),
                            color: ((isSelected === 'THIS_YEAR') ? appTheme.appColor.white : ''),
                            borderColor: '#B2B2B2',
                            minWidth: '48px',
                            font: 'normal normal medium 18px/22px Barlow',
                            borderRadius: '0px 8px 8px 0px',
                            fontSize: '14px'
                        }}
                        onClick={() => {
                            getChartData('THIS_YEAR')
                        }}
                >
                    This Year</Button>
            </ButtonGroup>
        )
    };

    const getBarChartValues = () => {
        return (
            {
                name: xAxis,
                data: yAxis,
                emphasis: {
                    focus: 'series'
                },
                barGap: 0.2,
                barWidth: '18px',
                itemStyle: {
                    color: '#0073FF',
                },
                type: 'bar',
            }
        );
    };

    const renderChart = () => {
        if (!checkIfChartDataAvailable()) {
            return (
                (<div className='w-100 d-flex align-items-center justify-content-center panel'
                      style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 360px)'}}>
                    Data not found.
                </div>)
            )
        }
        return (
            <div className='mx-sm-3 mb-2 mx-0'>
                <VerticalBarChart {...props}
                                  id={"funds-listing-echart"}
                                  series={getBarChartValues(yAxis)}
                                  xAxislabels={xAxis}
                                  showDownloadIcon={false}
                                  yAxisPosition={"left"}
                                  isBarChartClickable={true}
                />
            </div>
        )
    };

    const renderDesktopData = () => {
        return (
            <div>
                <div className="mb-3">
                    <div className={(isXSView || isSMView) ? 'bg-white' : 'p-0 w-100 border-rounded panel h-100'}>
                        <div className="px-0 h-100">
                            <div
                                className={"px-3 border-bottom d-flex justify-content-between rounded-top " + ((isXSView || isSMView) ? "py-3" : "py-2")}>
                                <div className='d-flex align-items-center'>
                                    <DLText id={'title-name'}
                                            isInline={true}
                                            fontSize={'lg'}
                                            marginBottom={'none'}
                                            fontWeight={'semi-bold'}
                                            text={"Requests"}/>
                                </div>
                                {(isXSView || isSMView) ? renderMobileButton() : renderButtons()}
                            </div>
                            <div className={'p-3 mb-4'}
                                 style={{minHeight: 317}}>
                                <div className='h-100'>
                                    {renderChart()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pb-4">
                    <div className={(isXSView || isSMView) ? 'bg-white' : 'p-0 w-100 border-rounded panel h-100'}>
                        <div className="px-0 h-100">
                            <div className={"py-2 px-3 border-bottom d-flex justify-content-between rounded-top"}>
                                <div className='d-flex align-items-center'>
                                    <DLText id={'title-name'}
                                            isInline={true}
                                            fontSize={'lg'}
                                            marginBottom={'none'}
                                            fontWeight={'semi-bold'}
                                            text={"Open Requests"}/>
                                </div>
                            </div>
                            <div className={'p-3 '}
                                 style={{minHeight: 317}}>
                                <div className='h-100'>
                                    {openRequestUi()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderDesktopUI = () => {
        return (
            <div className={'lead-details-container'}>
                {renderDesktopHeader()}
                <div className={isMobileView ? (isXSView || isSMView) ? 'pt-3 bg-light' : 'lead-page-container page-container bg-light' : 'lead-page-container page-container bg-light mx-3'}>
                    {renderDesktopData()}
                </div>
            </div>
        );
    };

    return (
        <div>
            {renderDesktopUI()}
        </div>
    );
};

export default NFTCRMDashboardView;