import store from "../../store";
import {
    resetFTAllFilters, setFTCRMIdValue,
    setFTLimitCountValue,
    setFTPageValue,
    setFTRowsPerPageValue, setFTSearchKeywordValue,
    setFTSkipCountValue, setFTStatusValue
} from "./FTReducer";
import {getAuthorizationToken, httpDelete, httpGet, httpPost, httpPut} from "../../utils/httpHelper";
import {getAppEnvironmentVariables} from "../../utils/helperPlatform";
import {isEmpty} from "../../utils/validations";
import {showToastMessage} from "../../AppComponents/AppActions";


export const setFTSkipCount = (data) => {
    store.dispatch(setFTSkipCountValue(data))
};

export const setFTLimitCount = (data) => {
    store.dispatch(setFTLimitCountValue(data))
};

export const setFTPageCount = (data) => {
    store.dispatch(setFTPageValue(data))
};

export const setFTRowPerPageCount = (data) => {
    store.dispatch(setFTRowsPerPageValue(data))
};

export const resetFTFilters = (data) => {
    store.dispatch(resetFTAllFilters(data))
};

export const setFTSearchKeyword = (data) => {
    store.dispatch(setFTSearchKeywordValue(data))
};

export const setFTCRMId = (data) => {
    store.dispatch(setFTCRMIdValue(data))
};

export const setFTStatus = (data) => {
    store.dispatch(setFTStatusValue(data))
};

export const getFinancialTransactionRequest = (data) => {
    return httpPost('/service/advisory/financialTransaction/all', data, true).then(res => {
        return res;
    })
};

export const getTransactionDetails = (requestId) => {
    return httpGet('/service/advisory/financialTransaction/fundRequest/all?txnRequestId=' + requestId, true).then(res => {
        return res;
    });
};

export const discardTransaction = (requestId) => {
    return httpDelete('/service/advisory/financialTransaction?txnRequestId=' + requestId, {}).then(res => {
        return res;
    })
};

export const resendTxnEmail = (data, handleErrorInComponent) => {
    return httpPost('/service/advisory/financialTransaction/resendLink', data, handleErrorInComponent).then(res => {
        return res;
    });
};

export const copyTransaction = (data, handleErrorInComponent) => {
    return httpPost('/service/advisory/financialTransaction/copy', data, handleErrorInComponent).then(res => {
        return res;
    });
};

export const sendToManagement = (data) => {
    return httpPut('/service/advisory/financialTransaction/management/in_progress', data, true).then(res => {
        return res;
    })
};

export const getRefreshTransactionDetails = (data, handleErrorInComponent) => {
    return httpPost('/service/advisory/financialTransaction/fundReport/refresh', data, handleErrorInComponent).then(res => {
        return res;
    });
};

export const moveToDraft = (data) => {
    return httpPut('/service/advisory/financialTransaction/status/draft', data, true).then(res => {
        return res;
    })
};

export const approveTransaction = (data) => {
    return httpPut('/service/advisory/financialTransaction/management/approve', data, true).then(res => {
        return res;
    })
};

export const rejectTransaction = (data) => {
    return httpPut('/service/advisory/financialTransaction/management/reject', data, true).then(res => {
        return res;
    })
};

export const getTxnReportDetails = (data, handleErrorInComponent) => {

    return httpPost('/service/advisory/financialTransaction/fundReport/all', data, handleErrorInComponent).then(res => {
        return res;
    });
};

export const editPaymentDetails = (data, handleErrorInComponent, type) => {
    if (type === "EDIT") {
        return httpPut('/service/advisory/financialTransaction/payment', data, handleErrorInComponent).then(res => {
            return res;
        })
    } else {
        return httpPost('/service/advisory/financialTransaction/payment', data, handleErrorInComponent).then(res => {
            return res;
        });
    }
};

export const getPaymentDetails = (paymentId) => {
    return httpGet('/service/advisory/financialTransaction/payment?paymentId=' + paymentId).then(res => {
        return res;
    });
};

export const submitFundRequest = (data, handleErrorInComponent) => {
    return httpPost('/service/advisory/financialTransaction/fundRequest', data, handleErrorInComponent).then(res => {
        return res;
    });
};

export const serachPurchaseProducts = (data) => {
    return httpPost('/service/advisory/master/product/search', data, true).then(res => {
        return res;
    });
};

export const selectProduct = (txnRequestId, productId) => {
    return httpGet('/service/advisory/financialTransaction/product/limits?txnRequestId='+txnRequestId+'&productId='+productId, true).then(res => {
        return res;
    });
};

export const addPurchaseScheme = (data) => {
    return httpPost('/service/advisory/financialTransaction/fund', data, true).then(res => {
        return res;
    });
};

export const FTReportDownload = (data) => {

    let url = '/service/advisory/financialTransaction/fundReport/download';

    const {REACT_APP_API_URL} = getAppEnvironmentVariables();

    let API_URL = REACT_APP_API_URL + url;

    let filename;

    let cloneData, flag;
    fetch(API_URL, {
        method: "POST",
        headers: {
            'Accept': 'application/*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getAuthorizationToken().token
        },
        responseType: 'arraybuffer',
        body: isEmpty(data) ? '' : JSON.stringify(data),
        dataType: 'blob'
    })

        .then(async (response) => {

            const contentDisposition = response.headers.get("content-disposition");

            if(contentDisposition) {
                const match = contentDisposition.match(/filename="?([^"]+)"?/);

                if (match) {
                    filename = match[1];
                }
            }
            cloneData = response.clone();
            await response.json()
                .then(res => {
                    // store.dispatch(setAppLoader(false));
                    if (res?.message) {
                        store.dispatch(showToastMessage('warning', res?.message))
                    }
                    if (res?.__error) {
                        store.dispatch(showToastMessage('warning', res?.__error))
                    }
                    flag = false;
                })
                .catch(() => {
                    flag = true;
                });
        }).then(result => {

        if (flag) {
            cloneData.blob().then(blob => {
                if (blob) {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = filename;
                    a.click();

                } else {
                    store.dispatch(showToastMessage('warning', result.message));
                }
            });
        }
    });
};

export const getExistingPortfolio = (requestedId) => {
    return httpGet('/service/advisory/client/folio/all?accountId=' + requestedId).then(res => {
        return res});
};


