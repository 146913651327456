import React from "react";

import {isEmpty} from "../../../../../../utils/validations";
import {getCommaSeparatedDecimalValue} from "../../../../FTHelper";

import {

    redemptionSelectedSchemeCellConfig,
    redemptionSelectedSchemeCellConfigXSView,
    redemptionSelectedSchemeHeaderData
} from "../../../../FTModal";

import DLTable from "../../../../../../core/components/DLTable/DLTable";
import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLDropDown from "../../../../../../core/components/DLDropdown/DLDropDown";
import DLInputTextField from "../../../../../../core/components/DLInputTextField/DLInputTextField";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import ExistingPortfolio from "../existingPortfolio/ExistingPortfolio";

const PurchaseRedemptionTransactionView = (props) => {
    const {
        isMobileView,
        isShowLoader,
        handleDelete,
        handleChange,
        selectedScheme,
        UIState,
    } = props;

    const getRedeemUnits = (scheme) => {
        if (scheme.type === 'ALL') {
            return scheme.units;
        } else if (!isEmpty(scheme?.redeemUnits)) {
            return scheme.redeemUnits;
        }
        return '';
    };

    const getRedeemAmount = (scheme) => {
        if (scheme.type === 'ALL') {
            return scheme.valuation;
        } else if (!isEmpty(scheme?.redeemAmount)) {
            return scheme?.redeemAmount;
        }
        return '';
    };

    const getFormattedTableData = () => {
        let tableData = [];

        selectedScheme?.map((selectedTransaction, index) => {

            let transactionRecords = [];
            let transactionDetails = {};
            if (!isMobileView) {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderProductNameUI(selectedTransaction)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderFolioNumberUI(selectedTransaction?.folioNo)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderType(selectedTransaction, index)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderRedeemUnits(selectedTransaction, index)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderRedeemAmount(selectedTransaction, index)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderAction(selectedTransaction?.id)
                });
            } else {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderSelectedSchemeTableMobileView(selectedTransaction, index)
                })
            }
            transactionDetails.id = selectedTransaction?.clientRequestId;
            transactionDetails.data = transactionRecords;
            tableData.push(transactionDetails)
        });
        return tableData;
    };

    const renderErrorMessages = (errors) => {

        if (isEmpty(errors)) {
            return '';
        }

        return (
            errors?.map((error, index) => {
                return <div className='pb-2 d-flex align-items-center' key={"error-" + index}>
                    <i className="fa-solid fa-circle-info error-icon pe-1"/>
                    <DLText id={''}
                            text={error}
                            fontSize={"xs"}
                            fontColor={"danger"}
                            marginBottom={'none'}
                    />
                </div>
            })
        )
    };

    const renderProductNameUI = (selectedTransaction) => {
        let productName = selectedTransaction?.scheme;
        let errors = selectedTransaction?.errors;

        const errorUI = renderErrorMessages(errors);
        return (
            <div>
                <div className='pt-2 mt-2'>
                    <DLText id={''}
                            text={productName}
                            fontSize={"xs"}
                            marginBottom={'none'}
                    />
                </div>
                {!isEmpty(errorUI) && <div className='pt-2'>
                    {errorUI}
                </div>}
            </div>
        )
    };

    const renderFolioNumberUI = (folioNo) => {
        return (
            <div className='pt-2 mt-2'>
                <DLText id={'folio-id'}
                        text={folioNo}
                        fontSize={'xs'}
                        marginBottom={'none'}
                />
            </div>
        )
    };

    const renderType = (selectedTransaction, index) => {

        return (
            <div className='pt-2' id={'type-field-' + index}>
                <DLDropDown
                    id={'type'}
                    options={[
                        {label: 'Amount', value: 'AMOUNT'},
                        {label: 'Units', value: 'UNIT'},
                        {label: 'All', value: 'ALL'},
                    ]}
                    label={''}
                    value={selectedTransaction?.type}
                    onSelect={(value) => handleChange(value, selectedTransaction?.id, 'type')}
                    minWidth={'120px'}
                    disableHelperMessageHeight={true}
                    placeholder={'Select'}
                    marginBottom={'none'}
                    error={selectedTransaction.typeError}
                />
            </div>
        )
    };

    const renderRedeemAmount = (selectedTransaction, index) => {
        let redeemAmount = getRedeemAmount(selectedTransaction);
        let displayRedeemAmount = getRedeemAmount(selectedTransaction);

        return (
            <div className='pt-2'
                 id={'transferAmount-field-' + index}>
                {selectedTransaction?.type === 'AMOUNT' && selectedTransaction?.type !== 'ALL' ? (
                    <div className={''} style={{}}>
                        <DLInputTextField
                            id={'redeem-amount'}
                            onChangeValue={(value) => {
                                handleChange(value, selectedTransaction?.id, 'redeemAmount');
                            }}
                            disableHelperMessageHeight={false}
                            value={redeemAmount}
                            error={selectedTransaction.redeemAmountError}
                            helperMessage={selectedTransaction.redeemAmountError}
                            marginBottom={"none"}
                            rows={1}
                            fontSize={'xs'}
                            isClearable={false}
                            inputType={"decimal"}
                            maxDecimalPlaces={2}
                            isCommaSeparated={true}
                        />
                    </div>
                ) : (
                    <div className={'pt-2'} style={{paddingRight: '14px', height: '59px'}}>
                        <DLText
                            id={'units'}
                            text={isEmpty(displayRedeemAmount) ? '' : getCommaSeparatedDecimalValue((displayRedeemAmount).toFixed(2), true)}
                            fontSize={'xs'}
                            marginBottom={'none'}
                        />
                    </div>
                )}
            </div>
        )
    };

    const renderRedeemUnits = (selectedTransaction, index) => {
        let redeemUnits = getRedeemUnits(selectedTransaction);
        let displayRedeemUnits = getRedeemUnits(selectedTransaction);

        return (
            <div className='pt-2'
                 id={'transferAmount-field-' + index}>
                {selectedTransaction?.type === 'UNIT' && selectedTransaction?.type !== 'ALL' ? (
                    <div className={''} style={{}}>
                        <DLInputTextField
                            id={'redeem-unit'}
                            onChangeValue={(value) => {
                                handleChange(value, selectedTransaction?.id, 'redeemUnits')
                            }}
                            disableHelperMessageHeight={false}
                            value={redeemUnits}
                            error={selectedTransaction.redeemUnitsError}
                            helperMessage={selectedTransaction.redeemUnitsError}
                            marginBottom={"none"}
                            rows={1}
                            fontSize={'xs'}
                            isClearable={false}
                            inputType={"decimal"}
                            maxDecimalPlaces={4}
                            isCommaSeparated={true}
                        />
                    </div>
                ) : (
                    <div className={'pt-2'} style={{paddingLeft: '14px', height: '59px'}}>
                        <DLText
                            id={'units'}
                            text={isEmpty(displayRedeemUnits) ? '' : getCommaSeparatedDecimalValue((displayRedeemUnits).toFixed(4), true)}
                            fontSize={'xs'}
                            marginBottom={'none'}
                        />
                    </div>
                )}
            </div>
        )
    };

    const renderAction = (id) => {
        return (
            <div className={'pt-3 cursor'} onClick={() => handleDelete(id)}>
                <i className="fa-regular fa-lg fa-trash-can"/>
            </div>
        )
    };

    const renderSelectedSchemeTableMobileView = (selectedScheme, index) => {

        const renderMobileSourceScheme = () => {
            let productName = selectedScheme.scheme;

            return (
                <div className='d-flex justify-content-between'>
                    <div className='w-75 px-0 mx-0'>
                        <DLText id={'scheme-title'}
                                text={productName}
                                fontWeight={'normal'}
                                fontSize={"xs"}
                        />
                    </div>
                    <div className='h-100 px-2'>
                        <i className='fa-regular fa-xl fa-trash-can default-icon cursor'
                           onClick={() => {
                               handleDelete(selectedScheme?.id)
                           }}
                        />
                    </div>
                </div>
            )
        };

        const renderMobileFolioNumberUI = () => {
            return (
                <div className='py-2'>
                    <DLText
                        id={"folioNo"}
                        text={"Folio no. "}
                        fontColor={"grayDark"}
                        fontSize={"xs"}
                        marginBottom={"none"}
                        isInline={true}
                    />
                    <DLText
                        id={'folio-no'}
                        text={selectedScheme?.folioNo}
                        fontSize={"xs"}
                        marginBottom={"none"}
                        isInline={true}
                    />
                </div>
            )
        };

        const errorUI = () => {
            let errors = selectedScheme?.errors;
            if (isEmpty(errors)) {
                return '';
            }

            return (
                errors?.map((error, index) => {
                    return <div className='pb-2 d-flex align-items-center' key={"error-" + index}>
                        <i className="fa-solid fa-circle-info error-icon pe-1"/>
                        <DLText id={''}
                                text={error}
                                fontSize={"xs"}
                                fontColor={"danger"}
                                marginBottom={'none'}
                        />
                    </div>
                })
            )
        };

        const renderMobileRedeemUnitsAmount = () => {
            let redeemAmount = getRedeemAmount(selectedScheme);
            let redeemUnits = getRedeemUnits(selectedScheme);
            let displayRedeemAmount = getRedeemAmount(selectedScheme);
            let displayRedeemUnits = getRedeemUnits(selectedScheme);

            return (
                <div>
                    <div className='d-flex justify-content-between gap-2'>
                        <div className="col-4" id={'transferUnits-field-' + index} style={{flex: 1}}>
                            <DLText
                                id={"units"}
                                text={"Type"}
                                fontColor={"grayDark"}
                                fontSize={"xs"}
                                marginBottom={"none"}
                            />
                        </div>
                        <div className="col-4" id={'transferUnits-field-' + index} style={{flex: 1}}>
                            <DLText
                                id={"units"}
                                text={"Redeem Units"}
                                fontColor={"grayDark"}
                                fontSize={"xs"}
                                marginBottom={"none"}
                            />
                        </div>
                        <div className="col-4" id={'transferUnits-field-' + index} style={{flex: 1}}>
                            <DLText
                                id={"units"}
                                text={"Redeem Amount"}
                                fontColor={"grayDark"}
                                fontSize={"xs"}
                                marginBottom={"none"}
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-between gap-2 pt-2'>
                        <div className="col-4 pe-1" id={'transferUnits-field-' + index} style={{flex: 1}}>
                            <DLDropDown
                                id={"type"}
                                // label={'Type'}
                                label={''}
                                labelFontColor={'grayDark'}
                                labelFontSize={'xs'}
                                value={selectedScheme?.type}
                                minWidth={"100%"}
                                options={[
                                    {label: 'Amount', value: 'AMOUNT'},
                                    {label: 'Units', value: 'UNIT'},
                                    {label: 'All', value: 'ALL'},
                                ]}
                                onSelect={(value) => handleChange(value, selectedScheme?.id, 'type')}
                                disableHelperMessageHeight={true}
                                marginBottom={"none"}
                                // labelPlacement={'top'}
                                placeholder={'Select'}
                                error={selectedScheme?.typeError}
                            />
                        </div>
                        <div className="col-4 pe-1" id={'transferUnits-field-' + index} style={{flex: 1}}>
                            {selectedScheme?.type === 'UNIT' && selectedScheme?.type !== 'ALL' ?
                                (
                                    <DLInputTextField
                                        id="transfer-unit"
                                        onChangeValue={(value) => handleChange(value, selectedScheme?.id, 'redeemUnits')}
                                        disableHelperMessageHeight={true}
                                        value={redeemUnits}
                                        error={selectedScheme?.redeemUnitsError}
                                        isClearable={false}
                                        marginBottom={"none"}
                                        fontSize={"xs"}
                                        inputType={"decimal"}
                                        label={''}
                                        labelFontSize={'xs'}
                                    />
                                ) :
                                (
                                    <div className='pt-2'>
                                        <DLText
                                            id="units"
                                            text={isEmpty(displayRedeemUnits) ? '' : getCommaSeparatedDecimalValue((displayRedeemUnits).toFixed(2), true)}
                                        />
                                    </div>
                                )}
                        </div>
                        <div className="col-4 pe-1" id={'transferUnits-field-' + index} style={{flex: 1}}>
                            {selectedScheme?.type === 'AMOUNT' && selectedScheme?.type !== 'ALL' ?
                                (
                                    <div>
                                        <DLInputTextField
                                            id="transfer-amount"
                                            onChangeValue={(value) => handleChange(value, selectedScheme?.id, 'redeemAmount')}
                                            disableHelperMessageHeight={true}
                                            value={redeemAmount}
                                            error={selectedScheme?.redeemAmountError}
                                            isClearable={false}
                                            marginBottom={"none"}
                                            fontSize={"xs"}
                                            inputType={"decimal"}
                                            label={''}
                                            labelFontSize={'xs'}
                                        />
                                    </div>
                                ) :
                                (
                                    <div className='pt-2'>
                                        <DLText
                                            id="units"
                                            text={isEmpty(displayRedeemAmount) ? '' : getCommaSeparatedDecimalValue((displayRedeemAmount).toFixed(2), true)}
                                        />
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            )
        };

        return (
            <div>
                {renderMobileSourceScheme()}
                {renderMobileFolioNumberUI()}
                {renderMobileRedeemUnitsAmount()}
                {errorUI()}
            </div>
        )
    };

    const renderSelectedPortfolio = () => {

        return (
            <div className={"bg-white border-rounded px-3 pt-2 " + (isEmpty(selectedScheme) ? "pb-3" : "pb-4")}>
                <div className='d-flex mb-3'>
                    <div>
                        <DLText
                            id={'redemption'}
                            type={'normal'}
                            text={'Redemption '}
                            fontSize={'lg'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                            isInline={true}
                        />
                    </div>
                </div>
                {isEmpty(selectedScheme) ?
                    <div className={'d-flex justify-content-center align-items-center border-rounded border'}
                         style={{minHeight: 'calc(100dvh - 630px)', maxHeight: 'calc(100dvh - 630px)'}}>
                        <DLText
                            id={'empty-ui'}
                            text={'Add a fund from the existing portfolio for redemption.'}
                        />
                    </div> :
                    <div className={'h-100'}>
                        {renderSelectedSchemeTable()}
                    </div>
                }
            </div>
        )
    };

    const renderSelectedSchemeTable = () => {
        const tableData = getFormattedTableData();
        if (isMobileView) {
            return (
                <div>
                    <DLTable
                        id={'selected-list'}
                        headerData={[]}
                        tableData={tableData}
                        tableMaxHeight={'auto'}
                        tableMinHeight={'auto'}
                        isDynamicHeight={true}
                        isRowClickable={false}
                        isShowPagination={false}
                        isShowTotalCount={true}
                        cellConfig={redemptionSelectedSchemeCellConfigXSView}
                    />
                </div>
            )
        }
        return (
            <div>
                <DLTable
                    id={'selected-list'}
                    headerData={redemptionSelectedSchemeHeaderData}
                    headerConfig={[{verticalAlign: 'center'}]}
                    tableData={tableData}
                    isDynamicHeight={true}
                    isRowClickable={false}
                    isShowPagination={false}
                    isShowTotalCount={true}
                    cellConfig={redemptionSelectedSchemeCellConfig}
                    isShowCellSeprator={false}
                />
            </div>
        )
    };

    const renderBottomActionButtons = () => {
        const {submitTransactionDetails, openDiscardModal} = props;
        return (
            <div
                className={(isMobileView ? 'bg-white fixed-bottom' : '')}
                style={{
                    zIndex: 100,
                    boxShadow: isMobileView ? "0px -2px 5px rgba(0, 0, 0, 0.1)" : "none",
                }}
            >
                <div
                    className={isMobileView ? 'd-flex py-2 justify-content-center align-items-center' : 'sticky-bottom-buttons '}>
                    <div>
                        <DLButton
                            id={'save-and-close-button'}
                            type={"danger"}
                            styleType={"outlined"}
                            sideMargin={'none'}
                            onClick={() => {
                                openDiscardModal()
                            }}
                            label={"Discard"}
                        />
                    </div>
                    <div className={isMobileView ? "ps-3" : ""}>
                        <DLButton id={'next-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  onClick={() => {
                                      submitTransactionDetails()
                                  }}
                                  label={"Done"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderDiscardModal = () => {
        const {closeAddDiscardModal, isOpenDiscardModal, redirectScreen} = props;
        return (
            <DLModal
                history={props.history}
                maxWidth={"xs"}
                onClose={closeAddDiscardModal}
                open={isOpenDiscardModal}
                id={'discard-modal'}>
                <DLModalTitle
                    id={'discard-title'}
                    onClose={() => closeAddDiscardModal()}
                    title={'Confirmation'}/>
                <DLModalContainer
                    id={'discard-container'}>
                    <div className='mb-2 pb-1 text-center'>
                        <DLText id={''}
                                text={"Are you sure you want to discard this transaction?"}
                                fontSize={"sm"}
                                fontWeight={"normal"}
                                marginBottom={"none"}
                        />
                        <DLText id={''}
                                text={"This action cannot be undone."}
                                fontSize={"sm"}
                                fontColor={"danger"}
                                marginBottom={"none"}
                        />
                    </div>
                </DLModalContainer>
                <DLModalActions id={'discard-action'}>
                    <div className='mb-4' style={{paddingTop: '7px'}}>
                        <DLButton
                            id={'discard-cancelBtn'}
                            history={props.history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => closeAddDiscardModal()}/>
                        <DLButton
                            id={'discard-btn'}
                            history={props.history}
                            styleType={"contained"}
                            type={"danger"}
                            label={'Discard'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => {
                                redirectScreen('DISCARD')
                            }}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        )
    };

    const renderHeader = () => {
        if (isMobileView) {
            return (
                <div
                    className={'d-flex align-items-center align-content-center w-100 justify-content-between border-bottom p-3 bg-white'}>
                    <DLText id={"purchase-transaction-field"}
                            text={"Redemption Transaction: Edit"}
                            fontSize={"md"}
                            fontWeight={'semi-bold'}
                            marginBottom={"none"}
                    />
                </div>
            )
        }

        return (
            <div className='d-flex justify-content-between w-100'>
                <div className='page-header px-0 py-3  m-0'>
                    Redemption Transaction: Edit
                </div>
            </div>
        )
    };

    const renderUi = () => {
        return (
            <div>
                <div className="bg-light h-100">
                    <div className="bg-white mb-2 border-rounded border">
                        {renderExistingPortfolio()}
                    </div>
                    <div className={'bg-white border-rounded border mb-5'}>
                        {renderSelectedPortfolio()}
                    </div>
                </div>
                {renderBottomActionButtons()}
            </div>
        )
    };

    const renderExistingPortfolio = () => {
        return (
            <ExistingPortfolio {...props}
                               subTextLabel={"Select the funds you would like to redeem"}
            />
        )
    };

    const renderMobileViewSelectedPortfolio = () => {
        return (
            <div className={"bg-white"}>
                <div className='d-flex py-2 px-3 black-border-bottom black-border-top'>
                    <div>
                        <DLText
                            id={'redemption'}
                            type={'normal'}
                            text={'Redemption '}
                            fontSize={'md'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                            isInline={true}
                        />
                    </div>
                </div>
                {isEmpty(selectedScheme) ?
                    <div className={'d-flex justify-content-center align-items-center px-4'}
                         style={{minHeight: "calc(100dvh - 663px)"}}>
                        <DLText
                            id={'empty-ui'}
                            text={'Add a fund from the existing portfolio for systematic transfer.'}
                            customWrapperStyle={{
                                textAlign: 'center'
                            }}
                        />
                    </div> :
                    <div>
                        {renderSelectedSchemeTable()}
                    </div>
                }
            </div>
        )
    };

    const renderMobileViewUi = () => {
        return (
            <div>
                <div>
                    <div className="bg-white mb-3">
                        {renderExistingPortfolio()}
                    </div>
                    <div className={'bg-white'}>
                        {renderMobileViewSelectedPortfolio()}
                    </div>
                </div>
                {renderBottomActionButtons()}
            </div>
        )
    };

    const renderErrorUI = () => {
        const {responseErrors} = props;

        if (isEmpty(responseErrors)) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={'mt-3'}>
                {!isEmpty(responseErrors) && <div className='error-container'>
                    <div className='error-vertical-line'/>
                    <div className={'error-panel'}>
                        {responseErrors}
                    </div>
                </div>}
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    if (isEmpty(UIState)) {
        return renderLoader()
    }

    return (
        <div className={isMobileView ? 'bg-light' : 'bg-light px-4'}
             style={{minHeight: '100%'}}>
            {renderHeader()}
            <div
                className={isMobileView ? '' : 'd-flex flex-column bg-light'}>
                {isMobileView ? renderMobileViewUi() : renderUi()}
            </div>
            {renderDiscardModal()}
            {renderLoader()}
        </div>
    )
};

export default PurchaseRedemptionTransactionView;
