import React from 'react';
import DLText from "../../../../../core/components/DLText/DLText";
import DLTable from "../../../../../core/components/DLTable/DLTable";
import {
    lastMonthsTransactionsCellConfig,
    lastMonthsTransactionsCellConfigMobile,
    lastMonthsTransactionsHeading
} from "../../../../ClientsModel";
import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import {
    convertUTCToLocalTime,
    numberWithCommas
} from "../../../../../utils/helper";
import {transactionStatuses} from "../../../../../transactions/FT/components/FTReportList/FTReportConst";
import {renderTransactionStatusDot} from "../../../../../transactions/FT/components/FTReportList/FTReportHelper";
import SystematicInvestments from "./components/SystematicInvestments";
import ExistingPortfolio from "../../../../../transactions/FT/components/FTDetails/components/existingPortfolio/ExistingPortfolio";

const InvestmentView = (props) => {

    const {
        isMobileView,
        isXSView,
        lastTransactions,
        isShowLoader,
        UIState,
        setSeeMoreFilters,
        profile
    } = props;

    const renderTransactionsMobileUI = (transaction) => {
        let status = '';
        if (!isEmpty(transaction.trxnStatus)) {
            status = transactionStatuses.find(status => status.label === transaction.trxnStatus);
        }
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <DLText
                            id={"SchemeValue"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={isEmpty(transaction.schemeName) ? '-' : transaction.schemeName}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className={`${isMobileView ? 'col-4 pe-0' : 'col-4'}`}>
                        <DLText
                            id={"dateValue"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={isEmpty(transaction.entryDate) ? '-' : `on ${convertUTCToLocalTime(transaction.entryDate, 'DD-MM-YYYY')}`}
                            marginBottom={'none'}
                        />
                    </div>
                    <div className={`${isMobileView ? 'col-4 pe-0' : 'col-4'}`}>
                        <DLText
                            id={"amountValue"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={numberWithCommas(transaction.amount)}
                            fontColor={"black"}
                            fontWeight={"semi-bold"}
                        />
                        <DLText
                            id={'transactionType'}
                            text={transaction.trxnType}
                            fontSize={"xs"}
                        />
                    </div>
                    <div className={`${isMobileView ? 'col-4' : 'col-4'}`}>
                        <div
                            className={`d-flex align-items-start ${isEmpty(status.label) ? '' : ''}`}>
                            <div>
                                {isEmpty(status.value) ? '' : renderTransactionStatusDot(status.value)}
                            </div>
                            <DLText
                                id={"statusValue"}
                                type={"normal"}
                                fontSize={"xs"}
                                text={isEmpty(status.label) ? '-' : status.label}
                                marginBottom={'none'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderTransactionType = (transaction) => {
        return (
            <div>
                <DLText id={'trxnType'}
                        text={isEmpty(transaction?.trxnType) ? '-' : transaction?.trxnType}
                        fontSize={'xs'}
                        fontWeight={'normal'}
                        marginBottom={"none"}
                />
                {transaction?.subTrxnType === "Systematic" ?
                    <DLText id={'subTrxnType'}
                            text={transaction?.subTrxnType}
                            fontSize={'xs'}
                            fontWeight={'normal'}
                            fontColor={'grayDark'}
                    /> : ''
                }
            </div>
        )
    };

    const getFormattedTableData = (data) => {
        let tableData = [];
        if (isEmpty(data)) {
            return tableData;
        }
        data?.map((transaction, index) => {
            let bankAccountDetails = [];
            let data = {};
            if(index > 9){
               return;
            }
            if (isMobileView) {
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderTransactionsMobileUI(transaction)
                });
            } else {
                bankAccountDetails.push(isEmpty(transaction?.schemeName) ? '-' : transaction?.schemeName);
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderTransactionType(transaction)
                });
                bankAccountDetails.push(isEmpty(transaction.entryDate) ? '-' : convertUTCToLocalTime(transaction.entryDate, 'DD-MM-YYYY'));
                bankAccountDetails.push(renderTransactionsStatus(transaction));
                bankAccountDetails.push(isEmpty(transaction.amount) ? '-' : numberWithCommas(transaction.amount));
            }
            data.id = '';
            data.data = bankAccountDetails;
            tableData.push(data)
        });
        return tableData;
    };
    const renderTransactionsStatus = (transaction) => {
        let status;
        if (!isEmpty(transaction.trxnStatus)) {
            status = transactionStatuses.find(status => status.label === transaction.trxnStatus);
        }
        return (
            <div className={`d-flex ${isXSView ? 'align-items-start ' : 'align-items-center'} `}>
                {isEmpty(status?.value) ? '' : renderTransactionStatusDot(status?.value)} <DLText
                id={"statusValue"}
                type={"normal"}
                fontSize={"xs"}
                text={isEmpty(transaction?.trxnStatus) ? '-' : transaction?.trxnStatus}
                marginBottom={'none'}
            />
            </div>
        )
    };

    const lastMonthsTransactionsMobileTable = () => {
        let tableData = getFormattedTableData(lastTransactions);

        return (
            <div className='bg-white'>
                {renderHeading(tableData)}
                {isEmpty(lastTransactions) ?
                    <div className={'d-flex align-items-center justify-content-center border-top border-bottom'}
                         style={{minHeight: '200px'}}>
                        No transaction initiated for this account.
                    </div>
                    :
                    <div className={'border-top'}>
                        <DLTable
                            id={''}
                            tableMaxHeight={'auto'}
                            tableMinHeight={'auto'}
                            isRowClickable={false}
                            isShowPagination={false}
                            headerData={[]}
                            tableData={tableData}
                            cellConfig={lastMonthsTransactionsCellConfigMobile}

                        />
                    </div>
                }
            </div>
        );
    };

    const renderHeading = (tableData) =>{
        return(
            <div className={isMobileView? 'block-padding border-bottom d-flex justify-content-between' : 'py-2 ps-3 border-bottom d-flex justify-content-between'} id={'investment'}>
                <DLText
                    id={'header'}
                    text={'Last 10 Transactions'}
                    fontSize={isMobileView? 'md' : 'lg'}
                    marginBottom={'none'}
                    fontWeight={'semi-bold'}
                />
                {!isEmpty(tableData) && (
                    <div className='d-flex justify-content-center align-items-center cursor pe-3' onClick={() => setSeeMoreFilters()}>
                        <DLText id={'see-more'}
                                text={'See more'}
                                fontWeight={'bold'}
                                fontSize={'sm'}
                                isClickable={true}
                                marginBottom={'none'}
                        />
                    </div>
                )}
            </div>
        )
    };

    const lastMonthsTransactionsTable = () => {
        let tableData = getFormattedTableData(lastTransactions);

        return (
            <div className='panel p-0 table-border'>
                {renderHeading(tableData)}
                {isEmpty(lastTransactions) ?
                    <div className={'d-flex align-items-center justify-content-center border-top'}
                         style={{minHeight: '292px'}}>
                        No transaction initiated for this account.
                    </div>
                    :
                    <DLTable
                        id={''}
                        tableMaxHeight={'290px'}
                        tableMinHeight={'280px'}
                        isRowClickable={false}
                        isShowPagination={false}
                        headerData={lastMonthsTransactionsHeading}
                        tableData={tableData}
                        cellConfig={lastMonthsTransactionsCellConfig}
                        isShowTableBorder={false}
                    />
                }
            </div>
        )
    };

    const renderExistingPortfolio = () => {
        const {accountId} = props;
        return (
            <ExistingPortfolio
                {...props}
                isFromClientView={true}
                accountId={accountId}
                subTextLabel={''}
            />
        );
    };

    const renderLoader = () => {
        return (<DLLoader isVisible={isShowLoader || isEmpty(UIState)} type={'screen'}/>);
    };

    const renderUI = () => {
        return !isEmpty(UIState) && (isMobileView ? renderMobileUI() : renderDesktopUI())
    };

    const renderDesktopUI = () => {
        const {isMDView,isLGView}=props;
        return (
            <div>
                <div className={`${isMDView || isLGView?'w-100':'w-75'} `}>
                    {(profile?.isManagement) && <div className="my-4">
                        {renderExistingPortfolio()}
                    </div>}
                    <div className={profile?.isManagement ? '' : 'mt-4'}>
                        {<SystematicInvestments {...props}/>}
                    </div>
                    <div className='my-4'>
                        {lastMonthsTransactionsTable()}
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileUI = () => {
        return (
            <div className={'pt-3 bg-light'}>
                {(profile?.isManagement) && <div>
                    {renderExistingPortfolio()}
                </div>}
                {<SystematicInvestments {...props}/>}
                <div className={'border-top'}>
                    {lastMonthsTransactionsMobileTable()}
                </div>
            </div>
        );
    };


    return (
        <div className={isMobileView ? '' : 'page-container'}
             style={{
                 maxHeight: isXSView ? 'calc(100dvh - 210px)' : isMobileView ? 'calc(100dvh - 172px)' : 'calc(100dvh - 152px)',
                 minHeight: isXSView ? 'calc(100dvh - 210px)' : isMobileView ? 'calc(100dvh - 170px)' : 'calc(100dvh - 152px)',
                 overflow: "auto"
             }}>
            {renderUI()}
            {renderLoader()}
        </div>
    );
};

export default InvestmentView;